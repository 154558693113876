import React, { useContext, useEffect, useReducer } from "react";
import {
  Autocomplete,
  Box,
  Typography,
  Button,
  TextField,
  InputAdornment,
  Grid,
  Alert,
  Chip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { INITIAL_STATE, postReducer } from "../../../../reducers/postReducer";
import { ACTION_TYPES } from "../../../../reducers/ActionTypes";
import { ButtonSubmitProgress } from "../../../../component/ProgressIndicators/PageProgress";
import { apiCreateSubscription } from "../../../../api/AppCenter/Setters";
// ASSESTS
import ArrorLeft from "../../../../assets/appointment/arrow-left.png";
import Line from "../../../../assets/appointment/Line.png";
import { getContentTypes } from "../../../../api/Settings/Settings";

export const extra_modules = [
  {
    title: "Laboratory Test",
    app_label: "lab",
    model: "test",
  },
  {
    title: "Laboratory Result",
    app_label: "lab",
    model: "labtestresult",
  },
  {
    title: "Product Stock",
    app_label: "product",
    model: "product",
  },
  {
    title: "Payroll",
    app_label: "payroll",
    model: "payroll",
  },
  {
    title: "Leave",
    app_label: "leave",
    model: "leaverequest",
  },
  {
    title: "Task",
    app_label: "tasks",
    model: "task",
  },
];

const SubscriptionCreate = () => {
  const initialState = {
    title: null,
    number_of_staff: 1,
    number_of_client: 10,
    number_of_doctors: 1,
    price: 0,
    extra_features: [],
    extra_modules: [],
  };
  const navigate = useNavigate();
  const [values, setValues] = React.useState(initialState);
  const [contentTypeList, setContentTypeList] = React.useState([]);
  // reducers------------------------------------------
  const [state, dispatch] = useReducer(postReducer, INITIAL_STATE);
  // functions -------------------------------------------
  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
    dispatch({ type: ACTION_TYPES.RESET });
  };
  const getModuleIdFromContentTypes = () => {
    let modList = [];
    values.extra_modules.map((mod) => {
      const modContentType = contentTypeList.find(
        (ty) => ty.app_label === mod.app_label && ty.model === mod.model
      );
      if (modContentType) {
        // const newMod = {
        //   ...mod,
        //   id: modContentType.id,
        // };
        modList.push(modContentType.id);
      }
    });
    return modList;
  };

  // submit func ant its callbacks ------------------------------------------------
  const handleSuccessAction = (data) => {
    dispatch({ type: ACTION_TYPES.FETCH_SUCCESS, payload: data });
  };
  const handleErrorAction = () => {
    dispatch({ type: ACTION_TYPES.FETCH_ERROR });
  };
  const handleUpdate = () => {
    const newData = {
      ...values,
      extra_features: values.extra_features.join(","),
      extra_modules: getModuleIdFromContentTypes(),
    };
    dispatch({ type: ACTION_TYPES.FETCH_START });
    apiCreateSubscription(newData, handleSuccessAction, handleErrorAction);
  };
  // select the fields of the selected state ------------------------------------
  const contentTypesSucess = (data) => {
    setContentTypeList(data);
  };
  React.useEffect(() => {
    getContentTypes(contentTypesSucess);
  }, []);
  return (
    <Grid container spacing={3}>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          alignItem: "center",
          justifyContent: "flex-start",
          marginLeft: "24px",
          marginTop: "24px",
        }}
        onClick={() => navigate(-1)}
      >
        <img src={ArrorLeft} alt="icon" style={{ paddingRight: "12px" }} />
        <img src={Line} alt="icon" style={{ paddingRight: "12px" }} />
        <Typography
          sx={{ fontSize: "20px", fontWeight: "500", color: "#203139" }}
        >
          Create Subscription
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Box
          sx={{
            backgroundColor: "backgroundTypes.main",
            borderRadius: "16px",
            p: 3,
          }}
        >
          <Box sx={{}}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <TextField
                required
                name="title"
                label="Title"
                value={values.title}
                onChange={handleChange}
                sx={{ m: 1, width: { xs: "100%", sm: "40ch", md: "60ch" } }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                  style: { borderRadius: "10px" },
                }}
              />

              <TextField
                required
                name="number_of_staff"
                label="Number Of Staff"
                value={values.number_of_staff}
                onChange={handleChange}
                sx={{ m: 1, width: { xs: "100%", sm: "40ch", md: "60ch" } }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                  style: { borderRadius: "10px" },
                }}
              />
              <TextField
                required
                name="number_of_doctors"
                label="Number Of Doctors"
                value={values.number_of_doctors}
                onChange={handleChange}
                sx={{ m: 1, width: { xs: "100%", sm: "40ch", md: "60ch" } }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                  style: { borderRadius: "10px" },
                }}
              />
              <TextField
                required
                name="number_of_client"
                label="Number of client"
                value={values.number_of_client}
                onChange={handleChange}
                sx={{ m: 1, width: { xs: "100%", sm: "40ch", md: "60ch" } }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"></InputAdornment>
                  ),
                  style: { borderRadius: "10px" },
                }}
              />

              <Autocomplete
                multiple
                id="tags-filled"
                options={extra_modules}
                onChange={(e, newValue) => {
                  setValues({ ...values, extra_modules: newValue });
                }}
                getOptionLabel={(option) => option.title}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option.title}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // variant="standard"
                    label="Extra Modules"
                    placeholder="Click Enter To Add.."
                    sx={{
                      "& .MuiInputBase-root": {
                        borderRadius: "10px !important",
                      },
                      m: 1,
                      width: { xs: "100%", sm: "40ch", md: "60ch" },
                    }}
                  />
                )}
              />
              <TextField
                required
                // variant="standard"
                name="price"
                label="Price"
                value={values.price}
                onChange={handleChange}
                sx={{ m: 1, width: { xs: "100%", sm: "40ch", md: "60ch" } }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">&#8358;</InputAdornment>
                  ),
                  style: { borderRadius: "10px" },
                }}
              />
              <Autocomplete
                multiple
                id="tags-filled"
                options={values.extra_features}
                freeSolo
                onChange={(e, newValue) => {
                  setValues({ ...values, extra_features: newValue });
                }}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // variant="standard"
                    label="Extra Features"
                    placeholder="Click Enter To Add.."
                    sx={{
                      "& .MuiInputBase-root": {
                        borderRadius: "10px !important",
                      },
                      m: 1,
                      width: { xs: "100%", sm: "40ch", md: "60ch" },
                    }}
                  />
                )}
              />
            </Box>

            {state.post ? (
              <Alert severity="success">Successfully Added</Alert>
            ) : (
              ""
            )}
            {state.error ? (
              <Alert severity="error">
                Not Saved: Check your information and try again.
              </Alert>
            ) : (
              ""
            )}
            <Button
              onClick={handleUpdate}
              variant="contained"
              sx={{
                height: "56px",
                marginBottom: "51px",
                borderRadius: "12px",
              }}
            >
              Add Subscription
              {state.loading && <ButtonSubmitProgress />}
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SubscriptionCreate;
