import { Box, Typography } from "@mui/material";
import React, { useReducer, useState } from "react";
import {
  handleMedicalRecordSubmission,
  handleTaskAttachmentFile,
} from "../../../api/API";
import MedicalRecordUploading from "../../../pages/client/registration/snippet/MedicalRecordUploading";
import { ACTION_TYPES } from "../../../reducers/ActionTypes";
import { INITIAL_STATE, postReducer } from "../../../reducers/postReducer";
import Upload from "../../../assets/registration/Upload.png";
import UploadEmpty from "../../../assets/registration/UploadEmpty.png";
import Empty from "../../../assets/registration/empty.png";
import TaskFileUploading from "./TaskFileUploading";

const TaskFileUpload = ({ setAddedFiles }) => {
  // states
  const [fileAdded, setFileAdded] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [files, setFiles] = useState([]);
  const [progress, setProgress] = useState("");
  // reducers
  const [state, dispatch] = useReducer(postReducer, INITIAL_STATE);
  // functions

  const handleSuccessAction = (data) => {
    dispatch({ type: ACTION_TYPES.FETCH_SUCCESS });
    setFileAdded(true);
    setFiles([...files, data]);
    setAddedFiles([...files, data]);
    setIsDone(true);
  };
  const handleErrorAction = (errorData) => {
    console.log("error", errorData);
    dispatch({ type: ACTION_TYPES.FETCH_ERROR });
  };
  console.log(files);
  const handleUploadProgress = (progress) => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress <= 100) {
          prevProgress += progress;
          setProgress(prevProgress);
        }
      });
    }, 800);
    return () => {
      clearInterval(timer);
    };
  };

  const handleAddFile = (event) => {
    // it adds the file both to the list below and submits it to the server
    let values = event.target.files[0];
    const data = {
      title: values.name,
      attachment: values,
      size: (values.size / 1024 / 1024).toFixed(2),
    };
    console.log(data);
    if (values.name !== null) {
      handleTaskAttachmentFile(
        data,
        handleSuccessAction,
        handleErrorAction,
        handleUploadProgress
      );
    }
  };
  return (
    <Box>
      <Box
        sx={{
          border: "1px dashed #9CA3A7",
          borderRadius: "12px",
          width: { xs: "40ch", sm: "63ch" },
          mb: 2,
        }}
        onDrop={handleAddFile}
      >
        <Box
          sx={{
            p: { xs: 0, sm: 3 },
          }}
        >
          <Box
            component="label"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <input type="file" name="image" hidden onChange={handleAddFile} />
            <Box>
              <img
                src={fileAdded ? Upload : UploadEmpty}
                alt="upload"
                width="100px"
                height="100px"
                style={{ borderRadius: "5%" }}
              />
            </Box>

            <Box>
              <Typography>Drag and Drop Files, or Browse</Typography>
              <Typography
                variant="caption"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  color: "textGray.main2",
                }}
              >
                Maximum Upload File Size :30MB
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          mt: -2,
        }}
      >
        <Typography variant="caption">
          File Format: Zip, RAR, PNG, PDF and Doc
        </Typography>
      </Box>

      {fileAdded ? (
        <Box
          sx={{
            mt: 5,
            width: { xs: "40ch", sm: "63ch" },
          }}
        >
          <>
            {files.length > 0
              ? files.map((file, index) => (
                  <TaskFileUploading
                    file={file}
                    key={index}
                    progress={progress}
                    done={isDone}
                  />
                ))
              : ""}
          </>
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
};

export default TaskFileUpload;
