import React, { useContext } from "react";
import { useState } from "react";
// import { useDispatch, useSelector } from "react-redux";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Box, Drawer, Fab, Grid, IconButton, Tooltip } from "@mui/material";
// import { IconSettings } from "@tabler/icons-react";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
// import SubCard from "../ui-component/cards/SubCard";
import AnimateButton from "../ui-component/extended/AnimateButton";
// import { SET_BORDER_RADIUS, SET_FONT_FAMILY } from "../store/actions";
import { gridSpacing } from "../store/constant";
import { AddTask, Close } from "@mui/icons-material";
import AddNewTask from "./AddTask";
import TaskContext from "../../context/taskContext";

// concat 'px'
// function valueText(value) {
//   return `${value}px`;
// }

// ==============================|| LIVE CUSTOMIZATION ||============================== //

const TaskAutomationModal = () => {
  const theme = useTheme();
  //   const dispatch = useDispatch();
  //   const customization = useSelector((state) => state.customization);

  // drawer on/off

  const { openTask, toggleTask } = useContext(TaskContext);
  // const [open, setOpen] = useState(false);
  // const handleToggle = () => {
  //   setOpen(!open);
  // };

  return (
    <div>
      {" "}
      <>
        {/* toggle button */}
        <Tooltip title="Add Task">
          <Fab
            component="div"
            onClick={toggleTask}
            size="medium"
            variant="circular"
            color="primary"
            sx={{
              borderRadius: 0,
              borderTopLeftRadius: "50%",
              borderBottomLeftRadius: "50%",
              borderTopRightRadius: "50%",
              borderBottomRightRadius: "4px",
              top: "25%",
              position: "fixed",
              right: 10,
              zIndex: theme.zIndex.speedDial,
            }}
          >
            <AnimateButton type="rotate">
              <IconButton color="inherit" size="large" disableRipple>
                <AddTask />
              </IconButton>
            </AnimateButton>
          </Fab>
        </Tooltip>

        <Drawer
          anchor="right"
          onClose={toggleTask}
          open={openTask}
          PaperProps={{
            sx: {
              width: 350,
            },
          }}
        >
          <PerfectScrollbar component="div">
            <Grid container spacing={gridSpacing} sx={{ p: 1 }}>
              <AddNewTask />
            </Grid>
          </PerfectScrollbar>
        </Drawer>
      </>
    </div>
  );
};

export default TaskAutomationModal;
