import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";

import DeleteIcon from "../../../../assets/appointment/Deleteicon.png";
import { WarningAmber } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  p: 4,
  boxShadow: "0px 24px 32px rgba(171, 171, 171, 0.16)",
  borderRadius: "16px",
};

export default function GeneralWarningModal({
  open,
  onClose,
  warningText,
  actionFunction,
  actionText,
}) {
  //   const [open, setOpen] = React.useState(false);
  //   const handleOpen = () => setOpen(true);
  //   const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            onClick={onClose}
            sx={{
              position: "absolute",
              top: "24px",
              right: "24px",
              cursor: "pointer",
            }}
          >
            <CloseIcon />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "32px",
            }}
          >
            <WarningAmber />
          </Box>

          <Box sx={{ marginBottom: "66px" }}>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "20px",
                color: "#64697A",
                textAlign: "center",
              }}
            >
              {warningText}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "24px",
            }}
          >
            <Button
              onClick={onClose}
              sx={{
                paddingY: "13px",
                paddingX: "75px",
                background: "#F3F3F4",
                borderRadius: "8px",
              }}
            >
              Close
            </Button>
            <Button
              variant="contained"
              sx={{ paddingY: "13px", paddingX: "75px", borderRadius: "8px" }}
              onClick={actionFunction}
            >
              {actionText}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
