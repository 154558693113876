import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { CLINIC_LINKS } from "../../links/clinicLinks";
import { getClinicClient, getUser } from "../../api/getUserMain";

export const ClientRoute = ({ children }) => {
  const authed = getClinicClient(); // isauth() returns true or false based on localStorage

  return authed ? children : <Navigate to={CLINIC_LINKS.LOGIN} />;
  //   return (
  //     <Routes>
  //       <Route
  //         {...rest}
  //         render={(props) => {
  //           const isLoggedIn = getUser();

  //           return isLoggedIn ? (
  //             <Component {...props} />
  //           ) : (
  //             <Navigate to={CLINIC_LINKS.LOGIN} />
  //           );
  //         }}
  //       />
  //     </Routes>
  //   );
};
