import * as React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

export default function Footer() {
  const d = new Date();
  const year = d.getUTCFullYear();
  return (
    <Box
      sx={{
        marginX: "auto",
        width: "100%",
        height: 40,
        position: "relative",
        bottom: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "center",
          marginTop: "15px",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Typography
            variant="body2"
            sx={{
              paddingLeft: "5px",
              paddingRight: "10px",
            }}
          >
            Powered by SMARTDVM
          </Typography>
          <Typography
            variant="body2"
            sx={{
              paddingRight: "10px",
            }}
          >
            &copy; {year}
          </Typography>
        </Box>

        <Typography
          variant="body2"
          sx={{
            paddingRight: "10px",
            cursor: "pointer",
          }}
        >
          Contact us
        </Typography>

        <Typography
          variant="body2"
          sx={{
            paddingRight: "10px",

            cursor: "pointer",
          }}
        >
          About us
        </Typography>
      </Box>
    </Box>
  );
}
