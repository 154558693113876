export default {headers:{
    Authorization:localStorage.getItem('access_token') 
    ? 'Bearer ' + localStorage.getItem('access_token')
    : null,
    'ConTent-Type':"multipart/form-data",
    accept:'application/json'
}}


export const headerFileSpecial=(handleUploadProgress)=>{ 
    return {headers:{
    Authorization:localStorage.getItem('access_token') 
    ? 'Bearer ' + localStorage.getItem('access_token')
    : null,
    'ConTent-Type':"multipart/form-data",
    accept:'application/json'
},
onUploadProgress: (progressEvent) => {
    const progress = (progressEvent.loaded / progressEvent.total) * 50;
    handleUploadProgress(progress)
    // console.log(progress);
  },
onDownloadProgress: (progressEvent) => {
    const progress = 50 + (progressEvent.loaded / progressEvent.total) * 50;
    handleUploadProgress(progress)
    // console.log(progress);
  },
}
}
