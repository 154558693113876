import axios from "axios";
import { CLIENT_LINKS } from "../../links/clientLinks";
import { CLINIC_LINKS } from "../../links/clinicLinks";
import { APP_CENTER_LINKS } from "../../links/appCenterLinks";
import { CLINIC_SUPER_LINKS } from "../../links/clinicSuperLinks";
import { GENERAL_LINKS } from "../../links/generalLinks";

export const baseURL = () => {
  if (window.location.hostname.includes("localhost")) {
    return "http://localhost:8000";
  } else if (window.location.hostname.includes("testfrt")) {
    return "https://testbck.smartdvm.com";
  } else {
    return "https://bck.uat.smartdvm.com";
  }
};

const axiosInstance = axios.create({
  baseURL: baseURL(),
  timeout: 5000,
  headers: {
    Authorization: localStorage.getItem("access_token")
      ? "Bearer " + localStorage.getItem("access_token")
      : null,
    "ConTent-Type": "application/json",
    accept: "application/json",
  },
});

const CLINIC_AUTH_LINKS = [
  CLINIC_LINKS.LOGIN,
  CLINIC_LINKS.REGISTER,
  CLINIC_LINKS.BIO_REGISTER,
  CLINIC_LINKS.CLINIC_REGISTER,
  CLINIC_LINKS.CLINIC_UPDATE,
  CLINIC_LINKS.CLINIC_CHANGE_PASSWORD,
  CLINIC_LINKS.CLINIC_RESET_PASSWORD,
];
const APP_CENTER_AUTH_LINKS = [APP_CENTER_LINKS.LOGIN];
const CLINIC_SUPER_AUTH_LINKS = [
  CLINIC_SUPER_LINKS.LOGIN,
  CLINIC_SUPER_LINKS.REQUEST_REGISTER,
  CLINIC_SUPER_LINKS.CLINIC_REGISTER,
];
const CLIENT_AUTH_LINKS = [CLIENT_LINKS.LOGIN];

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    if (
      (CLINIC_AUTH_LINKS.find((link) => link === window.location.pathname) ||
        Object.values(GENERAL_LINKS).find(
          (link) => link === window.location.pathname
        ) ||
        APP_CENTER_AUTH_LINKS.find(
          (link) => link === window.location.pathname
        ) ||
        CLINIC_SUPER_AUTH_LINKS.find(
          (link) => link === window.location.pathname
        ) ||
        CLIENT_AUTH_LINKS.find((link) => link === window.location.pathname) ||
        window.location.pathname.includes("/vapp/change_password/")) &&
      originalRequest.url === "/api/token/refresh/"
    ) {
      return Promise.reject(error);
    } else if (
      error.response.status === 401 &&
      originalRequest.url === "/api/token/"
    ) {
      Promise.reject(error);
    } else if (
      (error.response.status === 401 ||
        error.response.status === 403 ||
        error.response.status === 400) &&
      originalRequest.url === "/api/token/refresh/" &&
      (!CLINIC_AUTH_LINKS.includes(
        (link) => link === window.location.pathname
      ) ||
        window.location.pathname !== CLIENT_LINKS.LOGIN)
    ) {
      if (window.location.pathname.includes("vclients")) {
        window.location.href = CLIENT_LINKS.LOGIN;
      } else if (window.location.pathname.includes("app_center")) {
        window.location.href = APP_CENTER_LINKS.LOGIN;
      } else if (window.location.pathname.includes("vapp_super")) {
        window.location.href = CLINIC_SUPER_LINKS.LOGIN;
      } else {
        window.location.href = CLINIC_LINKS.LOGIN;
      }
      return Promise.reject(error);
    } else if (error.response.status === 401 || error.response.status === 403) {
      originalRequest._retry = true;
      const refreshToken = localStorage.getItem("refresh_token");
      return axiosInstance
        .post("/api/token/refresh/", { refresh: refreshToken })
        .then((res) => {
          if (res.status === 201 || res.status === 200) {
            localStorage.setItem("access_token", res.data.access);
            axiosInstance.defaults.headers["Authorization"] =
              "Bearer " + res.data.access;
            originalRequest.headers["Authorization"] =
              "Bearer " + res.data.access;
            return axiosInstance(originalRequest);
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    } else {
      return Promise.reject(error);
    }
  }
);

export default axiosInstance;
