import React, { useState, useEffect } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { NavLink, useLocation } from "react-router-dom";

import Dash from "../../assets/Clinic/Dashboard/Dash.png";
import Dashgrey from "../../assets/Clinic/Dashboard/Dashgrey.png";
import CalenderBlue from "../../assets/Clinic/Dashboard/calenderBlue.png";

import Calender from "../../assets/Clinic/Dashboard/Calender.png";
import Users from "../../assets/Clinic/Dashboard/Users.png";
import UsersBlue from "../../assets/Clinic/Dashboard/UsersBlue.png";
import Patient from "../../assets/Clinic/Dashboard/patientIcon.png";
import PatientBlue from "../../assets/Clinic/Dashboard/patientBlue.svg";
import Inventory from "../../assets/Clinic/Dashboard/Group3.png";
import InventoryBlue from "../../assets/Clinic/Dashboard/InventoryBlue.png";
import NotificationBlue from "../../assets/Clinic/Dashboard/NotificationBlue.svg";

import Lab from "../../assets/Clinic/Dashboard/Group2.png";
import LaboratoryBlue from "../../assets/Clinic/Dashboard/LaboratoryBlue.png";

// import Notification from "../../assets/Clinic/Dashboard/Notification.png";
import NotificationIcon from "../../assets/dasboardAssets/NotificationIcon.svg";
import LeaderboardOutlinedIcon from "@mui/icons-material/LeaderboardOutlined";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import { hasChildren } from "./utils";
import { CLINIC_LINKS } from "../../links/clinicLinks";
import StaffPermContext from "../../context/StaffPermContext";
import ClinicAuthContext from "../../context/clinicAuthContext";

export default function MenuItems() {
  const location = useLocation();
  const [currentlyOpenId, setCurrentlyOpenId] = useState(0);
  const [currentPagePath, setCurrentPagePath] = useState("");

  const isActiveLink = (links) => {
    return links.includes(location.pathname);
  };

  useEffect(() => {
    setCurrentPagePath(location.pathname);
  }, [location.pathname]);

  const menu = [
    {
      icon: (
        <img
          src={isActiveLink(CLINIC_LINKS.DASHBOARD) ? Dash : Dashgrey}
          alt="icon"
        />
      ),
      title: (
        <NavLink
          onClick={() => setCurrentlyOpenId(0)}
          to={CLINIC_LINKS.DASHBOARD}
          style={({ isActive }) => (isActive ? activeStyle : nonActiveStyle)}
        >
          Dashboard
        </NavLink>
      ),
      link: CLINIC_LINKS.DASHBOARD,
      items: [],
      id: 1,
    },
    {
      icon: (
        <img
          src={
            isActiveLink([
              CLINIC_LINKS.APPOINTMENT_LIST,
              CLINIC_LINKS.STAFF_AVAILABILITY,
              CLINIC_LINKS.BOARDING_LIST,
            ])
              ? CalenderBlue
              : Calender
          }
          alt="icon"
        />
      ),
      title: "Calendar",
      id: 2,
      items: [
        {
          title: (
            <NavLink
              to={CLINIC_LINKS.APPOINTMENT_LIST}
              style={() =>
                currentPagePath.startsWith("/vapp/appointments")
                  ? activeStyleSubMenu
                  : nonActiveStyle
              }
            >
              Appointments
            </NavLink>
          ),
          link: CLINIC_LINKS.APPOINTMENT_LIST,
          items: [],
        },
        {
          title: (
            <NavLink
              to={CLINIC_LINKS.STAFF_AVAILABILITY}
              style={({ isActive }) =>
                currentPagePath.startsWith("/vapp/availability")
                  ? activeStyleSubMenu
                  : nonActiveStyle
              }
            >
              Availability
            </NavLink>
          ),
          link: CLINIC_LINKS.STAFF_AVAILABILITY,
          items: [],
        },
        {
          title: (
            <NavLink
              to={CLINIC_LINKS.BOARDING_LIST}
              style={({ isActive }) =>
                currentPagePath.startsWith("/vapp/boarding")
                  ? activeStyleSubMenu
                  : nonActiveStyle
              }
            >
              Boarding
            </NavLink>
          ),
          link: CLINIC_LINKS.BOARDING_LIST,
          items: [],
        },
      ],
    },
    {
      icon: (
        <img
          src={
            isActiveLink([CLINIC_LINKS.CLIENT_LIST, CLINIC_LINKS.PATIENT_VIEW])
              ? PatientBlue
              : Patient
          }
          alt="icon"
        />
      ),
      title: "Clients",
      id: 3,
      items: [
        {
          title: (
            <NavLink
              to={CLINIC_LINKS.CLIENT_LIST}
              style={({ isActive }) =>
                currentPagePath.startsWith("/vapp/users/clients")
                  ? activeStyleSubMenu
                  : nonActiveStyle
              }
            >
              Clients
            </NavLink>
          ),
          link: CLINIC_LINKS.CLIENT_LIST,
          items: [],
        },
        {
          title: (
            <NavLink
              to={CLINIC_LINKS.PATIENT_VIEW}
              style={({ isActive }) =>
                currentPagePath.startsWith("/vapp/users/patient")
                  ? activeStyleSubMenu
                  : nonActiveStyle
              }
            >
              Patients
            </NavLink>
          ),
          link: CLINIC_LINKS.PATIENT_VIEW,
          items: [],
        },
      ],
    },
    {
      icon: (
        <img
          src={
            isActiveLink([
              CLINIC_LINKS.USERS_STAFF,
              CLINIC_LINKS.TASK,
              CLINIC_LINKS.PAYROLL,
            ])
              ? UsersBlue
              : Users
          }
          alt="icon"
        />
      ),
      title: "Users",
      id: 4,
      items: [
        {
          title: (
            <NavLink
              to={CLINIC_LINKS.USERS_STAFF}
              style={() =>
                currentPagePath.startsWith("/vapp/users/staff")
                  ? activeStyleSubMenu
                  : nonActiveStyle
              }
            >
              Staff
            </NavLink>
          ),
          link: CLINIC_LINKS.USERS_STAFF,
          items: [],
        },
        {
          title: (
            <NavLink
              to={CLINIC_LINKS.TASK}
              style={({ isActive }) =>
                currentPagePath.startsWith("/vapp/users/task")
                  ? activeStyleSubMenu
                  : nonActiveStyle
              }
            >
              Tasks
            </NavLink>
          ),
          link: CLINIC_LINKS.TASK,
          items: [],
        },
        {
          title: (
            <NavLink
              to={CLINIC_LINKS.PAYROLL}
              style={({ isActive }) =>
                currentPagePath.startsWith("/vapp/users/payroll")
                  ? activeStyleSubMenu
                  : nonActiveStyle
              }
            >
              Payroll
            </NavLink>
          ),
          link: CLINIC_LINKS.PAYROLL,
          items: [],
        },
      ],
    },
    {
      icon: (
        <img
          src={
            isActiveLink([
              CLINIC_LINKS.PRODUCT_LIST,
              CLINIC_LINKS.MEDICINE_LIST,
              CLINIC_LINKS.INVENTORY,
              CLINIC_LINKS.INVENTORY_LIST,
            ])
              ? InventoryBlue
              : Inventory
          }
          alt="icon"
        />
      ),
      title: "Stock",
      id: 5,
      items: [
        {
          title: (
            <NavLink
              to={CLINIC_LINKS.MEDICINE_LIST}
              style={({ isActive }) =>
                currentPagePath.startsWith("/vapp/inventory/medicine")
                  ? activeStyleSubMenu
                  : nonActiveStyle
              }
            >
              Medicine
            </NavLink>
          ),
          link: CLINIC_LINKS.MEDICINE_LIST,
          items: [],
        },
        {
          title: (
            <NavLink
              to={CLINIC_LINKS.PRODUCT_LIST}
              style={({ isActive }) =>
                currentPagePath.startsWith("/vapp/inventory/product")
                  ? activeStyleSubMenu
                  : nonActiveStyle
              }
            >
              Products
            </NavLink>
          ),
          link: CLINIC_LINKS.PRODUCT_LIST,
          items: [],
        },
        {
          title: (
            <NavLink
              to={CLINIC_LINKS.INVENTORY}
              style={({ isActive }) =>
                currentPagePath.startsWith("/vapp/inventory/inventory")
                  ? activeStyleSubMenu
                  : nonActiveStyle
              }
            >
              Inventory
            </NavLink>
          ),
          link: CLINIC_LINKS.INVENTORY,
          items: [],
        },
        {
          title: (
            <NavLink
              to={CLINIC_LINKS.INVENTORY_LIST}
              style={({ isActive }) =>
                currentPagePath.startsWith("/vapp/inventory/non-inventory")
                  ? activeStyleSubMenu
                  : nonActiveStyle
              }
            >
              Non-inventory
            </NavLink>
          ),
          link: CLINIC_LINKS.INVENTORY_LIST,
          items: [],
        },
      ],
    },
    {
      icon: (
        <img
          src={
            isActiveLink([
              CLINIC_LINKS.LAB_TEST_LIST,
              CLINIC_LINKS.IMAGING,
              CLINIC_LINKS.LAB_TEST_RESULT_LIST,
              CLINIC_LINKS.ADD_TEST_RESULT,
            ])
              ? LaboratoryBlue
              : Lab
          }
          alt="icon"
        />
      ),
      title: "Laboratory",
      id: 6,
      items: [
        {
          title: (
            <NavLink
              to={CLINIC_LINKS.LAB_TEST_LIST}
              style={({ isActive }) =>
                currentPagePath.startsWith("/vapp/laboratory/lab-test-list")
                  ? activeStyleSubMenu
                  : nonActiveStyle
              }
            >
              Lab Test List
            </NavLink>
          ),
          link: CLINIC_LINKS.LAB_TEST_LIST,
          items: [],
        },
        {
          title: (
            <NavLink
              to={CLINIC_LINKS.IMAGING}
              style={({ isActive }) =>
                currentPagePath.startsWith("/vapp/laboratory/imaging")
                  ? activeStyleSubMenu
                  : nonActiveStyle
              }
            >
              Imaging
            </NavLink>
          ),
          link: CLINIC_LINKS.IMAGING,
          items: [],
        },
        {
          title: (
            <NavLink
              to={CLINIC_LINKS.LAB_TEST_RESULT_LIST}
              style={({ isActive }) =>
                currentPagePath.startsWith("/vapp/laboratory/lab-test-result")
                  ? activeStyleSubMenu
                  : nonActiveStyle
              }
            >
              Lab Test Results
            </NavLink>
          ),
          link: CLINIC_LINKS.LAB_TEST_RESULT_LIST,
          items: [],
        },
        {
          title: (
            <NavLink
              to={CLINIC_LINKS.ADD_TEST_RESULT}
              style={({ isActive }) =>
                currentPagePath.startsWith("/vapp/laboratory/add-test-result")
                  ? activeStyleSubMenu
                  : nonActiveStyle
              }
            >
              Add Test Result
            </NavLink>
          ),
          link: CLINIC_LINKS.ADD_TEST_RESULT,
          items: [],
        },
      ],
    },
    {
      icon: isActiveLink([
        CLINIC_LINKS.ACCOUNTS,
        CLINIC_LINKS.PROFIT_AND_LOSS_LIST,
        CLINIC_LINKS.PAYMENT,
        CLINIC_LINKS.INVOICE,
        CLINIC_LINKS.STATEMENT_OF_ACCOUNT,
      ]) ? (
        <CreditCardOutlinedIcon sx={{ color: "#009eed" }} />
      ) : (
        <CreditCardOutlinedIcon />
      ), //<img src={Acct} alt="icon" />,
      title: "Accounting",
      id: 7,
      items: [
        {
          title: (
            <NavLink
              to={CLINIC_LINKS.ACCOUNTS}
              style={({ isActive }) =>
                currentPagePath.startsWith("/vapp/accounts/container")
                  ? activeStyleSubMenu
                  : nonActiveStyle
              }
            >
              Accounts
            </NavLink>
          ),
          link: CLINIC_LINKS.ACCOUNTS,
          items: [],
        },
        {
          title: (
            <NavLink
              to={CLINIC_LINKS.PROFIT_AND_LOSS_LIST}
              style={({ isActive }) =>
                currentPagePath.startsWith(
                  "/vapp/accounts/profit-and-loss/list"
                )
                  ? activeStyleSubMenu
                  : nonActiveStyle
              }
            >
              Profit And Loss
            </NavLink>
          ),
          link: CLINIC_LINKS.PROFIT_AND_LOSS_LIST,
          items: [],
        },
        {
          title: (
            <NavLink
              to={CLINIC_LINKS.PAYMENT}
              style={({ isActive }) =>
                currentPagePath.startsWith("/vapp/accounting/payment")
                  ? activeStyleSubMenu
                  : nonActiveStyle
              }
            >
              Payments
            </NavLink>
          ),
          link: CLINIC_LINKS.PAYMENT,
          items: [],
        },
        {
          title: (
            <NavLink
              to={CLINIC_LINKS.INVOICE}
              style={({ isActive }) =>
                currentPagePath.startsWith("/vapp/accounting/invoice")
                  ? activeStyleSubMenu
                  : nonActiveStyle
              }
            >
              Invoices
            </NavLink>
          ),
          link: CLINIC_LINKS.INVOICE,
          items: [],
        },
        {
          title: (
            <NavLink
              to={CLINIC_LINKS.STATEMENT_OF_ACCOUNT}
              style={({ isActive }) =>
                currentPagePath.startsWith(
                  "/vapp/accounting/statement-of-account"
                )
                  ? activeStyleSubMenu
                  : nonActiveStyle
              }
            >
              Statements of Account
            </NavLink>
          ),
          link: CLINIC_LINKS.STATEMENT_OF_ACCOUNT,
          items: [],
        },
      ],
    },
    {
      icon: isActiveLink([
        CLINIC_LINKS.ACCOUNT_RECEIVABLE,
        CLINIC_LINKS.CONTROLLED_SUBSTANCE,
        CLINIC_LINKS.INVENTORY_BILLABLE_ITEMS,
        CLINIC_LINKS.SALES_OF_DAYS,
        CLINIC_LINKS.PRACTICE_TRENDS,
        CLINIC_LINKS.DIAGNOSIS_REPORT,
      ]) ? (
        <LeaderboardOutlinedIcon sx={{ color: "#009eed" }} />
      ) : (
        <LeaderboardOutlinedIcon />
      ),
      id: 8,
      title: "Reports",
      items: [
        {
          title: (
            <NavLink
              to={CLINIC_LINKS.ACCOUNT_RECEIVABLE}
              style={({ isActive }) =>
                currentPagePath.startsWith("/vapp/report/account-receivable")
                  ? activeStyleSubMenu
                  : nonActiveStyle
              }
            >
              Account Receivable
            </NavLink>
          ),
          link: CLINIC_LINKS.ACCOUNT_RECEIVABLE,
          items: [],
        },
        {
          title: (
            <NavLink
              to={CLINIC_LINKS.CONTROLLED_SUBSTANCE}
              style={({ isActive }) =>
                currentPagePath.startsWith("/vapp/report/controlled-substance")
                  ? activeStyleSubMenu
                  : nonActiveStyle
              }
            >
              Controlled Substance logging
            </NavLink>
          ),
          link: CLINIC_LINKS.CONTROLLED_SUBSTANCE,
          items: [],
        },
        {
          title: (
            <NavLink
              to={CLINIC_LINKS.INVENTORY_BILLABLE_ITEMS}
              style={({ isActive }) =>
                currentPagePath.startsWith(
                  "/vapp/report/inventory-and-billable-items"
                )
                  ? activeStyleSubMenu
                  : nonActiveStyle
              }
            >
              Inventory and Billable items
            </NavLink>
          ),
          link: CLINIC_LINKS.INVENTORY_BILLABLE_ITEMS,
          items: [],
        },
        {
          title: (
            <NavLink
              to={CLINIC_LINKS.SALES_OF_DAYS}
              style={({ isActive }) =>
                currentPagePath.startsWith("/vapp/report/sales-of-days")
                  ? activeStyleSubMenu
                  : nonActiveStyle
              }
            >
              Sales Report
            </NavLink>
          ),
          link: CLINIC_LINKS.SALES_OF_DAYS,
          items: [],
        },
        {
          title: (
            <NavLink
              to={CLINIC_LINKS.PRACTICE_TRENDS}
              style={({ isActive }) =>
                currentPagePath.startsWith("/vapp/report/practice-trends")
                  ? activeStyleSubMenu
                  : nonActiveStyle
              }
            >
              Practice Trends
            </NavLink>
          ),
          link: CLINIC_LINKS.PRACTICE_TRENDS,
          items: [],
        },
        {
          title: (
            <NavLink
              to={CLINIC_LINKS.DIAGNOSIS_REPORT}
              style={({ isActive }) =>
                currentPagePath.startsWith("/vapp/report/diagnosis")
                  ? activeStyleSubMenu
                  : nonActiveStyle
              }
            >
              Diagnosis Trend
            </NavLink>
          ),
          link: CLINIC_LINKS.DIAGNOSIS_REPORT,
          items: [],
        },
        {
          title: (
            <NavLink
              to={CLINIC_LINKS.DOCTOR_REPORT}
              style={({ isActive }) =>
                currentPagePath.startsWith(CLINIC_LINKS.DOCTOR_REPORT)
                  ? activeStyleSubMenu
                  : nonActiveStyle
              }
            >
              Doctor's Trend
            </NavLink>
          ),
          link: CLINIC_LINKS.DOCTOR_REPORT,
          items: [],
        },
        {
          title: (
            <NavLink
              to={CLINIC_LINKS.TASK_REPORT}
              style={({ isActive }) =>
                currentPagePath.startsWith(CLINIC_LINKS.TASK_REPORT)
                  ? activeStyleSubMenu
                  : nonActiveStyle
              }
            >
              Tasks Trend
            </NavLink>
          ),
          link: CLINIC_LINKS.TASK_REPORT,
          items: [],
        },
      ],
    },
    {
      title: "Event Logs",
      icon: (
        <img
          src={
            isActiveLink([
              CLINIC_LINKS.NOTIFICATION_LIST,
              CLINIC_LINKS.NOTIFICATION_BROADCAST_LIST,
              CLINIC_LINKS.NOTIFICATION_ACTIVITY_LOGS,
            ])
              ? NotificationBlue
              : NotificationIcon
          }
        />
      ),
      id: 9,
      items: [
        {
          title: (
            <NavLink
              to={CLINIC_LINKS.NOTIFICATION_LIST}
              style={({ isActive }) =>
                currentPagePath.startsWith("/vapp/notification/list")
                  ? activeStyleSubMenu
                  : nonActiveStyle
              }
            >
              Notifications
            </NavLink>
          ),
          link: CLINIC_LINKS.NOTIFICATION_LIST,
          items: [],
        },
        {
          title: (
            <NavLink
              to={CLINIC_LINKS.NOTIFICATION_BROADCAST_LIST}
              style={({ isActive }) =>
                currentPagePath.startsWith("/vapp/notification/broadcast")
                  ? activeStyleSubMenu
                  : nonActiveStyle
              }
            >
              Broadcast list
            </NavLink>
          ),
          link: CLINIC_LINKS.NOTIFICATION_BROADCAST_LIST,
          items: [],
        },
        {
          title: (
            <NavLink
              to={CLINIC_LINKS.NOTIFICATION_ACTIVITY_LOGS}
              style={({ isActive }) =>
                currentPagePath.startsWith(
                  "/vapp/notification/activity-log-list"
                )
                  ? activeStyleSubMenu
                  : nonActiveStyle
              }
            >
              Activity Log
            </NavLink>
          ),
          link: CLINIC_LINKS.NOTIFICATION_ACTIVITY_LOGS,
          items: [],
        },
      ],
    },
  ];

  return menu.map((item, key) => (
    <MenuItem
      key={key}
      item={item}
      currentlyOpenId={currentlyOpenId}
      setCurrentlyOpenId={setCurrentlyOpenId}
    />
  ));
}

const MenuItem = ({ item, currentlyOpenId, setCurrentlyOpenId }) => {
  const { sideNavExtraModViaAppLabelAndAppModel } =
    React.useContext(ClinicAuthContext);
  const { staffPermCheck, staffPermCheckMultiLevel } =
    React.useContext(StaffPermContext);
  const Component = hasChildren(item)
    ? staffPermCheckMultiLevel(item.items, MultiLevel)
    : sideNavExtraModViaAppLabelAndAppModel(
        item.link,
        staffPermCheck(item.link, SingleLevel)
      );
  return (
    <Component
      item={item}
      currentlyOpenId={currentlyOpenId}
      setCurrentlyOpenId={setCurrentlyOpenId}
    />
  );
};

const SingleLevel = ({ item }) => {
  return (
    <ListItem>
      <ListItemIcon>{item.icon}</ListItemIcon>
      <ListItemText primary={item.title} />
    </ListItem>
  );
};

const MultiLevel = ({ item, currentlyOpenId, setCurrentlyOpenId }) => {
  const { items: children } = item;
  // const [open, setOpen] = useState(false);

  const open = currentlyOpenId === item.id;

  const handleClick = () => {
    if (open) {
      setCurrentlyOpenId(0);
    } else {
      setCurrentlyOpenId(item.id);
    }
  };

  return (
    <React.Fragment>
      <ListItem
        onClick={handleClick}
        sx={{ marginTop: "14px", cursor: "pointer" }}
      >
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.title} />
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <Collapse in={open} timeout="auto" sx={{ marginBottom: "14px" }}>
        <List component="div" disablePadding>
          {children.map((child, key) => (
            <MenuItem key={key} item={child} />
          ))}
        </List>
      </Collapse>
    </React.Fragment>
  );
};

let activeStyle = {
  textDecoration: "none",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  color: "#203139",
  fontWeight: "500",
  fontSize: "14px",
};

let activeStyleSubMenu = {
  textDecoration: "none",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  color: "#009EED",
  borderRadius: "4px",
  height: "28px",
  fontWeight: "500",
  fontSize: "14px",
  backgroundColor: "#F0FAFE",
};
let nonActiveStyle = {
  textDecoration: "none",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  color: "#6A767B",
  fontWeight: "400",
  fontSize: "14px",
};
