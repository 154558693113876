import { Box, Grid } from '@mui/material'
import React from 'react'

const ClientRegMainContainer = ({children}) => {
  return (
        <Grid item sm={7} md={9} sx={{
            // marginTop: {lg: "85px", md: "70px", sm: "30px", xs: "0px"},
            minHeight:'100vh',
            backgroundColor:'backgroundTypes.main',
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
        }}>
                <Box sx={{
                    
                    backgroundColor:"backgroundTypes.main",
                    width:'100%',
                    px:3,
                    marginLeft: {md: "128px", sm: "80px"},
                    mt:6
                }}>
                    {children}
                </Box>
        </Grid>
    
  )
}

export default ClientRegMainContainer;