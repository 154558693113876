import { Box, Drawer, IconButton, Toolbar } from "@mui/material";
import React from "react";
import Logo from "../../../assets/logo.png";
import { useTheme, styled } from "@mui/material/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DrawerContext from "../../../context/drawerContext";
import SuperSideNavItems from "./SideNavItems";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const drawerWidth = 250;

const SuperSideNav = () => {
  const { open, handleDrawerOpen, matches, drawerRef } =
    React.useContext(DrawerContext);
  const theme = useTheme;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        // width: "252px",
      }}
    >
      <Drawer
        variant={matches ? "permanent" : "responsive"}
        ref={drawerRef}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
          display: "flex",
          flexDirection: "row",
        }}
        open={open}
        onClose={handleDrawerOpen}
      >
        <Toolbar
          sx={{
            borderRadius: "0px",
            display: { xs: "flex" },
            alignItems: "center",
            flexDirection: "column",
            width: { md: 200 },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              mb: 3,
            }}
          >
            <img
              src={Logo}
              alt="smartdvm-logo||uploaded logo"
              style={{
                marginTop: "12px",
                marginLeft: "24px",
                maxHeight: "6em",
                maxWidth: "8em",
              }}
            />
            <DrawerHeader>
              {!matches && (
                <IconButton onClick={handleDrawerOpen}>
                  {theme.direction === "rtl" ? (
                    <ChevronRightIcon />
                  ) : (
                    <ChevronLeftIcon />
                  )}
                </IconButton>
              )}
            </DrawerHeader>
          </Box>

          <SuperSideNavItems />
        </Toolbar>
      </Drawer>
    </Box>
  );
};

export default SuperSideNav;
