import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";

import DeleteIcon from "../assets/appointment/Deleteicon.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  p: 4,
  boxShadow: "0px 24px 32px rgba(171, 171, 171, 0.16)",
  borderRadius: "16px",
};

export default function PermAlertModal({ open, onClose }) {
  //   const [open, setOpen] = React.useState(false);
  //   const handleOpen = () => setOpen(true);
  //   const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            onClick={onClose}
            sx={{
              position: "absolute",
              top: "24px",
              right: "24px",
              cursor: "pointer",
            }}
          >
            <CloseIcon />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "32px",
            }}
          >
            <img src={DeleteIcon} alt="icon" />
          </Box>

          <Box sx={{ marginBottom: "66px" }}>
            <Typography
              sx={{
                fontWeight: "500",
                fontSize: "24px",
                color: "#000000",
                textAlign: "center",
                paddingBottom: "6px",
              }}
            >
              Access Denied!!!
            </Typography>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "20px",
                color: "#64697A",
                textAlign: "center",
              }}
            >
              You Don't have the required permission.
            </Typography>
          </Box>
         
        </Box>
      </Modal>
    </div>
  );
}
