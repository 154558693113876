import { useMediaQuery } from "@mui/material";
import React, { createContext, useState, useRef, useEffect } from "react";
import { useTheme } from "@mui/material/styles";

const DrawerContext = createContext(null);

export const DrawerProvider = ({ children }) => {
  const theme = useTheme();
  const drawerRef = useRef(null);

  const [open, setOpen] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(!open);
  };
  const handleDrawerClose = () => setOpen(false);

  const matches = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    function handleClickOutside(event) {
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        handleDrawerOpen();
      }
    }

    if (!matches) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [matches]);

  return (
    <DrawerContext.Provider
      value={{
        open,
        handleDrawerOpen,
        handleDrawerClose,
        matches,
        drawerRef,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
};

export default DrawerContext;
