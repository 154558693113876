import React from "react";
import { PageProgress } from "./component/ProgressIndicators/PageProgress";
import AppCenterDefaultLayout from "./component/DefaultLayout/AppCenterDefaultLayout";

const AppCenterPageLoader = () => {
  return (
    <div>
      <PageProgress />
    </div>
  );
};

export default AppCenterPageLoader;
