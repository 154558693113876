import AccountCircleOutlined from "@mui/icons-material/AccountCircleOutlined";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Visibility from "@mui/icons-material/Visibility";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Container,
  createTheme,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import NewClientLoginContainer from "../../../scomponents/utils/NewClientLoginContainer";
import SmartdvmLogo from "../../../assets/logo.png";
import LockIcon from "@mui/icons-material/Lock";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Link, useNavigate } from "react-router-dom";
import { handleLogin } from "../../../api/API";
import ClinicAuthContext from "../../../context/clinicAuthContext";
import { getUser, getClinicClientAPI } from "../../../api/getUserMain";
import { CLIENT_LINKS } from "../../../links/clientLinks";
import { INITIAL_STATE, postReducer } from "../../../reducers/postReducer";
import { ACTION_TYPES } from "../../../reducers/ActionTypes";
import { ButtonSubmitProgress } from "../../../component/ProgressIndicators/PageProgress";
import capitalizeFirstLetter from "../../../features/capitalizeFirstLetter";

const Login = () => {
  const navigate = useNavigate();
  const { clinic, setUser } = useContext(ClinicAuthContext);

  const [values, setValues] = React.useState({
    email: "",
    password: "",
    showPassword: false,
  });
  const [state, dispatch] = React.useReducer(postReducer, INITIAL_STATE);

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
    dispatch({ type: ACTION_TYPES.RESET });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const getClientInfoSucess = (data) => {
    setUser(data);
    navigate(CLIENT_LINKS.DASHBOARD);
  };
  const handleSuccessAction = () => {
    getClinicClientAPI(getClientInfoSucess, handleErrorAction, clinic.id);
  };
  const handleErrorAction = () => {
    dispatch({ type: ACTION_TYPES.FETCH_ERROR });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch({ type: ACTION_TYPES.FETCH_START });
    handleLogin(values, handleSuccessAction, handleErrorAction);
  };

  return (
    <NewClientLoginContainer>
      <>
        <Box component="form" onSubmit={handleSubmit}>
          {/* This section will only show when it's mobile screen */}
          <Box sx={{ display: { xs: "block", sm: "none" } }}>
            <Box>
              <img
                src={SmartdvmLogo}
                alt="smartdvm-logo"
                style={{
                  marginLeft: "3rem",
                  marginTop: "0.5rem",
                  maxHeight: "12em",
                  maxWidth: "14em",
                  cursor: "pointer",
                }}
              />
            </Box>
            {clinic.name && (
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 400,
                  marginBottom: "1rem",
                  alignItems: "left",
                }}
              >
                Welcome to {clinic.name}{" "}
              </Typography>
            )}
          </Box>
          {/* It ends here */}
          <Typography
            variant="h4"
            sx={{
              fontWeight: 400,
              marginBottom: "2rem",
              alignItems: "left",
            }}
          >
            Log In
          </Typography>
          <Box>
            <TextField
              margin="normal"
              required
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              sx={{ width: { xs: "35ch", sm: "40ch" } }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircleOutlined />
                  </InputAdornment>
                ),
                style: { borderRadius: "10px" },
              }}
              onChange={handleChange}
            />
          </Box>
          <Box>
            <TextField
              margin="normal"
              required
              id="password"
              label="Password"
              name="password"
              sx={{ width: { xs: "35ch", sm: "40ch" } }}
              type={values.showPassword ? "text" : "password"}
              autoFocus
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
                style: { borderRadius: "10px" },
              }}
              onChange={handleChange}
            />
          </Box>
          <Typography
            variant="caption"
            sx={{
              color: "#009EED",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: "10px",
            }}
          >
            <Link
              to={CLIENT_LINKS.RESET_PASSWORD}
              style={{ textDecoration: "none", color: "#009EED" }}
            >
              {" "}
              Forgot Password
            </Link>
          </Typography>
          <Button
            variant="contained"
            type="submit"
            sx={{
              mt: 5,
              paddingY: "14px",
              width: { xs: "35ch", sm: "38ch" },
              borderRadius: "10px",
            }}
            onClick={handleSubmit}
          >
            Login
            {state.loading && <ButtonSubmitProgress />}
          </Button>
        </Box>
        <Box sx={{ my: 2 }}>
          {state.error && clinic.id ? (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              Email Or Password Incorrect. Check and Login again. Notice: only
              clients of {clinic.name} can login
            </Alert>
          ) : (
            ""
          )}
          {state.error && !clinic.id ? (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              Fetch Error Kindly reload the page
              {console.log(clinic.id)}
            </Alert>
          ) : (
            ""
          )}
        </Box>
      </>
    </NewClientLoginContainer>
  );
};

export default Login;
