import React, { Suspense, useContext, lazy } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { clearUserTokens, getFullUserInfo } from "./api/getUserMain";
import {
  getClinicAuthenticatedStaffDetail,
  getClinicDetail,
  getClinicDetailSlug,
} from "./api/API";
import {
  getCurrency,
  getRoleSettingsDetailByName,
} from "./api/Settings/Settings";
import ClinicAuthContext, {
  ClinicAuthProvider,
} from "./context/clinicAuthContext";
import { getClinicSlugFromSubHost } from "./api/getClinicSlugFromSubHost";
import StaffRolePermContext from "./context/StaffPermContext";

import { CLINIC_LINKS } from "./links/clinicLinks";
import BioRegister from "./pages/clinic/registration/BioRegister";
import ClinicPageLoader from "./ClinicPageLoader";
import { ClinicRoute } from "./scomponents/profileRoutes/ClinicRoute";
import DefaultLayout from "./component/Clinic/DefaultLayout/ClinicDefaultLayout";
import { getClinicBillActiveForToday } from "./api/Accounting/PracticeSuperAccountingAPI";
import ForgotPassword from "./pages/client/registration/ForgotPassword";

const ArchivedAccount = lazy(() =>
  import("./pages/clinic/Acounting/ArchivedAccounts")
);

const DefaultTaxSettings = lazy(() =>
  import("./pages/settings/Payement/DefaultTax")
);
const ClinicSubscribe = lazy(() =>
  import("./pages/clinic/main/ClinicSubscribe")
);
const Dashboard = lazy(() => import("./pages/clinic/main/Dashboard"));

const AppointmentList = lazy(() =>
  import("./pages/clinic/appointment/AppointmentList")
);
const CreatePrescription = lazy(() =>
  import("./pages/clinic/appointment/CreatePrescription")
);
const AppointmentDailyList = lazy(() =>
  import("./pages/clinic/appointment/AppointmnetDailyList")
);
const AppointmentRescheduleBoarding = lazy(() =>
  import("./pages/clinic/appointment/AppointmentRescheduleBoarding")
);

const AppointmentDetailMedical = lazy(() =>
  import("./pages/clinic/appointment/AppointmentDetailMedical")
);
const AppointmentReschedule = lazy(() =>
  import("./pages/clinic/appointment/AppointmentReschedule")
);
const Soap = lazy(() => import("./pages/clinic/appointment/soap/Soap"));
const SoapFormSubjective = lazy(() =>
  import("./pages/clinic/appointment/soap/SoapFormSubjective")
);
const SoapFormObjective = lazy(() =>
  import("./pages/clinic/appointment/soap/SoapFormObjective")
);
const SoapFormAssesment = lazy(() =>
  import("./pages/clinic/appointment/soap/SoapFormAssesment")
);
const SoapFormPlan = lazy(() =>
  import("./pages/clinic/appointment/soap/SoapFormPlan")
);
const SoapEstimatedPayment = lazy(() =>
  import("./pages/clinic/appointment/soap/SoapEstimatedPayment")
);
const SoapEstimatedPaymentPrintView = lazy(() =>
  import("./pages/clinic/appointment/soap/SoapEstimatedPaymentPrintView")
);
const AppointmentCreate = lazy(() =>
  import("./pages/clinic/appointment/AppointmentCreate")
);
const StaffAvailability = lazy(() =>
  import("./pages/clinic/availability/StaffAvailability")
);
const StaffAvailabilityDetail = lazy(() =>
  import("./pages/clinic/availability/StaffAvailabilityDetail")
);

const StaffList = lazy(() => import("./pages/clinic/Staff/StaffList"));
const AddNewStaff = lazy(() => import("./pages/clinic/Staff/AddNewStaff"));
const Password = lazy(() => import("./pages/clinic/Staff/Password"));
const StaffOverview = lazy(() => import("./pages/clinic/Staff/StaffOverview"));
const StaffOverviewEdit = lazy(() =>
  import("./pages/clinic/Staff/StaffOverviewEdit")
);
const StaffWorkingHoursEdit = lazy(() =>
  import("./pages/clinic/Staff/StaffWorkingHoursEdit")
);
const StaffWorkingHours = lazy(() =>
  import("./pages/clinic/Staff/StaffWorkingHours")
);
const AccountInfoEditMode = lazy(() =>
  import("./pages/clinic/Profile/AccountInfoEditMode")
);
const AccountInfoViewMode = lazy(() =>
  import("./pages/clinic/Profile/AccountInfoViewMode")
);
const DepartmentInfo = lazy(() =>
  import("./pages/clinic/Profile/DepartmentInfo")
);
const DepartmentInfoViewMode = lazy(() =>
  import("./pages/clinic/Profile/DepartmentInfoViewMode")
);
const EducationEditMode = lazy(() =>
  import("./pages/clinic/Profile/EducationEditMode")
);
const EducationEditModeViewMode = lazy(() =>
  import("./pages/clinic/Profile/EducationViewMode")
);
const ProfilePassword = lazy(() =>
  import("./pages/clinic/Profile/ProfilePassword")
);
const StaffLeave = lazy(() => import("./pages/clinic/Profile/StaffLeave"));

const Task = lazy(() => import("./pages/clinic/Task/Task"));
const AddNewTask = lazy(() => import("./pages/clinic/Task/AddNewTask"));
const EditTask = lazy(() => import("./pages/clinic/Task/EditTask"));
const TaskDetails = lazy(() => import("./pages/clinic/Task/TaskDetails"));
const Payroll = lazy(() => import("./pages/clinic/Payroll/Payroll"));

const AddPayroll = lazy(() => import("./pages/clinic/Payroll/AddPayroll"));
const SubmitPayroll = lazy(() =>
  import("./pages/clinic/Payroll/SubmitPayroll")
);
const PayslipHistoryDetails = lazy(() =>
  import("./pages/clinic/Payroll/PayslipHistoryDetails")
);
const UpdatePayroll = lazy(() =>
  import("./pages/clinic/Payroll/UpdatePayRoll")
);
const AddPatient = lazy(() => import("./pages/clinic/Clients/AddPatient"));
const ClientList = lazy(() => import("./pages/clinic/Clients/ClientList"));
const PatientView = lazy(() => import("./pages/clinic/Clients/PatientView"));
const PatientList = lazy(() => import("./pages/clinic/Clients/PatientDetails"));
const EditPersonalInfo = lazy(() =>
  import("./pages/clinic/Clients/EditPersonalInfo")
);
const EditPatientInfo = lazy(() =>
  import("./pages/clinic/Clients/EditPatientInfo")
);
const ClientDocumentList = lazy(() =>
  import("./pages/clinic/Clients/ClientDocumentList")
);

const AddNewInvetory = lazy(() =>
  import("./pages/clinic/Inventory/AddNewInvetory")
);
const EditInvetory = lazy(() =>
  import("./pages/clinic/Inventory/EditNonInventory")
);
const InvetoryList = lazy(() =>
  import("./pages/clinic/Inventory/InventoryList")
);
const ProductList = lazy(() => import("./pages/clinic/Inventory/ProductList"));
const AddProduct = lazy(() => import("./pages/clinic/Inventory/AddProducts"));
const EditProduct = lazy(() => import("./pages/clinic/Inventory/EditProduct"));

const ProductDetails = lazy(() =>
  import("./pages/clinic/Inventory/ProductDetails")
);
const AddNewMedicine = lazy(() =>
  import("./pages/clinic/Inventory/AddNewMedicine")
);
const EditMedicine = lazy(() =>
  import("./pages/clinic/Inventory/EditMedicine")
);
const MedicineList = lazy(() =>
  import("./pages/clinic/Inventory/MedicineList")
);
const MedicineDetails = lazy(() =>
  import("./pages/clinic/Inventory/MedicineDetails")
);
const Inventory = lazy(() => import("./pages/clinic/Inventory/Inventory"));

const AddInventory = lazy(() =>
  import("./pages/clinic/Inventory/AddInventory")
);
const Payment = lazy(() => import("./pages/clinic/Acounting/Payment"));
const Invoice = lazy(() => import("./pages/clinic/Acounting/Invoice"));
const AddInvoice = lazy(() => import("./pages/clinic/Acounting/AddInvoice"));
const AddNonCustomerInvoice = lazy(() =>
  import("./pages/clinic/Acounting/AddNonCustomerInvoice")
);
const ProfitAndLossCreate = lazy(() =>
  import("./pages/clinic/Acounting/ProfitAndLoss/ProfitAndLossCreate")
);
const ProfitAndLossList = lazy(() =>
  import("./pages/clinic/Acounting/ProfitAndLoss/ProfitAndLossList")
);
const ProfitAndLossDetail = lazy(() =>
  import("./pages/clinic/Acounting/ProfitAndLoss/ProfitAndLossDetail")
);
const ProfitAndLossEdit = lazy(() =>
  import("./pages/clinic/Acounting/ProfitAndLoss/ProfitAndLossEdit")
);
const StatementOfAccount = lazy(() =>
  import("./pages/clinic/Acounting/StatementOfAccount")
);
const Labtest = lazy(() => import("./pages/clinic/Laboratory/Labtest"));
const CreateTest = lazy(() => import("./pages/clinic/Laboratory/CreateTest"));
const AddTestResult = lazy(() =>
  import("./pages/clinic/Laboratory/AddTestResult")
);

const TestDetails = lazy(() => import("./pages/clinic/Laboratory/TestDetails"));
const AddStatement = lazy(() =>
  import("./pages/clinic/Acounting/AddStatement")
);
const StatementOfAccountDetails = lazy(() =>
  import("./pages/clinic/Acounting/StatementOfAccountDetails")
);
const InvoiceDetails = lazy(() =>
  import("./pages/clinic/Acounting/InvoiceDetails")
);
const AccountReceivable = lazy(() =>
  import("./pages/clinic/Reports/AccountReceivable")
);
const DoctorsReport = lazy(() =>
  import("./pages/clinic/Reports/DoctorsReport")
);
const StaffTaskReport = lazy(() => import("./pages/clinic/Reports/TaskReport"));
const StaffTaskReportDetail = lazy(() =>
  import("./pages/clinic/Reports/TaskReportDetail")
);

const AccountReceivableDetail = lazy(() =>
  import("./pages/clinic/Reports/AccountReceivableDetail")
);
const ControlledSubstanceDetails = lazy(() =>
  import("./pages/clinic/Reports/ControlledSubstanceDetails")
);
const ControlledSubstance = lazy(() =>
  import("./pages/clinic/Reports/ControlledSubstance")
);
const InventoryBillableItems = lazy(() =>
  import("./pages/clinic/Reports/InventoryBillableItems")
);

const SalesOfDays = lazy(() => import("./pages/clinic/Reports/SalesOfDays"));
const PracticeTrends = lazy(() =>
  import("./pages/clinic/Reports/PracticeTrends")
);

const AppointmentDetailContainer = lazy(() =>
  import("./pages/clinic/appointment/AppointmentDetailContainer")
);
const SoapPlanEdit = lazy(() =>
  import("./pages/clinic/appointment/soap/edit/SoapFormPlan")
);
const SoapFormAssesmentEdit = lazy(() =>
  import("./pages/clinic/appointment/soap/edit/SoapFormAssesment")
);
const SoapFormObjectiveEdit = lazy(() =>
  import("./pages/clinic/appointment/soap/edit/SoapFormObjective")
);
const SoapFormSubjectiveEdit = lazy(() =>
  import("./pages/clinic/appointment/soap/edit/SoapFormSubjective")
);
const AddClient = lazy(() => import("./pages/clinic/Clients/AddClient"));
const LabTestResultList = lazy(() =>
  import("./pages/clinic/Laboratory/LabtestResultList")
);
const TestResultDetails = lazy(() =>
  import("./pages/clinic/Laboratory/TestResultDetail")
);
const EditTest = lazy(() => import("./pages/clinic/Laboratory/EditTest"));
const EditTestResult = lazy(() =>
  import("./pages/clinic/Laboratory/EditTestResult")
);
const LeaveSettings = lazy(() => import("./pages/settings/HR/LeaveSettings"));
// const InventorySettings = lazy(() => import( "./pages/settings/InventorySettings"));
// const CategorySettings = lazy(() => import( "./pages/settings/InventorySettings/CategorySettings"));
const AdmissionType = lazy(() =>
  import("./pages/settings/AppointmentSettings/AdmissionType")
);
// const DoctorsNameSettings = lazy(() => import( "./pages/settings/DoctorsNameSettings"));
const PaymentSettings = lazy(() =>
  import("./pages/settings/SalesAndInvoice/PaymentSettings")
);
const InvoiceSettings = lazy(() =>
  import("./pages/settings/SalesAndInvoice/InvoiceSettings")
);
const Department = lazy(() => import("./pages/settings/Staff/Department"));
const SpeciesSettings = lazy(() =>
  import("./pages/settings/Patient/SpeciesSettings")
);
const BreedSettings = lazy(() =>
  import("./pages/settings/Patient/BreedSettings")
);
const ColorSettings = lazy(() =>
  import("./pages/settings/Patient/ColorSettings")
);
const GenderSettings = lazy(() =>
  import("./pages/settings/Patient/GenderSettings")
);
// const Roles = lazy(() => import( "./pages/settings/Staff/Roles"));
const InitialComplaint = lazy(() =>
  import("./pages/settings/ClinicalItems/InitialComplaint")
);
const VitalFindings = lazy(() =>
  import("./pages/settings/ClinicalItems/VitalFindings")
);
const EditInvoice = lazy(() => import("./pages/clinic/Acounting/EditInvoice"));
const TestResultDetailPrint = lazy(() =>
  import("./pages/clinic/Laboratory/TestResultDetailPrint")
);
const PaymentMethod = lazy(() =>
  import("./pages/client/Dashboard/Appointment/PaymentMethod")
);
const ProductSupplier = lazy(() =>
  import("./pages/settings/Inventory/ProductSupplier")
);
const CageTypeSettings = lazy(() =>
  import("./pages/settings/AppointmentSettings/CageTypeSettings")
);
const CageSizeSettings = lazy(() =>
  import("./pages/settings/AppointmentSettings/CageSizeSettings")
);
const CategorySettings = lazy(() =>
  import("./pages/settings/Inventory/CategorySettings")
);
const CageSizeSettingsDetails = lazy(() =>
  import("./pages/settings/AppointmentSettings/CageSizeSettingsDetails")
);

const CageSizeEdit = lazy(() =>
  import("./pages/settings/AppointmentSettings/CageSizeEdit")
);
const Currency = lazy(() => import("./pages/settings/Payement/Currency"));
const ClinicPaymentMethod = lazy(() =>
  import("./pages/clinic/Acounting/ClinicPayment")
);
const PayrollDetails = lazy(() =>
  import("./pages/clinic/Payroll/PayrollDetails")
);
const NonInventoryDetail = lazy(() =>
  import("./pages/clinic/Inventory/NonInventoryDetail")
);
const BoardingList = lazy(() =>
  import("./pages/clinic/appointment/BoardingList")
);
const AccountsLayout = lazy(() => import("./pages/clinic/Acounting/Accounts"));
const AccountDetail = lazy(() =>
  import("./pages/clinic/Acounting/AccountDetail")
);
const AccountEdit = lazy(() => import("./pages/clinic/Acounting/EditAccount"));
const AddAccount = lazy(() =>
  import("./pages/clinic/Acounting/AccountsAdd/AddAccount")
);
const AddExpenses = lazy(() =>
  import("./pages/clinic/Acounting/AccountsAdd/AddExpenses")
);

const NotificationList = lazy(() =>
  import("./pages/clinic/notification/NotificationList")
);
const BroadcastForm = lazy(() =>
  import("./pages/clinic/notification/BroadcastForm")
);
const PaymentGateway = lazy(() =>
  import("./pages/settings/Payement/PaymentGateway")
);
const PaymentGatewayList = lazy(() =>
  import("./pages/settings/Payement/PaymentGatewayList")
);
const PaymentGatewayEdit = lazy(() =>
  import("./pages/settings/Payement/PaymentGatewayEdit")
);
const ActivityLogList = lazy(() =>
  import("./pages/clinic/notification/ActivityLogList")
);
const BroadcastList = lazy(() =>
  import("./pages/clinic/notification/BroadcastList")
);
const Permission = lazy(() => import("./pages/settings/Staff/Permission"));
const RoleStaffMembers = lazy(() =>
  import("./pages/settings/Staff/RoleStaffMembers")
);
const Diagnosis = lazy(() =>
  import("./pages/settings/ClinicalItems/Diagnosis")
);
const Title = lazy(() => import("./pages/settings/Staff/Title"));
const GoogleSettings = lazy(() =>
  import("./pages/settings/Messaging/GoogleSettings")
);
const StaffGender = lazy(() => import("./pages/settings/Staff/StaffGender"));
const PermissionSelectionPage = lazy(() =>
  import("./pages/settings/Staff/PermissionSelectionPage")
);
const EditPrescription = lazy(() =>
  import("./pages/clinic/appointment/soap/EditPrescription")
);
const PurchaseHistoryFullList = lazy(() =>
  import("./pages/clinic/Inventory/PurchaseHistoryFullList")
);
const SalesHistoryFullList = lazy(() =>
  import("./pages/clinic/Inventory/SalesHistoryFullList")
);
const Flag = lazy(() => import("./pages/settings/Laboratory/Flag"));
const Methodology = lazy(() =>
  import("./pages/settings/Laboratory/Methodology")
);
const Location = lazy(() => import("./pages/settings/Laboratory/Location"));
const TestType = lazy(() => import("./pages/settings/Laboratory/TestType"));
const SampleType = lazy(() => import("./pages/settings/Laboratory/SampleType"));
const ClinicSidePatientMedicalRecord = lazy(() =>
  import("./pages/clinic/appointment/MedicalRecord")
);
const Imaging = lazy(() => import("./pages/clinic/Laboratory/Imaging"));
const InventoryDetails = lazy(() =>
  import("./pages/clinic/Inventory/InventoryDetails")
);
const AddImaging = lazy(() => import("./pages/clinic/Laboratory/AddImaging"));
const ImagingDetails = lazy(() =>
  import("./pages/clinic/Laboratory/ImagingDetails")
);
const EditNonInvetory = lazy(() =>
  import("./pages/clinic/Inventory/EditNonInventory")
);
const EditInventory = lazy(() =>
  import("./pages/clinic/Inventory/EditInventory")
);
const EditImaging = lazy(() => import("./pages/clinic/Laboratory/EditImaging"));
const ClinicUpdate = lazy(() =>
  import("./pages/clinic/registration/ClinicUpdate")
);
const NotificationDetails = lazy(() =>
  import("./pages/clinic/notification/NotificationDetails")
);
const PaymentDetails = lazy(() =>
  import("./pages/clinic/Acounting/PaymentDetails")
);
const PrintPrescription = lazy(() =>
  import("./pages/clinic/appointment/snippets/PrintPrescription")
);
const PrintSoapPrescription = lazy(() =>
  import("./pages/clinic/appointment/snippets/PrintSoapPrescription")
);
const StaffPayslipHistory = lazy(() =>
  import("./pages/clinic/Profile/StaffPayslipHistory")
);
const StaffPayslipPrintPage = lazy(() =>
  import("./pages/clinic/Profile/StaffPayslipPrintPage")
);
const PrintPayslipDetails = lazy(() =>
  import("./pages/clinic/Payroll/PrintPayslipDetails")
);
const PrintSoap = lazy(() =>
  import("./pages/clinic/appointment/snippets/PrintSoap")
);
const StaffPayslipHistoryDetails = lazy(() =>
  import("./pages/clinic/Profile/StaffPayslipHistoryDetails")
);
const GettingStartedPage = lazy(() =>
  import("./scomponents/main/GettingStartedPage")
);
const ConfigPracticeSettings = lazy(() =>
  import("./scomponents/main/ConfigPracticeSettings")
);
const UpcomingAppointmentTableFullList = lazy(() =>
  import("./component/Patient/UpcomingAppointmentTableFullList")
);
const LastPrescriptionFullList = lazy(() =>
  import("./component/Patient/LastPrescriptionFullList")
);
const PaymentSuccessPage = lazy(() =>
  import("./pages/clinic/Acounting/PaymentSuccessPage")
);
const Loading = lazy(() => import("./component/Loading/Loading"));
const Error404Page = lazy(() => import("./pages/Error404Page/Error404Page"));
const DiagnosisReportList = lazy(() =>
  import("./pages/clinic/Reports/DiagnosisReportList")
);
const DiagnosisReportDetailList = lazy(() =>
  import("./pages/clinic/Reports/DiagnosisReportDetailList")
);
const AddPatientInfo = lazy(() =>
  import("./pages/clinic/Clients/AddPatientInfo")
);

function ClinicApp() {
  const navigate = useNavigate();
  //this function needs to call the authenticated user and get his/ her data
  const {
    user,
    staff,
    clinic,
    setUser,
    setClinic,
    setStaff,
    setCurrency,
    setActiveBill,
  } = useContext(ClinicAuthContext);
  const { setStaffPerms, handlePermUpdateContectForUI, setStaffData } =
    useContext(StaffRolePermContext);

  const handleGetClinicCallback = (data) => {
    setClinic(data);
    localStorage.setItem("subscriptionID", data && data.clinic_plan);
  };
  const handleErrorCallback = () => {
    // navigate(CLINIC_LINKS.LOGIN);
  };

  const roleSuccessCallback = (data) => {
    handlePermUpdateContectForUI(data);
  };
  const staffSuccessCallback = (data) => {
    setStaff(data);
    setStaffData(data);

    if (data.clinic && data.clinic.slug !== getClinicSlugFromSubHost()) {
      clearUserTokens();
      navigate(CLINIC_LINKS.LOGIN);
    }
  };
  const getCurrencySuccess = (data) => {
    setCurrency(data.code);
  };
  const getClinicActiveBillSuccess = (data) => {
    setActiveBill(data);
    localStorage.setItem(
      "currentBillSubscriptionID",
      data && data.subscription
    );
  };
  React.useEffect(() => {
    getClinicAuthenticatedStaffDetail(
      staffSuccessCallback,
      handleErrorCallback
    );
    getClinicDetailSlug(
      getClinicSlugFromSubHost(),
      handleGetClinicCallback,
      handleErrorCallback
    );

    // if (staff.role) {
    getRoleSettingsDetailByName(
      staff.role,
      clinic.id,
      roleSuccessCallback,
      null
    );
    // }
    if (clinic.id) {
      getCurrency(clinic.id, getCurrencySuccess);
      getClinicBillActiveForToday(clinic.id, getClinicActiveBillSuccess);
    }
  }, [clinic.id]);

  return (
    <>
      <Routes>
        <Route
          path={CLINIC_LINKS.SUBSCRIPTION_DASHBOARD}
          element={
            <Suspense fallback={<ClinicPageLoader />}>
              <ClinicSubscribe />
            </Suspense>
          }
        />
        <Route
          path={CLINIC_LINKS.BIO_REGISTER}
          element={
            <Suspense fallback={<ClinicPageLoader />}>
              <ClinicRoute>
                <BioRegister />
              </ClinicRoute>
            </Suspense>
          }
        />
        <Route
          path={CLINIC_LINKS.FORGOT_PASSWORD}
          element={
            <Suspense fallback={<ClinicPageLoader />}>
              <ClinicRoute>
                <ForgotPassword />
              </ClinicRoute>
            </Suspense>
          }
        />
        <Route
          path={CLINIC_LINKS.SOAP_ESTIMATED_PAYMENT_PRINT_VIEW}
          element={
            <Suspense fallback={<ClinicPageLoader />}>
              <ClinicRoute>
                <SoapEstimatedPaymentPrintView />
              </ClinicRoute>
            </Suspense>
          }
        />

        <Route
          path={CLINIC_LINKS.PRINT_SOAP}
          element={
            <Suspense fallback={<ClinicPageLoader />}>
              <ClinicRoute>
                <PrintSoap />
              </ClinicRoute>
            </Suspense>
          }
        />
        <Route
          path={CLINIC_LINKS.PRINT_MEDICAL_PRESCRIPTION}
          element={
            <Suspense fallback={<ClinicPageLoader />}>
              <ClinicRoute>
                <PrintPrescription />
              </ClinicRoute>
            </Suspense>
          }
        />
        <Route
          path={CLINIC_LINKS.PRINT_SOAP_MEDICAL_PRESCRIPTION}
          element={
            <Suspense fallback={<ClinicPageLoader />}>
              <ClinicRoute>
                <PrintSoapPrescription />
              </ClinicRoute>
            </Suspense>
          }
        />
        <Route
          path={CLINIC_LINKS.PRINT_STAFF_PAYSLIP}
          element={
            <Suspense fallback={<ClinicPageLoader />}>
              <ClinicRoute>
                <StaffPayslipPrintPage />
              </ClinicRoute>
            </Suspense>
          }
        />
        <Route
          path={CLINIC_LINKS.PRINT_PAYSLIP_DETAILS}
          element={
            <Suspense fallback={<ClinicPageLoader />}>
              <ClinicRoute>
                <PrintPayslipDetails />
              </ClinicRoute>
            </Suspense>
          }
        />
        <Route
          path={CLINIC_LINKS.TEST_RESULT_DETAILS_PRINT}
          element={
            <Suspense fallback={<ClinicPageLoader />}>
              <ClinicRoute>
                <TestResultDetailPrint />
              </ClinicRoute>
            </Suspense>
          }
        />

        <Route
          path={CLINIC_LINKS.PAYMENT_DETAILS}
          element={
            <Suspense fallback={<ClinicPageLoader />}>
              <ClinicRoute>
                <PaymentDetails />
              </ClinicRoute>
            </Suspense>
          }
        />
        <Route
          path="/vapp/"
          element={
            <Suspense fallback={<ClinicPageLoader />}>
              <ClinicRoute>
                <DefaultLayout />
              </ClinicRoute>
            </Suspense>
          }
        >
          <Route
            path={CLINIC_LINKS.DASHBOARD}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <Dashboard />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.ARCHIVED_ACCOUNTS}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <ArchivedAccount />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.CLINIC_UPDATE}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <ClinicUpdate />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.APPOINTMENT_LIST}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <AppointmentList />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.BOARDING_LIST}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <BoardingList />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.APPOINTMENT_RESCHEDULE}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <AppointmentReschedule />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.APPOINTMENT_RESCHEDULE_BOARDING}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <AppointmentRescheduleBoarding />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.CREATE_PRESCRIPTION}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <CreatePrescription />
                </ClinicRoute>
              </Suspense>
            }
          />

          <Route
            path={CLINIC_LINKS.CREATE_PRESCRIPTION_ID}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <CreatePrescription />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.EDIT_PRESCRIPTION}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <EditPrescription />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.APPOINTMENT_DETAIL_OVERVIEW}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <AppointmentDetailContainer />
                </ClinicRoute>
              </Suspense>
            }
          />

          <Route
            path={CLINIC_LINKS.UPCOMING_APPOINTMENT_FULL_LIST}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <UpcomingAppointmentTableFullList />
                </ClinicRoute>
              </Suspense>
            }
          />

          <Route
            path={CLINIC_LINKS.LAST_PRESCRIPTION_FULL_LIST}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <LastPrescriptionFullList />
                </ClinicRoute>
              </Suspense>
            }
          />

          <Route
            path={CLINIC_LINKS.APPOINTMENT_DETAIL_MEDICAL}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <AppointmentDetailMedical />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.APPOINTMENT_CREATE}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <AppointmentCreate />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.SOAP}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <Soap />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.SOAP_ESTIMATED_PAYMENT}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <SoapEstimatedPayment />
                </ClinicRoute>
              </Suspense>
            }
          />

          <Route
            path={CLINIC_LINKS.SOAP_FORM_SUBJECTIVE}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <SoapFormSubjective />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.SOAP_FORM_OBJECTIVE}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <SoapFormObjective />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.SOAP_FORM_ASSESMENT}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <SoapFormAssesment />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.SOAP_FORM_PLAN}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <SoapFormPlan />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.SOAP_FORM_SUBJECTIVE_EDIT}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <SoapFormSubjectiveEdit />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.SOAP_FORM_OBJECTIVE_EDIT}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <SoapFormObjectiveEdit />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.SOAP_FORM_ASSESMENT_EDIT}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <SoapFormAssesmentEdit />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.SOAP_FORM_PLAN_EDIT}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <SoapPlanEdit />
                </ClinicRoute>
              </Suspense>
            }
          />

          <Route
            path={CLINIC_LINKS.STAFF_AVAILABILITY}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <StaffAvailability />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.AVAILABILITY_SET_TIME}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <StaffAvailabilityDetail />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.USERS_STAFF}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <StaffList />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.STAFF_OVERVIEW}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <StaffOverview />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.STAFF_WORKING_HOURS_EDIT}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <StaffWorkingHoursEdit />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.STAFF_WORKING_HOURS}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <StaffWorkingHours />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.ADD_NEW_STAFF}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <AddNewStaff />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.STAFF_PASSWORD}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <Password />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.STAFF_OVERVIEW_EDIT}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <StaffOverviewEdit />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.ACCOUNT_INFO_EDIT_MODE}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <AccountInfoEditMode />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.ACCOUNT_INFO_VIEW_MODE}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <AccountInfoViewMode />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.DEPARTMENT_INFO}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <DepartmentInfo />
                </ClinicRoute>
              </Suspense>
            }
          />

          <Route
            path={CLINIC_LINKS.DEPARTMENT_INFO_VIEW_MODE}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <DepartmentInfoViewMode />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.STAFF_PAYSLIP_HISTORY}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <StaffPayslipHistory />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.STAFF_PAYSLIP_HISTORY_DETAILS}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <StaffPayslipHistoryDetails />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.EDUCATION_EDIT_MODE}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <EducationEditMode />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.PROFILE_PASSWORD}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <ProfilePassword />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.STAFF_LEAVE}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <StaffLeave />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.EDUCATION_VIEW_MODE}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <EducationEditModeViewMode />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.ADD_CLIENT_PATIENT}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <AddPatient />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.PATIENTS_MEDICAL_RECORDS}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <ClinicSidePatientMedicalRecord />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.CLIENT_LIST}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <ClientList />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.PATIENT_VIEW}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <PatientView />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.PATIENT_DETAILS}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <PatientList />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.EDIT_PERSONAL_INFO}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <EditPersonalInfo />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.EDIT_PATIENT_INFO}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <EditPatientInfo />
                </ClinicRoute>
              </Suspense>
            }
          />

          <Route
            path={CLINIC_LINKS.ADD_PATIENT_INFO}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <AddPatientInfo />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.DOCUMENT_LIST}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <ClientDocumentList />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.CLIENT_PERSONAL_INFORMATION}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <AddClient />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.APPOINTMENT_DAILY_LIST}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <AppointmentDailyList />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.TASK}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <Task />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.ADD_NEW_TASK}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <AddNewTask />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.EDIT_NEW_TASK}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <EditTask />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.TASK_DETAILS}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <TaskDetails />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.PAYROLL}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <Payroll />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.ADD_PAYROLL}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <AddPayroll />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.ADD_PAYSLIP}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <UpdatePayroll />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.PAYROLL_EDIT}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <UpdatePayroll />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.SUBMIT_PAYROLL}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <SubmitPayroll />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.PAYSLIP_DETAIL}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <PayslipHistoryDetails />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.PAYROLL_DETAIL}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <PayrollDetails />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.ADD_NEW_INVENTORY}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <AddNewInvetory />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.EDIT_NON_INVENTORY}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <EditNonInvetory />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.EDIT_INVENTORY}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <EditInventory />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.NON_INVENTORY_DETAIL}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <NonInventoryDetail />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.INVENTORY_LIST}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <InvetoryList />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.PRODUCT_LIST}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <ProductList />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.EDIT_PRODUCT}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <EditProduct />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.ADD_PRODUCT}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <AddProduct />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.PRODUCT_DETAILS}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <ProductDetails />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.INVENTORY_DETAILS}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <InventoryDetails />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.PURCHASE_HISTORY}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <PurchaseHistoryFullList />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.SALES_HISTORY}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <SalesHistoryFullList />
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.INVENTORY}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <Inventory />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.ADD_INVENTORY}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <AddInventory />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.MEDICINE_DETAILS}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <MedicineDetails />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.MEDICINE_LIST}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <MedicineList />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.ADD_MEDICINE}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <AddNewMedicine />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.EDIT_MEDICINE}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <EditMedicine />
                </ClinicRoute>
              </Suspense>
            }
          />

          <Route
            path={CLINIC_LINKS.PROFIT_AND_LOSS_LIST}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <ProfitAndLossList />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.PROFIT_AND_LOSS_CREATE}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <ProfitAndLossCreate />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.PROFIT_AND_LOSS_DETAIL}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <ProfitAndLossDetail />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.PROFIT_AND_LOSS_EDIT}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <ProfitAndLossEdit />
                </ClinicRoute>
              </Suspense>
            }
          />

          <Route
            path={CLINIC_LINKS.PAYMENT}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <Payment />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.INVOICE}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <Invoice />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.INVOICE_PAYMENT_METHOD}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <ClinicPaymentMethod />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.STATEMENT_OF_ACCOUNT}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <StatementOfAccount />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.LAB_METHODOLOGY_SETTINGS}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <Methodology />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.LAB_LOCATION_SETTINGS}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <Location />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.LAB_SAMPLE_TYPE_SETTINGS}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <SampleType />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.LAB_TEST_TYPE_SETTINGS}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <TestType />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.LAB_FLAG_SETTINGS}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <Flag />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.LAB_TEST_LIST}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <Labtest />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.LAB_TEST_RESULT_LIST}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <LabTestResultList />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.IMAGING}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <Imaging />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.ADD_IMAGING}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <AddImaging />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.EDIT_IMAGING}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <EditImaging />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.ADD_TEST_RESULT}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <AddTestResult />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.ADD_INVOICE}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <AddInvoice />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.ADD_NON_CUSTOMER_INVOICE}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <AddNonCustomerInvoice />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.INVOICE_EDIT}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <EditInvoice />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.ADD_STATEMENT}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <AddStatement />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.STATEMENT_OF_ACCOUNT_DETAILS}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <StatementOfAccountDetails />
                </ClinicRoute>
              </Suspense>
            }
          />

          <Route
            path={CLINIC_LINKS.INVOICE_DETAILS}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <InvoiceDetails />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.ACCOUNTS}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <AccountsLayout />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.ACCOUNT_DETAIL}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <AccountDetail />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.EDIT_ACCOUNT}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <AccountEdit />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.ADD_ACCOUNT}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <AddAccount />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.PAYMENT_SUCCESSFUL}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <PaymentSuccessPage />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.ADD_EXPENSES_ACCOUNT_ITEMS}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <AddExpenses />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.TEST_DETAILS}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <TestDetails />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.IMAGING_DETAILS}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <ImagingDetails />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.TEST_RESULT_DETAILS}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <TestResultDetails />
                </ClinicRoute>
              </Suspense>
            }
          />

          <Route
            path={CLINIC_LINKS.CREATE_TEST}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <CreateTest />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.EDIT_LAB_TEST}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <EditTest />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.TEST_RESULT_EDIT}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <EditTestResult />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.ACCOUNT_RECEIVABLE}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <AccountReceivable />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.DOCTOR_REPORT}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <DoctorsReport />
                </ClinicRoute>
              </Suspense>
            }
          />

          <Route
            path={CLINIC_LINKS.TASK_REPORT}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <StaffTaskReport />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.TASK_REPORT_DETAIL}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <StaffTaskReportDetail />
                </ClinicRoute>
              </Suspense>
            }
          />

          <Route
            path={CLINIC_LINKS.DIAGNOSIS_REPORT}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <DiagnosisReportList />
                </ClinicRoute>
              </Suspense>
            }
          />

          <Route
            path={CLINIC_LINKS.DIAGNOSIS_REPORT_DETAIL}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <DiagnosisReportDetailList />
                </ClinicRoute>
              </Suspense>
            }
          />

          <Route
            path={CLINIC_LINKS.GETTING_STARTED_PAGE}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <GettingStartedPage />
                </ClinicRoute>
              </Suspense>
            }
          />

          <Route
            path={CLINIC_LINKS.CONFIGURE_PRACTICE_SETTINGS}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <ConfigPracticeSettings />
                </ClinicRoute>
              </Suspense>
            }
          />

          <Route
            path={CLINIC_LINKS.ACCOUNT_RECEIVABLE_DETAIL}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <AccountReceivableDetail />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.CONTROLLED_SUBSTANCE}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <ControlledSubstance />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.CONTROLLED_SUBSTANCE_DETAILS}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <ControlledSubstanceDetails />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.INVENTORY_BILLABLE_ITEMS}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <InventoryBillableItems />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.SALES_OF_DAYS}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <SalesOfDays />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.LEAVE_SETTINGS}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <LeaveSettings />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.TITLE_SETTINGS}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <Title />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.STAFF_GENDER_SETTINGS}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <StaffGender />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.PRODUCT_SUPPLIER_SETTINGS}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <ProductSupplier />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.INITIAL_COMPLAINTS}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <InitialComplaint />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.VITAL_FINDINGS}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <VitalFindings />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.INVENTORY_CATEGORY_SETTINGS}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <CategorySettings />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.GOOGLE_SETTINGS}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <GoogleSettings />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.BOARDING_SIZE_DETAIL}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <CageSizeSettingsDetails />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.BOARDING_SIZE_EDIT}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <CageSizeEdit />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.CURRENCY}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <Currency />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.PAYMENT_GATEWAY}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <PaymentGatewayList />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.PAYMENT_GATEWAY_UPDATE}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <PaymentGatewayEdit />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.PAYMENT_GATEWAY_ADD}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <PaymentGateway />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.DEPARTMENT_SETTINGS}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <Department />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.PERMISSIONS_SETTINGS}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <Permission />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.ROLE_STAFF_LIST_SETTINGS}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <RoleStaffMembers />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.ROLE_SETTINGS_DETAIL_PERM}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <PermissionSelectionPage />
                </ClinicRoute>
              </Suspense>
            }
          />
          {/* <Route path={CLINIC_LINKS.ROLE_SETTINGS} element={<Suspense fallback={<ClinicPageLoader />}>
        <ClinicRoute><Roles /></ClinicRoute></Suspense>} /> */}
          <Route
            path={CLINIC_LINKS.SPECIES_SETTINGS}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <SpeciesSettings />
                </ClinicRoute>
              </Suspense>
            }
          />

          <Route
            path={CLINIC_LINKS.CAGESIZE_SETTINGS}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <CageSizeSettings />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.CAGETYPE_SETTINGS}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <CageTypeSettings />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.ADMISSION_TYPE_SETTINGS}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <AdmissionType />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.ASSESSMENT_DIAGNOSIS}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <Diagnosis />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.PAYMENT_SETTINGS}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <PaymentSettings />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.DEFAULT_TAX_SETTINGS}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <DefaultTaxSettings />
                </ClinicRoute>
              </Suspense>
            }
          />

          <Route
            path={CLINIC_LINKS.INVOICE_SETTINGS}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <InvoiceSettings />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.BREED_SETTINGS}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <BreedSettings />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.COLOR_SETTINGS}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <ColorSettings />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.SEX_SETTINGS}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <GenderSettings />
                </ClinicRoute>
              </Suspense>
            }
          />
          {/* <Route
          path={CLINIC_LINKS.DOC_NAME_SETTINGS}
          element={<Suspense fallback={<ClinicPageLoader />}>
          <ClinicRoute><DoctorsNameSettings /></ClinicRoute></Suspense>}
        /> */}
          <Route
            path={CLINIC_LINKS.PRACTICE_TRENDS}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <PracticeTrends />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.NOTIFICATION_LIST}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <NotificationList />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.NOTIFICATION_DETAILS}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <NotificationDetails />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.NOTIFICATION_CREATE_BROADCAST}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <BroadcastForm />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.NOTIFICATION_BROADCAST_LIST}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <BroadcastList />
                </ClinicRoute>
              </Suspense>
            }
          />
          <Route
            path={CLINIC_LINKS.NOTIFICATION_ACTIVITY_LOGS}
            element={
              <Suspense fallback={<ClinicPageLoader />}>
                <ClinicRoute>
                  <ActivityLogList />
                </ClinicRoute>
              </Suspense>
            }
          />
        </Route>

        <Route
          path={CLINIC_LINKS.LOADING}
          element={
            <Suspense fallback={<ClinicPageLoader />}>
              <ClinicRoute>
                <Loading />
              </ClinicRoute>
            </Suspense>
          }
        />

        {/* <Route
          path="*"
          element={
            <Suspense fallback={<ClinicPageLoader />}>
              <ClinicRoute>
                <Error404Page />
              </ClinicRoute>
            </Suspense>
          }
        /> */}
      </Routes>
    </>
  );
}
export default ClinicApp;
