import { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { GENERAL_LINKS } from "./links/generalLinks";
import PageLoader from "./PageLoader";
import ClinicLanding from "./pages/landing/ClinicLanding";
import ClinicStaffLanding from "./pages/landing/ClinicStaffLanding";
import Error404Page from "./pages/Error404Page/Error404Page";

// const Error404Page = lazy(() => import("./pages/Error404Page/Error404Page"));
// const ClinicLanding = lazy(() => import("./pages/landing/ClinicLanding"));
// const ClinicStaffLanding = lazy(() =>
//   import("./pages/landing/ClinicStaffLanding")
// );

const GeneralApp = () => {
  return (
    <>
      <Routes>
        <Route path={GENERAL_LINKS.NOT_FOUND_404} element={<Error404Page />} />
        <Route
          path={GENERAL_LINKS.CLINIC_LANDING}
          element={<ClinicLanding />}
        />
        <Route
          path={GENERAL_LINKS.CLINIC_STAFF_LANDING}
          element={<ClinicStaffLanding />}
        />
      </Routes>
    </>
  );
};
export default GeneralApp;
