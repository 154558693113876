import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";

import { LockOpen, PasswordRounded } from "@mui/icons-material";
import ClinicAuthContext from "../../../context/clinicAuthContext";
import { CLINIC_LINKS } from "../../../links/clinicLinks";
import { CLIENT_LINKS } from "../../../links/clientLinks";
import { CLINIC_SUPER_LINKS } from "../../../links/clinicSuperLinks";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  p: 4,
  boxShadow: "0px 24px 32px rgba(171, 171, 171, 0.16)",
  borderRadius: "16px",
};

export default function LandingPopModal({
  open,
  onClose,
  item_type,
  deleteFunction,
}) {
  //   const [open, setOpen] = React.useState(false);
  //   const handleOpen = () => setOpen(true);
  //   const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const { clinic } = React.useContext(ClinicAuthContext);
  // navigations
  const goToClinicLogin = () => window.location.replace(CLINIC_LINKS.DASHBOARD);
  const goToClinicSuperLogin = () => navigate(CLINIC_SUPER_LINKS.LOGIN);
  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            onClick={onClose}
            sx={{
              position: "absolute",
              top: "24px",
              right: "24px",
              cursor: "pointer",
            }}
          >
            <CloseIcon />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "32px",
            }}
          >
            <LockOpen />
          </Box>

          <Box sx={{ marginBottom: "66px" }}>
            <Typography
              sx={{
                fontWeight: "500",
                fontSize: "24px",
                color: "#000000",
                textAlign: "center",
                paddingBottom: "6px",
              }}
            >
              Sign In
            </Typography>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "20px",
                color: "#64697A",
                textAlign: "center",
              }}
            >
              Sign in to {clinic.name} as:
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "24px",
            }}
          >
            {/* <Button
              sx={{
                paddingY: "25px",
                background: "#F3F3F4",
                paddingX: "100px",
                borderRadius: "16px",
                mb: 2,
              }}
              onClick={goToClientLogin}
            >
              Client
            </Button> */}
            <Button
              onClick={goToClinicLogin}
              sx={{
                paddingY: "25px",
                paddingX: "100px",
                background: "#F3F3F4",
                borderRadius: "16px",
                mb: 2,
              }}
            >
              Staff
            </Button>
            <Button
              onClick={goToClinicSuperLogin}
              sx={{
                paddingY: "25px",
                paddingX: "100px",
                background: "#F3F3F4",
                borderRadius: "16px",
                mb: 2,
              }}
            >
              Administrator
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
