import React from "react";

import { Grid, Box, Typography } from "@mui/material";

// import LoginPageImage from "../../assets/clientbannerimage.png";

import ClientPageImage from "../../assets/Clinic/Registration/clientbannerimage2.png";

import SmartdvmLogo from "../../assets/logo-white.png";

const NewClientLoginContainer = ({ children }) => {
  return (
    <Grid container xs={12} sx={{ height: "100vh" }}>
      <Grid
        items
        xs={0}
        sm={6}
        sx={{
          backgroundColor: "#009EED",
          display: { xs: "none", sm: "block" },
        }}
      >
        <Box>
          <img
            src={SmartdvmLogo}
            alt="smartdvm-logo"
            style={{
              marginLeft: "3rem",
              marginTop: "3rem",
              maxHeight: "12em",
              maxWidth: "14em",
              cursor: "pointer",
              paddingBottom: "1.5rem",
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: 700,
              marginBottom: "1.5rem",
              color: "#fff",
            }}
          >
            Excellent <span style={{ color: "#2850AF" }}>pets</span> deserve
            <br></br>
            <span style={{ paddingLeft: "4rem" }}> excellent care</span>
          </Typography>
          <Box
            sx={{
              overflow: "hidden",
              position: "relative",
              marginTop: "1rem",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              src={ClientPageImage}
              alt="smartdvm-logo"
              style={{ height: "60vh" }}
            />
          </Box>
        </Box>
      </Grid>

      <Grid
        items
        xs={12}
        sm={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {children}
      </Grid>
    </Grid>
  );
};

export default NewClientLoginContainer;
