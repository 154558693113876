import { Button, TextField, Typography, InputAdornment } from "@mui/material";
import React from "react";
import LoginContainer from "../../../scomponents/utils/LoginContainer";
import LoginImage from "../../../assets/login.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EmailIcon from "@mui/icons-material/Email";
import ClientLoginBackground from "../../../assets/clientLoginBackground.png";

const ForgotPassword = () => {
  return (
    <LoginContainer>
      <>
        <Typography
          variant="h6"
          sx={{
            fontSize: "24px",
            display: "flex",
            alignItems: "center",
            color: "#203139",
          }}
        >
          <ArrowBackIcon sx={{ paddingRight: "10px" }} />{" "}
          <span style={{ color: "#CDD1D3", paddingRight: "12px" }}>|</span>{" "}
          Forgot Password
        </Typography>
        <TextField
          margin="normal"
          required
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          sx={{ width: { xs: "40ch", sm: "40ch" } }}
          autoFocus
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailIcon />
              </InputAdornment>
            ),
            style: { borderRadius: "16px" },
          }}
        />
        <Button
          variant="contained"
          sx={{
            marginTop: "50px",
            width: "40ch",
            borderRadius: "16px",
            paddingY: "14px",
          }}
        >
          Get Reset Link
        </Button>
      </>
    </LoginContainer>
  );
};

export default ForgotPassword;
