import React, { lazy, Suspense } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { APP_CENTER_LINKS } from "./links/appCenterLinks";
import { getClinicSlugFromSubHost } from "./api/getClinicSlugFromSubHost";
import { GENERAL_LINKS } from "./links/generalLinks";
import AppCenterPageLoader from "./AppCenterPageLoader";
import PageLoader from "./PageLoader";
import AppCenterDefaultLayout from "./component/DefaultLayout/AppCenterDefaultLayout";
import ResetPassword from "./pages/smartdvm_app_center/Login/ResetPassword";

const PractiveRegLogProgress = lazy(() =>
  import(
    "./pages/smartdvm_app_center/Dashboard/PracticeRegRequest/PractiveRegLogProgress"
  )
);
const PracticeRegHistory = lazy(() =>
  import(
    "./pages/smartdvm_app_center/Dashboard/PracticeRegRequest/PracticeRegRequestHistory"
  )
);
const PracticeRegRequestDetails = lazy(() =>
  import(
    "./pages/smartdvm_app_center/Dashboard/PracticeRegRequest/PracticeRegRequestDetails"
  )
);
const AppLabelList = lazy(() =>
  import("./pages/smartdvm_app_center/Dashboard/AppLabel/AppLabelList")
);
const AppLabelCreate = lazy(() =>
  import("./pages/smartdvm_app_center/Dashboard/AppLabel/AppLabelCreate")
);
const AppLabelDetail = lazy(() =>
  import("./pages/smartdvm_app_center/Dashboard/AppLabel/AppLabelDetail")
);
const AppLabelEdit = lazy(() =>
  import("./pages/smartdvm_app_center/Dashboard/AppLabel/AppLabelEdit")
);
const SubscriptionList = lazy(() =>
  import("./pages/smartdvm_app_center/Dashboard/Subscription/SubscriptionList")
);
const SubscriptionCreate = lazy(() =>
  import(
    "./pages/smartdvm_app_center/Dashboard/Subscription/SubscriptionCreate"
  )
);
const SubscriptionDetail = lazy(() =>
  import(
    "./pages/smartdvm_app_center/Dashboard/Subscription/SubscriptionDetail"
  )
);
const SubscriptionEdit = lazy(() =>
  import("./pages/smartdvm_app_center/Dashboard/Subscription/SubscriptionEdit")
);
const PracticeList = lazy(() =>
  import("./pages/smartdvm_app_center/Dashboard/Practices/PracticeList")
);
const PracticeDetail = lazy(() =>
  import("./pages/smartdvm_app_center/Dashboard/Practices/PracticeDetail")
);
const PracticeEdit = lazy(() =>
  import("./pages/smartdvm_app_center/Dashboard/Practices/PracticeEdit")
);
const Login = lazy(() => import("./pages/smartdvm_app_center/Login/Login"));
const Dashboard = lazy(() =>
  import("./pages/smartdvm_app_center/Dashboard/Dashboard")
);
const PracticeDetailContainer = lazy(() =>
  import(
    "./pages/smartdvm_app_center/Dashboard/Practices/PracticeDetailContainer"
  )
);
const PracticeBillTable = lazy(() =>
  import("./pages/smartdvm_app_center/center_components/Bills/BillsTable")
);
const BillList = lazy(() =>
  import("./pages/smartdvm_app_center/Dashboard/Bill/BillList")
);
const BillDetail = lazy(() =>
  import("./pages/smartdvm_app_center/Dashboard/Bill/BillDetail")
);
const AddPracticeBill = lazy(() =>
  import("./pages/smartdvm_app_center/Dashboard/Bill/AddPracticeBill")
);
const TransactionList = lazy(() =>
  import("./pages/smartdvm_app_center/Dashboard/Bill/Transactions")
);
const PaymentGatewayList = lazy(() =>
  import(
    "./pages/smartdvm_app_center/Dashboard/PaymentMethod/PaymentGatewayList"
  )
);
const PaymentGatewayEdit = lazy(() =>
  import(
    "./pages/smartdvm_app_center/Dashboard/PaymentMethod/PaymentGatewayEdit"
  )
);
const PaymentGateway = lazy(() =>
  import("./pages/smartdvm_app_center/Dashboard/PaymentMethod/PaymentGateway")
);
const ClinicPaymentMethod = lazy(() =>
  import(
    "./pages/smartdvm_app_center/center_components/BillPayment/ClinicPayment"
  )
);
const PayBill = lazy(() =>
  import("./pages/smartdvm_app_center/center_components/Bills/PayBill")
);
const Password = lazy(() =>
  import("./pages/smartdvm_app_center/ProfileSettings/Password")
);

const PersonalInformation = lazy(() =>
  import("./pages/smartdvm_app_center/ProfileSettings/PersonalInformation")
);
const Profile = lazy(() => import("./pages/smartdvm_app_center/Login/Profile"));
const PayBillSuccess = lazy(() =>
  import("./pages/smartdvm_app_center/center_components/Bills/PayBillSuccess")
);

const ProfileEdit = lazy(() =>
  import("./pages/smartdvm_app_center/Login/ProfileEdit")
);
const BillPrint = lazy(() =>
  import("./pages/smartdvm_app_center/center_components/Bills/BillPrint")
);
const PracticeRegRequestEdit = lazy(() =>
  import(
    "./pages/smartdvm_app_center/Dashboard/PracticeRegRequest/PracticeRegRequestEdit"
  )
);
const EmailList = lazy(() =>
  import("./pages/smartdvm_app_center/Dashboard/Emailing/EmailList")
);
const EmailCreate = lazy(() =>
  import("./pages/smartdvm_app_center/Dashboard/Emailing/EmailCreate")
);
const EmailDetail = lazy(() =>
  import("./pages/smartdvm_app_center/Dashboard/Emailing/EmailDetail")
);

const EmailEdit = lazy(() =>
  import("./pages/smartdvm_app_center/Dashboard/Emailing/EmailEdit")
);

const EventList = lazy(() =>
  import("./pages/smartdvm_app_center/Dashboard/Events/EventList")
);
const EventCreate = lazy(() =>
  import("./pages/smartdvm_app_center/Dashboard/Events/EventCreate")
);
const EventDetail = lazy(() =>
  import("./pages/smartdvm_app_center/Dashboard/Events/EventDetail")
);

const EventEdit = lazy(() =>
  import("./pages/smartdvm_app_center/Dashboard/Events/EventEdit")
);

const SmartDVMAppCenter = () => {
  const navigate = useNavigate();
  const slug = getClinicSlugFromSubHost();
  if (
    !window.location.host.includes("localhost") &&
    window.location.pathname.includes("app_center") &&
    slug !== "app_center"
  ) {
    navigate(GENERAL_LINKS.NOT_FOUND_404);
  } else if (
    !window.location.pathname.includes("app_center") &&
    !window.location.host.includes("localhost") &&
    slug === "app_center"
  ) {
    navigate(APP_CENTER_LINKS.LOGIN);
  } else if (
    !localStorage.getItem("app_center_staff_id") &&
    window.location.pathname !== APP_CENTER_LINKS.LOGIN
  ) {
    navigate(APP_CENTER_LINKS.LOGIN);
  }
  return (
    <>
      <Routes>
        <Route
          path={APP_CENTER_LINKS.RESET_PASSWORD}
          element={<ResetPassword />}
        />
        <Route
          path={APP_CENTER_LINKS.LOGIN}
          element={
            <Suspense fallback={<PageLoader />}>
              <Login />
            </Suspense>
          }
        />

        <Route
          path={APP_CENTER_LINKS.PRINT_BILLING_DETAIL}
          element={
            <Suspense fallback={<AppCenterPageLoader />}>
              <BillPrint />
            </Suspense>
          }
        />
        <Route
          path="/"
          element={
            <Suspense fallback={<AppCenterPageLoader />}>
              <AppCenterDefaultLayout />
            </Suspense>
          }
        >
          <Route
            path={APP_CENTER_LINKS.DASHBOARD}
            element={
              <Suspense fallback={<AppCenterPageLoader />}>
                <Dashboard />
              </Suspense>
            }
          />
          <Route
            path={APP_CENTER_LINKS.CLINIC_CREATION_REQUEST_LIST}
            element={
              <Suspense fallback={<AppCenterPageLoader />}>
                <PracticeRegHistory />
              </Suspense>
            }
          />
          <Route
            path={APP_CENTER_LINKS.CLINIC_CREATION_REQUEST_DETAILS}
            element={
              <Suspense fallback={<AppCenterPageLoader />}>
                <PracticeRegRequestDetails />
              </Suspense>
            }
          />
          <Route
            path={APP_CENTER_LINKS.CLINIC_CREATION_REQUEST_DETAILS_LOG}
            element={
              <Suspense fallback={<AppCenterPageLoader />}>
                <PractiveRegLogProgress />
              </Suspense>
            }
          />

          <Route
            path={APP_CENTER_LINKS.USER_PROFILE}
            element={
              <Suspense fallback={<AppCenterPageLoader />}>
                <Profile />
              </Suspense>
            }
          />
          <Route
            path={APP_CENTER_LINKS.USER_PROFILE_EDIT}
            element={
              <Suspense fallback={<AppCenterPageLoader />}>
                <ProfileEdit />
              </Suspense>
            }
          />
          <Route
            path={APP_CENTER_LINKS.USER_PROFILE_PASSWORD}
            element={
              <Suspense fallback={<AppCenterPageLoader />}>
                <Password />
              </Suspense>
            }
          />
          <Route
            path={APP_CENTER_LINKS.APP_LABEL}
            element={
              <Suspense fallback={<AppCenterPageLoader />}>
                <AppLabelList />
              </Suspense>
            }
          />
          <Route
            path={APP_CENTER_LINKS.APP_LABEL_CREATE}
            element={
              <Suspense fallback={<AppCenterPageLoader />}>
                <AppLabelCreate />
              </Suspense>
            }
          />
          <Route
            path={APP_CENTER_LINKS.APP_LABEL_DETAILS}
            element={
              <Suspense fallback={<AppCenterPageLoader />}>
                <AppLabelDetail />
              </Suspense>
            }
          />
          <Route
            path={APP_CENTER_LINKS.APP_LABEL_EDIT}
            element={
              <Suspense fallback={<AppCenterPageLoader />}>
                <AppLabelEdit />
              </Suspense>
            }
          />
          {/* SUBSCRIPTION */}
          <Route
            path={APP_CENTER_LINKS.SUBSCRIPTION}
            element={
              <Suspense fallback={<AppCenterPageLoader />}>
                <SubscriptionList />
              </Suspense>
            }
          />
          <Route
            path={APP_CENTER_LINKS.SUBSCRIPTION_DETAILS}
            element={
              <Suspense fallback={<AppCenterPageLoader />}>
                <SubscriptionDetail />
              </Suspense>
            }
          />
          <Route
            path={APP_CENTER_LINKS.SUBSCRIPTION_EDIT}
            element={
              <Suspense fallback={<AppCenterPageLoader />}>
                <SubscriptionEdit />
              </Suspense>
            }
          />
          <Route
            path={APP_CENTER_LINKS.SUBSCRIPTION_CREATE}
            element={
              <Suspense fallback={<AppCenterPageLoader />}>
                <SubscriptionCreate />
              </Suspense>
            }
          />
          {/* PRACTICES */}
          <Route
            path={APP_CENTER_LINKS.CLINIC}
            element={
              <Suspense fallback={<AppCenterPageLoader />}>
                <PracticeList />
              </Suspense>
            }
          />
          <Route
            // path={APP_CENTER_LINKS.CLINIC_DETAILS}
            element={
              <Suspense fallback={<AppCenterPageLoader />}>
                <PracticeDetailContainer />
              </Suspense>
            }
          >
            <Route
              path={APP_CENTER_LINKS.CLINIC_DETAILS}
              element={
                <Suspense fallback={<AppCenterPageLoader />}>
                  <PracticeDetail />
                </Suspense>
              }
            />
            <Route
              path={APP_CENTER_LINKS.CLINIC_BILLINGS}
              element={
                <Suspense fallback={<AppCenterPageLoader />}>
                  <BillList />
                </Suspense>
              }
            />
            <Route
              path={APP_CENTER_LINKS.CLINIC_TRANSACTIONS}
              element={
                <Suspense fallback={<AppCenterPageLoader />}>
                  <TransactionList />
                </Suspense>
              }
            />
            <Route
              path={APP_CENTER_LINKS.CLINIC_BILLING_DETAIL}
              element={
                <Suspense fallback={<AppCenterPageLoader />}>
                  <BillDetail />
                </Suspense>
              }
            />
          </Route>
          <Route
            path={APP_CENTER_LINKS.PAY_CLINIC_BILL}
            element={
              <Suspense fallback={<AppCenterPageLoader />}>
                <PayBill />
              </Suspense>
            }
          />

          <Route
            path={APP_CENTER_LINKS.PAY_CLINIC_BILL_SUCCESS}
            element={
              <Suspense fallback={<AppCenterPageLoader />}>
                <PayBillSuccess />
              </Suspense>
            }
          />
          <Route
            path={APP_CENTER_LINKS.ADD_CLINIC_BILL}
            element={
              <Suspense fallback={<AppCenterPageLoader />}>
                <AddPracticeBill />
              </Suspense>
            }
          />
          <Route
            path={APP_CENTER_LINKS.APP_CENTER_PAYMENT_GATEWAYS}
            element={
              <Suspense fallback={<AppCenterPageLoader />}>
                <PaymentGatewayList />
              </Suspense>
            }
          />
          <Route
            path={APP_CENTER_LINKS.APP_CENTER_PAYMENT_GATEWAY_EDIT}
            element={
              <Suspense fallback={<AppCenterPageLoader />}>
                <PaymentGatewayEdit />
              </Suspense>
            }
          />
          <Route
            path={APP_CENTER_LINKS.APP_CENTER_PAYMENT_GATEWAY_CREATE}
            element={
              <Suspense fallback={<AppCenterPageLoader />}>
                <PaymentGateway />
              </Suspense>
            }
          />
          <Route
            path={APP_CENTER_LINKS.CLINIC_EDIT}
            element={
              <Suspense fallback={<AppCenterPageLoader />}>
                <PracticeEdit />
              </Suspense>
            }
          />

          <Route
            path={APP_CENTER_LINKS.CLINIC_CREATION_REQUEST_EDIT}
            element={
              <Suspense fallback={<AppCenterPageLoader />}>
                <PracticeRegRequestEdit />
              </Suspense>
            }
          />

          <Route
            path={APP_CENTER_LINKS.APP_EMAIL}
            element={
              <Suspense fallback={<AppCenterPageLoader />}>
                <EmailList />
              </Suspense>
            }
          />

          <Route
            path={APP_CENTER_LINKS.APP_EMAIL_CREATE}
            element={
              <Suspense fallback={<AppCenterPageLoader />}>
                <EmailCreate />
              </Suspense>
            }
          />

          <Route
            path={APP_CENTER_LINKS.APP_EMAIL_DETAILS}
            element={
              <Suspense fallback={<AppCenterPageLoader />}>
                <EmailDetail />
              </Suspense>
            }
          />
          <Route
            path={APP_CENTER_LINKS.APP_EMAIL_EDIT}
            element={
              <Suspense fallback={<AppCenterPageLoader />}>
                <EmailEdit />
              </Suspense>
            }
          />

          <Route
            path={APP_CENTER_LINKS.APP_EVENT}
            element={
              <Suspense fallback={<AppCenterPageLoader />}>
                <EventList />
              </Suspense>
            }
          />

          <Route
            path={APP_CENTER_LINKS.APP_EVENT_CREATE}
            element={
              <Suspense fallback={<AppCenterPageLoader />}>
                <EventCreate />
              </Suspense>
            }
          />

          <Route
            path={APP_CENTER_LINKS.APP_EVENT_DETAILS}
            element={
              <Suspense fallback={<AppCenterPageLoader />}>
                <EventDetail />
              </Suspense>
            }
          />
          <Route
            path={APP_CENTER_LINKS.APP_EVENT_EDIT}
            element={
              <Suspense fallback={<AppCenterPageLoader />}>
                <EventEdit />
              </Suspense>
            }
          />
        </Route>
      </Routes>
    </>
  );
};

export default SmartDVMAppCenter;
