import { toast } from "react-toastify";
import { PAGE_LIMIT } from "../API";
import axiosInstance from "../axios/post";

export const getPracticeBillList = (
  clinic_id,
  successCallback,
  errorCallback,
  page,
  tablePage
) => {
  let pageLink = `/api/billing/list/${clinic_id}/`;
  if (tablePage && page) {
    pageLink = `${pageLink}?limit=${PAGE_LIMIT}&offset=${page}`;
  } else if (tablePage) {
    pageLink = `${pageLink}?limit=${PAGE_LIMIT}`;
  }
  try {
    axiosInstance
      .get(pageLink)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get bills");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};
export const getPracticeTransactionList = (
  clinic_id,
  successCallback,
  errorCallback,
  page,
  tablePage
) => {
  let pageLink = `/api/billing/transaction/list/${clinic_id}/`;
  if (tablePage && page) {
    pageLink = `${pageLink}?limit=${PAGE_LIMIT}&offset=${page}`;
  } else if (tablePage) {
    pageLink = `${pageLink}?limit=${PAGE_LIMIT}`;
  }
  try {
    axiosInstance
      .get(pageLink)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get bills");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};
export const getPracticeBillDetail = (
  id,
  clinic_id,
  successCallback,
  errorCallback
) => {
  let pageLink = `/api/billing/detail/${clinic_id}/${id}/`;

  try {
    axiosInstance
      .get(pageLink)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get bills");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const updatePracticeBillDetail = (
  id,
  clinic_id,
  data,
  successCallback,
  errorCallback
) => {
  let pageLink = `/api/billing/detail/${clinic_id}/${id}/`;

  try {
    axiosInstance
      .patch(pageLink, data)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get bills");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const practiceBillDelete = (
  id,
  clinic_id,
  successCallback,
  errorCallback
) => {
  let pageLink = `/api/billing/detail/${clinic_id}/${id}/`;

  try {
    axiosInstance
      .delete(pageLink)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get bills");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const addBilling = (clinic_id, data, successCallback, errorCallback) => {
  try {
    axiosInstance
      .post(`/api/billing/list/${clinic_id}/`, data)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get invoices");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const addBillingItem = (
  clinic_id,
  data,
  successCallback,
  errorCallback
) => {
  try {
    axiosInstance
      .post(`/api/billing/bill-item/list/${clinic_id}/`, data)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get invoices");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getClinicBillActiveForToday = async (
  clinic_id,
  successCallback,
  errorCallback
) => {
  try {
    await axiosInstance
      .get(`/api/billing/get-clinic-bill-for-today/${clinic_id}/`)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get active bill");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getClinicBillActiveOrLast = async (clinic_id, successCallback) => {
  try {
    await axiosInstance
      .get(`/api/billing/get-clinic-current-or-past-bill/${clinic_id}/`)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        console.log(err.response);
        toast.error("could not get active or past bill");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const apiRenewPracticeSubscription = async (
  clinic_id,
  data,
  successCallback,
  errorCallback
) => {
  try {
    await axiosInstance
      .post(`/api/billing/renew-subscription/${clinic_id}/`, data)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not renew subscription");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};
export const apiUpgradeSubscription = async (
  clinic_id,
  sub_id,
  successCallback
) => {
  const data = {
    sub_id: sub_id,
  };
  try {
    await axiosInstance
      .post(`/api/billing/upgrade-subscription/${clinic_id}/`, data)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        console.log(err.response);
        toast.error("could not upgrade subscription");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};
