import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { getClinicDetailSlug } from "./api/API";
import { CLIENT_LINKS } from "./links/clientLinks";
import { getClinicSlugFromSubHost } from "./api/getClinicSlugFromSubHost";
import { getFullUserInfo } from "./api/getUserMain";
import { useContext, useEffect, lazy, Suspense } from "react";
import ClinicAuthContext from "./context/clinicAuthContext";
import Login from "./pages/client/registration/NewClientLogin";
import PageLoader from "./PageLoader";
import ClientPageLoader from "./ClientPageLoader";
import { ClientRoute } from "./scomponents/profileRoutes/ClientRoute";
import DefaultLayout from "./component/DefaultLayout/DefaultLayout";
// components

const ClientMedicalInformaion = lazy(() =>
  import("./pages/client/registration/ClientMedicalInformaion")
);
const ClientPetReg = lazy(() =>
  import("./pages/client/registration/ClientPetReg")
);
const ClientReg = lazy(() => import("./pages/client/registration/ClientReg"));
const ForgotPassword = lazy(() =>
  import("./pages/client/registration/ForgotPassword")
);

const ResetPassword = lazy(() =>
  import("./pages/client/registration/ResetPassword")
);
const Register = lazy(() => import("./pages/client/registration/Register"));
const GetCode = lazy(() => import("./pages/client/registration/GetCode"));
const BasicRegForm = lazy(() =>
  import("./pages/client/registration/BasicRegForm")
);
const Dashboard = lazy(() => import("./pages/client/Dashboard/Dashboard"));
const Appointment = lazy(() =>
  import("./pages/client/Dashboard/Appointment/Appointment")
);
const AppointmentDetails = lazy(() =>
  import("./pages/client/Dashboard/Appointment/AppointmentDetails")
);
const AppointmentReschedule = lazy(() =>
  import("./pages/client/Dashboard/Appointment/AppointmentReschedule")
);
const AppointmentRescheduleBoarding = lazy(() =>
  import("./pages/client/Dashboard/Appointment/AppointmentRescheduleBoarding")
);
const EmptyMedicalRecord = lazy(() =>
  import("./pages/client/Dashboard/Appointment/EmptyMedicalRecord")
);
const AppointmentMedicalRecord = lazy(() =>
  import("./pages/client/Dashboard/Appointment/MedicalRecord")
);
const MedicalPrescription = lazy(() =>
  import("./pages/client/Dashboard/Appointment/MedicalPrescription")
);
const TestDetails = lazy(() =>
  import("./pages/client/Dashboard/Appointment/TestDetails")
);
const CreateAppointment = lazy(() =>
  import("./pages/client/Dashboard/Appointment/CreateAppointment")
);
const PaymentMethod = lazy(() =>
  import("./pages/client/Dashboard/Appointment/PaymentMethod")
);

const AddNewPatient = lazy(() =>
  import("./pages/client/Dashboard/Patient/AddNewPatient")
);
const EditPatient = lazy(() =>
  import("./pages/client/Dashboard/Patient/EditPatient")
);
const Patient = lazy(() => import("./pages/client/Dashboard/Patient/Patient"));
const Patients = lazy(() =>
  import("./pages/client/Dashboard/Patient/Patients")
);
const CustomerInvoiceList = lazy(() =>
  import("./pages/client/Dashboard/PaymentHistory/CustomerInvoiceList")
);
const PaymentHistory = lazy(() =>
  import("./pages/client/Dashboard/PaymentHistory/PaymentHistory")
);
const PaymentDetails = lazy(() =>
  import("./pages/client/Dashboard/PaymentHistory/PaymentDetails")
);

const MedicalRecord = lazy(() =>
  import("./pages/client/Dashboard/MedicalRecord/MedicalRecord")
);

const PersonalInformation = lazy(() =>
  import("./pages/client/ProfileSettings/PersonalInformation")
);
const PatientInformation = lazy(() =>
  import("./pages/client/ProfileSettings/PatientInformation")
);

const PatientMedicalRecord = lazy(() =>
  import("./pages/client/ProfileSettings/PatientMedicalRecord")
);
const Password = lazy(() => import("./pages/client/ProfileSettings/Password"));

const ClientMedicalRecord = lazy(() =>
  import("./pages/client/Dashboard/MedicalRecord/ClientMedicalRecord")
);
const PaymentSuccessPage = lazy(() =>
  import("./pages/clinic/Acounting/PaymentSuccessPage")
);
const ClinicPayment = lazy(() =>
  import("./pages/clinic/Acounting/ClinicPayment")
);
const UpcomingAppointmentTableFullList = lazy(() =>
  import(
    "./pages/client/Dashboard/Appointment/UpcomingAppointmentTableFullList"
  )
);

function ClientApp() {
  const { isLoggedin, user, clinic, setUser, setClinic } =
    useContext(ClinicAuthContext);

  const handleGetUserCallback = (data) => {
    setUser(data);
  };
  const handleGetUserErrorCallback = () => {};
  const { pathname } = useLocation();
  const handleGetClinicCallback = (data) => {
    setClinic(data);
  };
  const handleErrorCallback = () => {
    // navigate(CLINIC_LINKS.LOGIN);
  };
  useEffect(() => {
    const myAbortController = new AbortController();
    //Runs on the first render of all client related pages
    if (pathname.includes("vclients") && clinic.id) {
      getFullUserInfo(
        handleGetUserCallback,
        handleGetUserErrorCallback,
        clinic.id,
        null,
        myAbortController
      );
      if (!clinic.id) {
        getClinicDetailSlug(
          getClinicSlugFromSubHost(),
          handleGetClinicCallback,
          handleErrorCallback
        );
      }
      return () => {
        myAbortController.abort();
      };
    }
  }, [pathname, user.id, clinic.id]);

  return (
    <>
      <Routes>
        <Route path={CLIENT_LINKS.LOGIN} element={<Login />} />
        <Route
          path={CLIENT_LINKS.REGISTER}
          element={
            <Suspense fallback={<PageLoader />}>
              <Register />
            </Suspense>
          }
        />
        <Route
          path={CLIENT_LINKS.GET_CODE}
          element={
            <Suspense fallback={<PageLoader />}>
              <GetCode />
            </Suspense>
          }
        />
        <Route
          path={CLIENT_LINKS.BASIC_REGISTRATION_FORM}
          element={
            <Suspense fallback={<PageLoader />}>
              <BasicRegForm />
            </Suspense>
          }
        />
        <Route
          path={CLIENT_LINKS.RESET_PASSWORD}
          element={
            <Suspense fallback={<PageLoader />}>
              <ResetPassword />
            </Suspense>
          }
        />
        <Route
          path={CLIENT_LINKS.FORGOT_PASSWORD}
          element={
            <Suspense fallback={<PageLoader />}>
              <ForgotPassword />
            </Suspense>
          }
        />
        <Route
          path={CLIENT_LINKS.REGISTRATION}
          element={
            <Suspense fallback={<PageLoader />}>
              <ClientReg />
            </Suspense>
          }
        ></Route>

        <Route
          path="/vclients/"
          element={
            <Suspense fallback={<PageLoader />}>
              <ClientRoute>
                <DefaultLayout />
              </ClientRoute>
            </Suspense>
          }
        >
          <Route
            path="dashboard"
            element={
              <Suspense fallback={<ClientPageLoader />}>
                <ClientRoute>
                  <Dashboard />
                </ClientRoute>
              </Suspense>
            }
          />

          <Route
            path={CLIENT_LINKS.PATIENTS_REGISTRATION}
            element={
              <Suspense fallback={<PageLoader />}>
                <ClientPetReg />
              </Suspense>
            }
          ></Route>
          <Route
            path={CLIENT_LINKS.MEDICAL_INFORMATION_REGISTRATION}
            element={
              <Suspense fallback={<PageLoader />}>
                <ClientMedicalInformaion />
              </Suspense>
            }
          ></Route>

          {/* <Route path="client/dashboard/appointment" element={<Appointment />} ></Route> */}

          <Route
            path={CLIENT_LINKS.APPOINTMENTS}
            element={
              <Suspense fallback={<ClientPageLoader />}>
                <ClientRoute>
                  <Appointment />
                </ClientRoute>
              </Suspense>
            }
          ></Route>
          <Route
            path={CLIENT_LINKS.APPOINTMENT_DETAIL}
            element={
              <Suspense fallback={<ClientPageLoader />}>
                <ClientRoute>
                  <AppointmentDetails />
                </ClientRoute>
              </Suspense>
            }
          ></Route>
          <Route
            path={CLIENT_LINKS.APPOINTMENT_RESCHEDULE}
            element={
              <Suspense fallback={<ClientPageLoader />}>
                <ClientRoute>
                  <AppointmentReschedule />
                </ClientRoute>
              </Suspense>
            }
          ></Route>
          <Route
            path={CLIENT_LINKS.APPOINTMENT_EMPTY_MEDICAL_RECORD}
            element={
              <Suspense fallback={<ClientPageLoader />}>
                <ClientRoute>
                  <EmptyMedicalRecord />
                </ClientRoute>
              </Suspense>
            }
          ></Route>
          <Route
            path={CLIENT_LINKS.APPOINTMENT_MEDICAL_RECORD}
            element={
              <Suspense fallback={<ClientPageLoader />}>
                <ClientRoute>
                  <AppointmentMedicalRecord />
                </ClientRoute>
              </Suspense>
            }
          ></Route>
          <Route
            path={CLIENT_LINKS.APPOINTMENT_RESCHEDULE_BOARDING}
            element={
              <Suspense fallback={<ClientPageLoader />}>
                <ClientRoute>
                  <AppointmentRescheduleBoarding />
                </ClientRoute>
              </Suspense>
            }
          ></Route>
          <Route
            path={CLIENT_LINKS.APPOINTMENT_MEDICAL_RECORD_PRESCRIPTION}
            element={
              <Suspense fallback={<ClientPageLoader />}>
                <ClientRoute>
                  <MedicalPrescription />
                </ClientRoute>
              </Suspense>
            }
          ></Route>
          <Route
            path={CLIENT_LINKS.APPOINTMENT_MEDICAL_REORD_TEST_DETAIL}
            element={
              <Suspense fallback={<ClientPageLoader />}>
                <ClientRoute>
                  <TestDetails />
                </ClientRoute>
              </Suspense>
            }
          ></Route>
          <Route
            path={CLIENT_LINKS.APPOINTMENT_CREATE}
            element={
              <Suspense fallback={<ClientPageLoader />}>
                <ClientRoute>
                  <CreateAppointment />
                </ClientRoute>
              </Suspense>
            }
          ></Route>
          <Route
            path={CLIENT_LINKS.APPOINTMENT_PAYMENT}
            element={
              <Suspense fallback={<ClientPageLoader />}>
                <ClientRoute>
                  <PaymentMethod />
                </ClientRoute>
              </Suspense>
            }
          ></Route>
          <Route
            path={CLIENT_LINKS.PATIENT_DETAIL}
            element={
              <Suspense fallback={<ClientPageLoader />}>
                <ClientRoute>
                  <Patient />
                </ClientRoute>
              </Suspense>
            }
          ></Route>
          <Route
            path={CLIENT_LINKS.UPCOMING_APPOINTMENT_FULL_LIST}
            element={
              <Suspense fallback={<ClientPageLoader />}>
                <ClientRoute>
                  <UpcomingAppointmentTableFullList />
                </ClientRoute>
              </Suspense>
            }
          ></Route>

          <Route
            path={CLIENT_LINKS.PATIENTS}
            element={
              <Suspense fallback={<ClientPageLoader />}>
                <ClientRoute>
                  <Patients />
                </ClientRoute>
              </Suspense>
            }
          ></Route>
          <Route
            path={CLIENT_LINKS.PATIENT_ADD_NEW_PATIENT}
            element={
              <Suspense fallback={<ClientPageLoader />}>
                <ClientRoute>
                  <AddNewPatient />
                </ClientRoute>
              </Suspense>
            }
          ></Route>
          <Route
            path={CLIENT_LINKS.PATIENT_EDIT}
            element={
              <Suspense fallback={<ClientPageLoader />}>
                <ClientRoute>
                  <EditPatient />
                </ClientRoute>
              </Suspense>
            }
          ></Route>
          <Route
            path={CLIENT_LINKS.PAYMENT_DETAILS}
            element={
              <Suspense fallback={<ClientPageLoader />}>
                <ClientRoute>
                  <PaymentDetails />
                </ClientRoute>
              </Suspense>
            }
          ></Route>

          <Route
            path={CLIENT_LINKS.INVOICE_HISTORY}
            element={
              <Suspense fallback={<ClientPageLoader />}>
                <ClientRoute>
                  <CustomerInvoiceList />
                </ClientRoute>
              </Suspense>
            }
          />
          <Route
            path={CLIENT_LINKS.PAY_INVOICE}
            element={
              <Suspense fallback={<ClientPageLoader />}>
                <ClientRoute>
                  <ClinicPayment />
                </ClientRoute>
              </Suspense>
            }
          />
          <Route
            path={CLIENT_LINKS.PAY_INVOICE_SUCCESS}
            element={
              <Suspense fallback={<ClientPageLoader />}>
                <ClientRoute>
                  <PaymentSuccessPage />
                </ClientRoute>
              </Suspense>
            }
          />
          <Route
            path={CLIENT_LINKS.PAYMENT_HISTORY}
            element={
              <Suspense fallback={<ClientPageLoader />}>
                <ClientRoute>
                  <PaymentHistory />
                </ClientRoute>
              </Suspense>
            }
          ></Route>
          <Route
            path={CLIENT_LINKS.MEDICAL_RECORD}
            element={
              <Suspense fallback={<ClientPageLoader />}>
                <ClientRoute>
                  <MedicalRecord />
                </ClientRoute>
              </Suspense>
            }
          ></Route>
          <Route
            path={CLIENT_LINKS.DASHBOARD_PERSONAL_INFORMATION}
            element={
              <Suspense fallback={<ClientPageLoader />}>
                <ClientRoute>
                  <PersonalInformation />
                </ClientRoute>
              </Suspense>
            }
          ></Route>
          <Route
            path={CLIENT_LINKS.DASHBOARD_PATIENT_INFORMATION}
            element={
              <Suspense fallback={<ClientPageLoader />}>
                <ClientRoute>
                  <PatientInformation />
                </ClientRoute>
              </Suspense>
            }
          ></Route>
          <Route
            path={CLIENT_LINKS.DASHBOARD_MEDICAL_INFORMATION}
            element={
              <Suspense fallback={<ClientPageLoader />}>
                <ClientRoute>
                  <PatientMedicalRecord />
                </ClientRoute>
              </Suspense>
            }
          ></Route>
          <Route
            path={CLIENT_LINKS.PATIENTS_MEDICAL_RECORDS}
            element={
              <Suspense fallback={<ClientPageLoader />}>
                <ClientRoute>
                  <ClientMedicalRecord />
                </ClientRoute>
              </Suspense>
            }
          ></Route>
          <Route
            path={CLIENT_LINKS.DASHBOARD_PASSWORD_UPDATE}
            element={
              <Suspense fallback={<ClientPageLoader />}>
                <ClientRoute>
                  <Password />
                </ClientRoute>
              </Suspense>
            }
          ></Route>
        </Route>
      </Routes>
    </>
  );
}

export default ClientApp;
