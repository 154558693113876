import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import Calendar from "../../assets/dasboardAssets/Calendar.png";
import DashboardWhite from "../../assets/dasboardAssets/Dashboard.png";
import CalendarWhite from "../../assets/dasboardAssets/CalendarWhite.svg";
import MedicWhite from "../../assets/dasboardAssets/MedicWhite.svg";
import Medic from "../../assets/dasboardAssets/medic.png";
import Patient from "../../assets/dasboardAssets/Patient.png";
import PatientWhite from "../../assets/dasboardAssets/PatientWhite.svg";
import Payment from "../../assets/dasboardAssets/payment.png";
import Dashboard from "../../assets/dasboardAssets/Dashb.png";
import Bill from "../../assets/icon/payment.svg";
import BillWhite from "../../assets/dasboardAssets/BillWhite.svg";
import AppLabelWhite from "../../assets/dasboardAssets/AppLabelWhite.svg";

// import Dashboard from '../../assets/dasboardAssets/Dashboard.png';
// import DashboardOutlined from '@mui/material';
import { NavLink, useLocation } from "react-router-dom";
import { CLIENT_LINKS } from "../../links/clientLinks";

const SideNavItem = ({ itemIcon, itemIconWhite, title, path }) => {
  const location = useLocation();
  const [currentPagePath, setCurrentPagePath] = useState("");
  useEffect(() => {
    setCurrentPagePath(location.pathname);
  }, [location.pathname]);

  let activeStyle = {
    textDecoration: "none",
    backgroundColor: "#009EED",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "row",
    paddingTop: "12px",
    paddingBottom: "12px",
    paddingRight: "24px",
    paddingLeft: "12px",
    color: "#ffffff",
    fontFamily: "Poppins",
    fontWeight: "400",
    fontSize: "14px",
    mb: 4,
  };
  let nonActiveStyle = {
    textDecoration: "none",
    display: "flex",
    flexDirection: "row",
    color: "#39484F",
    fontFamily: "Poppins",
    fontWeight: "400",
    fontSize: "14px",
    mb: 4,
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        mb: 4,
      }}
    >
      <NavLink
        to={path}
        style={({ isActive }) => (isActive ? activeStyle : nonActiveStyle)}
      >
        <img
          src={location.pathname === path ? itemIconWhite : itemIcon}
          style={{
            paddingRight: "12px",
          }}
          alt="dashboard Icon"
        />

        {title}
      </NavLink>
    </Box>
  );
};

const SideNavItems = () => {
  return (
    <Box
      sx={{
        mt: 5,
        color: "textGray.sideNav",
      }}
    >
      <SideNavItem
        title="Dashboard"
        itemIcon={Dashboard}
        itemIconWhite={DashboardWhite}
        path={CLIENT_LINKS.DASHBOARD}
      />
      <SideNavItem
        title="Appointment"
        itemIcon={Calendar}
        itemIconWhite={CalendarWhite}
        path={CLIENT_LINKS.APPOINTMENTS}
      />
      <SideNavItem
        title="Patients"
        itemIcon={Patient}
        itemIconWhite={PatientWhite}
        path={CLIENT_LINKS.PATIENTS}
      />
      <SideNavItem
        title="Medical Records"
        itemIcon={Medic}
        itemIconWhite={MedicWhite}
        path={CLIENT_LINKS.MEDICAL_RECORD}
      />
      <SideNavItem
        title="Bills"
        itemIcon={Payment}
        itemIconWhite={AppLabelWhite}
        path={CLIENT_LINKS.INVOICE_HISTORY}
      />
      <SideNavItem
        title="Payment History"
        itemIcon={Bill}
        itemIconWhite={BillWhite}
        path={CLIENT_LINKS.PAYMENT_HISTORY}
      />
    </Box>
  );
};

export default SideNavItems;
