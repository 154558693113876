import axiosInstance from "../axios/post";

export const PAGE_LIMIT = 10;

export const apiGetAllClinicSlugList = (successCallback, errorCallback) => {
  let pageLink = `/api/clinic/all_clinic_slugs/all/`;

  try {
    axiosInstance
      .get(pageLink)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get slug");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const apiGetAppLabelList = (
  successCallback,
  errorCallback,
  page,
  tablePage
) => {
  let pageLink = `/api/app_settings/app_label/`;
  if (tablePage && page) {
    pageLink = `${pageLink}?limit=${PAGE_LIMIT}&offset=${page}`;
  } else if (tablePage) {
    pageLink = `${pageLink}?limit=${PAGE_LIMIT}`;
  }
  try {
    axiosInstance
      .get(pageLink)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get app label");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const apiGetAppLabelDetail = (id, successCallback, errorCallback) => {
  let pageLink = `/api/app_settings/app_label_detail/${id}/`;
  try {
    axiosInstance
      .get(pageLink)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get app label");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const apiGetSubscriptionList = (
  successCallback,
  errorCallback,
  page,
  tablePage
) => {
  let pageLink = `/api/plan/list/`;
  if (tablePage && page) {
    pageLink = `${pageLink}?limit=${PAGE_LIMIT}&offset=${page}`;
  } else if (tablePage) {
    pageLink = `${pageLink}?limit=${PAGE_LIMIT}`;
  }
  try {
    axiosInstance
      .get(pageLink)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get app label");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const apiGetSubscription = (successCallback, errorCallback) => {
  try {
    axiosInstance
      .get("/api/plan/list/")
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get sub");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};
export const apiGetClinicList = (
  handleSuccessAction,
  handleErrorAction,
  page,
  tablePage
) => {
  let pageLink = `/api/clinic/`;
  if (tablePage && page) {
    pageLink = `${pageLink}?limit=${PAGE_LIMIT}&offset=${page}`;
  } else if (tablePage) {
    pageLink = `${pageLink}?limit=${PAGE_LIMIT}`;
  }
  try {
    axiosInstance
      .get(pageLink)
      .then((res) => {
        handleSuccessAction(res.data);
      })
      .catch((err) => {
        handleErrorAction(err.response);
        console.log(`Error: ${err.response} `);
      });
  } catch (err) {
    handleErrorAction(err.response);
    console.log(`Error: ${err.response} `);
  }
};
export const apiGetSubscriptionDetail = (
  id,
  successCallback,
  errorCallback
) => {
  let pageLink = `/api/plan/detail/${id}/`;
  try {
    axiosInstance
      .get(pageLink)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get subscription");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const apiGetAppCenterPaymentGateways = (
  successCallback,
  errorCallback
) => {
  try {
    axiosInstance
      .get(`/api/billing/payment-gateway/list/`)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback(err);
      });
  } catch (error) {
    console.log(error);
  }
};

export const apiGetAppCenterPaymentGatewayDetail = (
  id,
  successCallback,
  errorCallback
) => {
  try {
    axiosInstance
      .get(`/api/billing/payment-gateway/detail/${id}/`)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback(err);
      });
  } catch (error) {
    console.log(error);
  }
};

export const apiGetAppCenterPaymentGatewayDetailByPaymentType = (
  paymentType,
  successCallback,
  errorCallback
) => {
  try {
    axiosInstance
      .get(`/api/billing/payment-gateway/detail-by-type/${paymentType}/`)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback(err);
      });
  } catch (error) {
    console.log(error);
  }
};

export const getAppCenterAuthenticatedStaffDetail = (
  successCallback,
  errorCallback
) => {
  try {
    axiosInstance
      .get(`/users/authenticated-app-center-staff/`)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback(err);
      });
  } catch (error) {
    console.log(error);
  }
};
export const getAppCenterStaffList = (successCallback, errorCallback) => {
  try {
    axiosInstance
      .get(`/users/app-center-staff-list/`)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback(err);
      });
  } catch (error) {
    console.log(error);
  }
};
export const getAppCenterStaffDetail = (id, successCallback, errorCallback) => {
  try {
    axiosInstance
      .get(`/users/app-center-staff-detail/${id}/`)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback(err);
      });
  } catch (error) {
    console.log(error);
  }
};

export const getAppCenterBulletDashboardStats = (
  successCallback,
  errorCallback
) => {
  try {
    axiosInstance
      .get(`/api/report/app-center/dashboard-stats/`)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback(err);
      });
  } catch (error) {
    console.log(error);
  }
};

export const getAppCenterActiveUserDashboardChart = (
  month,
  year,
  successCallback,
  errorCallback
) => {
  let link = `/api/report/app-center/dashboard/active-users/yearly/${year}/`;

  if (month && year) {
    link = `/api/report/app-center/dashboard/active-users/monthly/${month}/${year}/`;
  }
  try {
    axiosInstance
      .get(link)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback(err);
      });
  } catch (error) {
    console.log(error);
  }
};

export const apiCreateClinicDefaultTax = (
  data,
  successCallback,
  errorCallback
) => {
  let pageLink = `/api/app_settings/default_tax/`;

  try {
    axiosInstance
      .post(pageLink, data)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not create default tax");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const apiUpdateClinicDefaultTax = (
  clinic_id,
  data,
  successCallback,
  errorCallback
) => {
  let pageLink = `/api/app_settings/default_tax/${clinic_id}/`;

  try {
    axiosInstance
      .patch(pageLink, data)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not update default tax");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const apiGetClinicDefaultTax = (
  clinic_id,
  successCallback,
  errorCallback
) => {
  let pageLink = `/api/app_settings/default_tax/${clinic_id}/`;

  try {
    axiosInstance
      .get(pageLink)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get default tax");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};
