import { Backdrop, CircularProgress } from '@mui/material'
import React from 'react'

export const PageProgress = () => {
  return (
    <CircularProgress 
    sx={{
        position:"absolute",
        left:"50%",
        top:"50%"
    }} />
  )
}

export const PaperProgress = () => {
    return (
      <CircularProgress 
      sx={{
         paddingX:"50%",
         paddingY:"40vh"
      }} />
    )
  }

  export const ButtonSubmitProgress = ({color}) => {
    const adjustColor=color?color:"#fff"
    return (
      <CircularProgress 
      sx={{
          mx:2,
        color:adjustColor
      }} />
    )
  }

  export const FullpageBackDropProgress=({color, open, handleClose})=>{
    const nowColor=color?color:"#fff"

    return(
      <Backdrop
  sx={{ color: nowColor, zIndex: (theme) => theme.zIndex.drawer + 1 }}
  open={open}
  onClick={handleClose}
>
  <CircularProgress color="inherit" />
</Backdrop>
    )
  }


