import {
  Alert,
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import React, { useReducer, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoginContainer from "./LoginContainer";

import { ACTION_TYPES } from "../../../reducers/ActionTypes";
import {
  emptyReturnPostReducer,
  INITIAL_STATE,
} from "../../../reducers/postReducer";
import { handleSetResetEmail } from "../../../api/API";
import { useNavigate } from "react-router-dom";
import { CLINIC_LINKS } from "../../../links/clinicLinks";
import ClinicAuthContext from "../../../context/clinicAuthContext";
import { APP_CENTER_LINKS } from "../../../links/appCenterLinks";

const ResetPassword = () => {
  const { clinic } = React.useContext(ClinicAuthContext);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [state, dispatch] = useReducer(emptyReturnPostReducer, INITIAL_STATE);

  const successCallback = () => {
    dispatch({ type: ACTION_TYPES.FETCH_SUCCESS });
  };
  const errorCallback = (data) => {
    dispatch({ type: ACTION_TYPES.FETCH_ERROR, payload: data });
  };
  const sendResetEmail = (event) => {
    event.preventDefault();
    dispatch({ type: ACTION_TYPES.FETCH_START });
    const hostName = window.location.hostname;
    handleSetResetEmail(
      hostName,
      clinic.id,
      email,
      successCallback,
      errorCallback
    );
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      sendResetEmail();
    }
  };
  const goToLogin = () => navigate(APP_CENTER_LINKS.LOGIN);
  return (
    <LoginContainer>
      <Typography
        variant="h6"
        sx={{
          display: "flex",
          alignItems: "center",
          marginLeft: "4rem",
        }}
      >
        <ArrowBackIcon onClick={goToLogin} sx={{ paddingRight: "10px" }} />{" "}
        <span style={{ color: "#CDD1D3", paddingRight: "12px" }}>|</span> Forgot
        Password
      </Typography>
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column", marginLeft: "4rem" }}
      >
        <TextField
          margin="normal"
          required
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          sx={{ width: "40ch" }}
          autoFocus
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          onKeyPress={handleKeyPress}
        />
        <Button
          variant="contained"
          onClick={sendResetEmail}
          sx={{ mt: 5, paddingY: "13px", borderRadius: "8px", width: "40ch" }}
          onKeyPress={handleKeyPress}
        >
          Reset Password
          {state.loading ? <CircularProgress /> : ""}
        </Button>
      </Box>
      {state.post ? (
        <Alert severity="success">
          {" "}
          Recovery Email Sent. If you don't find the email check if the email
          address entered is correct{" "}
        </Alert>
      ) : (
        ""
      )}
      {state.error ? (
        <Alert severity="error">
          {" "}
          {clinic.id
            ? "Error. Check the email address typed or user doesn't exist"
            : "Fetch Error Reload Page if Error Persists Contact Clinic"}{" "}
        </Alert>
      ) : (
        ""
      )}
    </LoginContainer>
  );
};

export default ResetPassword;
