import React from "react";
import { useLocation } from "react-router-dom"

/**
 * A convenient wrapper for reading and writing search parameters via the
 * URLSearchParams interface.
 */
export const useSearchQuery=()=>{
    const {search}=useLocation()

    return React.useMemo(() => new URLSearchParams(search), [search]);
}
