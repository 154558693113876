import { baseURL } from "../api/axios/post";

export const imageLinkReformat = (image) => {
  if (image) {
    if (image.includes("localhost")) {
      return image;
    } else if (image.includes("s3.amazonaws.com")) {
      return image;
    } else if (image.includes("testbck.smartdvm.com")) {
      return image;
    } else if (image.includes(window.location.host)) {
      return image;
    } else {
      return `${baseURL()}${image}`;
    }
  } else {
    return null;
  }
};
