import Logo from "./assets/Clinic/Registration/Logo.png";

import "./PageLoader.css";

const PageLoader = () => {
  return (
    <div>
      <div>
        <div className="loading-container">
          {/* <img
            id="loading-text"
            style=" height: 200px; width:120px"
            src={Logo}
            alt="smartdvm"
          /> */}
          loading...
        </div>
      </div>
    </div>
  );
};
export default PageLoader;
