import { Box } from "@mui/material";
import React from "react";
// import SideNavItem from './SideNavItem'
// import DashboardOutlined from '@mui/icons-material/DashboardOutlined'
// import CalendarMonthOutlined from '@mui/icons-material/CalendarMonthOutlined'
// import Dash from '../../assets/Clinic/Dashboard/Dash.png'
// import Calender from '../../assets/Clinic/Dashboard/Calender.png'
// import Users from '../../assets/Clinic/Dashboard/Users.png'
// import Inventory from '../../assets/Clinic/Dashboard/Group3.png'
// import Lab from '../../assets/Clinic/Dashboard/Group2.png'
// import Acct from '../../assets/Clinic/Dashboard/Group1.png'
// import Notes from '../../assets/Clinic/Dashboard/Note.png'

import MenuItems from "./Menu";

const SideNavItems = () => {
  return (
    <Box
      sx={{
        mt: 5,
        color: "textGray.sideNav",
      }}
    >
      <MenuItems />
      {/* <SideNavItem title="Dashboard" itemIcon={Dash}  path="/clinic/dashboard"/>
    <SideNavItem title="Calender" itemIcon={Calender} path="/clinic/calender"/>
    <SideNavItem title="Users" itemIcon={Users} path="/clinic/users"/>
    <SideNavItem title="Inventory" itemIcon={Inventory} path="/clinic/inventory"/>
    <SideNavItem title="Laboratory" itemIcon={Lab} path="/clinic/laboratory"/>
    <SideNavItem title="Accounting" itemIcon={Acct} path="/clinic/accounting"/>
    <SideNavItem title="Reports" itemIcon={Notes} path="/clinic/reports"/> */}
    </Box>
  );
};

export default SideNavItems;
