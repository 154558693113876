import { savableDate, savableDateTime } from "../features/readableDate";
import getImagePostHeader, {
  headerFileSpecial,
} from "./axios/getImagePostHeader";
import axiosInstance from "./axios/post";
import { getClinicClient, getUser } from "./getUserMain";

export const PAGE_LIMIT = 10;

// Login to the clinic Application
export const handleLogin = (values, handleSuccessAction, handleErrorAction) => {
  try {
    axiosInstance
      .post("/api/token/", {
        email: values.email,
        password: values.password,
      })
      .then((res) => {
        localStorage.setItem("access_token", res.data.access);
        localStorage.setItem("refresh_token", res.data.refresh);
        axiosInstance.defaults.headers["Authorization"] =
          "Beerer " + localStorage.getItem("access_token");
        handleSuccessAction();
      })
      .catch((err) => {
        console.log(`Error: ${err.response} `);
        handleErrorAction();
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
    handleErrorAction();
  }
};

export const handleRegister = (
  values,
  successCallBack,
  errorCallback,
  logginErrorCallback
) => {
  try {
    axiosInstance
      .post("/users/register/", {
        email: values.email,
        password: values.password,
        first_name: values.firstName,
        last_name: values.lastName,
      })
      .then((res) => {
        const newValues = {
          email: values.email,
          password: values.password,
        };
        handleLogin(newValues, successCallBack, logginErrorCallback);
      });
    // .catch((err) => {
    //   errorCallback(err.response);
    // });
  } catch (err) {
    console.log(`Error: ${err.response} `);
    console.log("didnt register");
    errorCallback(err.response);
  }
};
export const handleNonCurrentUserRegister = (
  values,
  successCallback,
  errorCallback,
  clinicId,
  hostName
) => {
  let link = `/users/register/non_logged_in_user/`;
  if (clinicId && hostName) {
    link = `/users/register/non_logged_in_user/${clinicId}/${hostName}/`;
  }
  try {
    axiosInstance
      .post(link, {
        email: values.email,
        password: values.password,
      })
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback(err.response);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
    console.log("didnt register");
    errorCallback(err.response);
  }
};

export const handleGetUserWithCode = (
  code,
  handleSuccessAction,
  handleErrorAction
) => {
  try {
    axiosInstance
      .get(`/users/change_password/get_user/${code}/`)
      .then((res) => {
        handleSuccessAction(res.data);
      })
      .catch((err) => {
        handleErrorAction(err.response);
        console.log(`Error: ${err.response} `);
      });
  } catch (err) {
    handleErrorAction(err.response);
    console.log(`Error: ${err.response} `);
  }
};
export const changePassword = (
  clinicId,
  values,
  handleSuccessAction,
  handleErrorAction
) => {
  try {
    axiosInstance
      .post(`/users/change_password/${clinicId}/`, values)
      .then((res) => {
        handleSuccessAction(res.data);
      })
      .catch((err) => {
        handleErrorAction(err.response);
        console.log(`Error: ${err.response}`);
      });
  } catch (err) {
    handleErrorAction(err.response);
    console.log(`Error: ${err.response} `);
  }
};
export const handleCheckAndChangePassword = (
  clinicId,
  values,
  handleSuccessAction,
  handleErrorAction
) => {
  try {
    axiosInstance
      .post(`/users/check_and_change_password/${clinicId}/`, values)
      .then((res) => {
        handleSuccessAction(res.data);
      });
    // .catch((err) => {
    //   handleErrorAction(err.response);
    //   console.log(`Error: ${err.response}`);
    // });
  } catch (err) {
    handleErrorAction(err.response);
    console.log(`Error: ${err.response} `);
  }
};

export const handleSetResetEmail = (
  hostName,
  clinicID,
  email,
  handleSuccessAction,
  handleErrorAction
) => {
  try {
    axiosInstance
      .post(`/users/reset/${clinicID}/${hostName}/`, {
        email: email,
      })
      .then((res) => {
        handleSuccessAction();
      })
      .catch((err) => {
        handleErrorAction(err.response);
        console.log(`Error: ${err.response} `);
      });
  } catch (err) {
    handleErrorAction(err.response);
    console.log(`Error: ${err.response} `);
  }
};
export const handleClinicCreation = (
  values,
  handleSuccessCallback,
  handleErrorCallback
) => {
  try {
    axiosInstance
      .post(
        "/api/clinic/",
        {
          name: values.name,
          clinic_email: values.clinicEmail,
          phone_number: values.phoneNumber,
          license_number: values.licenseNumber,
          zip_code: values.zipCode,
          specializations: values.specializations,
          state: values.state,
          city: values.city,
          address: values.address,
        },
        getImagePostHeader
      )
      .then((res) => {
        handleSuccessCallback(res.data);
      })
      .catch((err) => {
        handleErrorCallback(err.response);
        console.log(`Error: ${err.response}`);
      });
  } catch (err) {
    handleErrorCallback(err.response);
  }
};
export const handleClinicUpdate = (
  id,
  values,
  handleSuccessCallback,
  handleErrorCallback
) => {
  try {
    axiosInstance
      .patch(
        `/api/clinic/${id}/`,
        {
          name: values.name,
          clinic_email: values.clinicEmail,
          phone_number: values.phoneNumber,
          license_number: values.licenseNumber,
          zip_code: values.zipCode,
          specializations: values.specializations,
          state: values.state,
          city: values.city,
          address: values.address,
          logo: values.logo,
          fav_icon: values.fav_icon,
        },
        getImagePostHeader
      )
      .then((res) => {
        handleSuccessCallback(res.data);
      })
      .catch((err) => {
        handleErrorCallback(err.response);
        console.log(`Error: ${err.response}`);
      });
  } catch (err) {
    handleErrorCallback(err.response);
  }
};
export const getClinicDetail = (id, handleSuccessAction, handleErrorAction) => {
  try {
    axiosInstance
      .get(`/api/clinic/${id}/`)
      .then((res) => {
        handleSuccessAction(res.data);
      })
      .catch((err) => {
        handleErrorAction(err.response);
        console.log(`Error: ${err.response} `);
      });
  } catch (err) {
    handleErrorAction(err.response);
    console.log(`Error: ${err.response} `);
  }
};

export const getClinicOwnerDetail = (
  clinicId,
  handleSuccessAction,
  handleErrorAction
) => {
  try {
    axiosInstance
      .get(`/users/get-clinic-owner/${clinicId}/`)
      .then((res) => {
        handleSuccessAction(res.data);
      })
      .catch((err) => {
        handleErrorAction(err.response);
        console.log(`Error: ${err.response} `);
      });
  } catch (err) {
    handleErrorAction(err.response);
    console.log(`Error: ${err.response} `);
  }
};

export const updateClinicDetail = (
  data,
  id,
  handleSuccessAction,
  handleErrorAction
) => {
  try {
    axiosInstance
      .patch(`/api/clinic/${id}/`, data)
      .then((res) => {
        handleSuccessAction(res.data);
      })
      .catch((err) => {
        handleErrorAction(err.response);
        console.log(`Error: ${err.response} `);
      });
  } catch (err) {
    handleErrorAction(err.response);
    console.log(`Error: ${err.response} `);
  }
};
export const getClinicDetailSlug = (
  slug,
  handleSuccessAction,
  handleErrorAction
) => {
  try {
    axiosInstance
      .get(`/api/clinic/${slug}/slug_detail/`)
      .then((res) => {
        handleSuccessAction(res.data);
      })
      .catch((err) => {
        handleErrorAction(err.response);
        console.log(`Error: ${err.response} `);
      });
  } catch (err) {
    handleErrorAction(err.response);
    console.log(`Error: ${err.response} `);
  }
};
export const handleClientRegistration = (
  values,
  handleSuccessAction,
  handleErrorAction
) => {
  // Takes in the values of that contains each field sent.
  // The action is what its meant to do after successful operation

  // imageValue:user.image,
  // firstName:user.full_name,
  // lastName:user.full_name,
  // gender:user.gender,
  // email:user.email,
  // dateOfBirth:user.dob,
  // phoneNumber:user.phone_number,
  // state:user.state,
  // city:user.city,
  // street:user.address,
  // zip:user.zip_code,

  try {
    axiosInstance
      .patch(`/users/inner/main/${getUser()}/`, {
        // dob:"2022-08-31",
        dob: `${values.dateOfBirth.$y}-${values.dateOfBirth.$M + 1}-${
          values.dateOfBirth.$D
        }`,
        phone_number: values.phoneNumber,
        state: values.state,
        city: values.city,
        address: values.street,
        zip_code: values.zip,
      })
      .then((res) => {
        handleSuccessAction(res.data);
      })
      .catch((err) => {
        handleErrorAction();
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const handleClientUpdate = (
  data,
  handleSuccessAction,
  handleErrorAction,
  id,
  clinicId,
  hostName
) => {
  const checkID = id ? id : getUser();
  const link =
    hostName && clinicId
      ? `/users/inner/main/${checkID}/${clinicId}/${hostName}/`
      : `/users/inner/main/${checkID}/`;
  try {
    axiosInstance
      .patch(link, data, getImagePostHeader)
      .then((res) => {
        handleSuccessAction(res.data);
      })
      .catch((err) => {
        console.log(`Error: ${err} `);
        handleErrorAction();
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const handlePetUpdate = (
  values,
  handleSuccessAction,
  handleErrorAction
) => {
  const data = {
    name: values.name,
    dob: values.dateOfBirth,
    breed: values.breed,
    specie: values.specie,
    weight: values.weight,
    gender: values.sex,
    // "image":values.image,
    color: values.color,
    microchip_id: values.microchip_id,
    underlying_disease: values.underlying_disease,
    notes: values.notes,
    status: values.status,
    neutered: values.neutered,
  };
  if (values.image) {
    data.image = values.image;
  }

  try {
    axiosInstance
      .patch(`/api/patients/patient/${values.id}/`, data, getImagePostHeader)
      .then((res) => {
        handleSuccessAction(res.data);
      })
      .catch((err) => {
        console.log(`Error: ${err} `);
        handleErrorAction();
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const handlePetRegistration = (
  values,
  handleSuccessAction,
  handleErrorAction,
  clinic_id
) => {
  const data = {
    customer: values.customer ? values.customer : getClinicClient(),
    name: values.name,
    dob: values.dateOfBirth,
    color: values.color,
    gender: values.sex,
    specie: values.specie,
    breed: values.breed,
    weight: values.weight,
    microchip_id: values.microchip_id,
    underlying_disease: values.underlying_disease,
    neutered: values.neutered,
    notes: values.notes,
    status: values.status,
  };
  if (values.image) {
    data["image"] = values.image;
  }
  try {
    axiosInstance
      .post(
        `/api/patients/patient/list/${clinic_id}/`,
        data,
        getImagePostHeader
      )
      .then((res) => {
        handleSuccessAction(res.data);
      })
      .catch((err) => {
        handleErrorAction();
        console.log(`Error: ${err.response} `, err.response);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};
export const handleMedicalRecordSubmission = (
  clinicId,
  clientId,
  values,
  handleSuccessAction,
  handleErrorAction,
  handleUploadProgress
) => {
  try {
    console.log(values);
    axiosInstance
      .post(
        `/api/records/clinic-docs/${clinicId}/document/`,
        {
          clinic: clinicId,
          patient: clientId,
          document_name: values.name,
          document_no: 12400878,
          document_type: values.type,
          document: values,
          certified_by: "",
        },
        getImagePostHeader
        // headerFileSpecial(handleUploadProgress)
      )
      .then((res) => {
        console.log(res.data);
        handleSuccessAction(res.data);
      })
      .catch((err) => {
        console.log(err);
        handleErrorAction(err.response.data);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getPatientSpecies = (handleGottenSpecie, clinic_id) => {
  try {
    axiosInstance
      .get(`/api/patients/specie/${clinic_id}/`)
      .then((res) => {
        // console.log(res.data)
        handleGottenSpecie(res.data);
      })
      .catch((err) => {
        console.log(`Error: ${err.response} `);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};
export const saveNewPatientSpecie = (specie, clinic_id) => {
  try {
    axiosInstance
      .post(`/api/patients/specie/${clinic_id}`, {
        specie: specie,
      })
      .then((res) => {
        console.log("saved");
      })
      .catch((err) => {
        console.log(`Error: ${err.response} `);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};
export const getSpecieBreeds = (specie, handleGottenBreeds) => {
  try {
    axiosInstance
      .get(`/api/patients/breed/${specie.toLowerCase()}`)
      .then((res) => {
        // console.log(res.data)
        handleGottenBreeds(res.data);
      })
      .catch((err) => {
        console.log(`Error: ${err.response} `);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};
export const saveNewSpecieBreed = (breed, specie) => {
  try {
    axiosInstance
      .post(`/api/patients/breed/${specie.toLowerCase()}/`, {
        breed: breed,
      })
      .then((res) => {
        console.log("saved breed");
      })
      .catch((err) => {
        console.log(`Error: ${err.response} `);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getClientsPatientsList = (
  getClientsPatientsListSuccessCallback,
  clinic_id
) => {
  //  it gets the patients owned by the requesting user
  try {
    axiosInstance
      .get(`/api/patients/mini/${clinic_id}/`)
      .then((res) => {
        // console.log(res.data)
        getClientsPatientsListSuccessCallback(res.data);
      })
      .catch((err) => {
        console.log("could not get patients");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};
export const getClientsPatientsListWithClientID = (
  id,
  getClientsPatientsListSuccessCallback
) => {
  //  it gets the patients owned by the requesting user
  try {
    axiosInstance
      .get(`/api/patients/auth_customer/${id}/patients/`)
      .then((res) => {
        // console.log(res.data)
        getClientsPatientsListSuccessCallback(res.data);
      })
      .catch((err) => {
        console.log("could not get patients");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

/**
 * Gets the list of appointmnets of a logged in user Or if a customer Id is found it gets the appointment list of the customer
 *
 * @param {*} appointmentsSuccessCallback call back function
 * @param {* int } clinic_id
 * @param {*int} customer_id
 */
export const getClientsPatientsAppointments = (
  appointmentsSuccessCallback,
  errorCallback,
  clinic_id,
  customer_id
) => {
  const linkApi = customer_id
    ? `/api/soap/appointment/${customer_id}/${clinic_id}/`
    : `/api/soap/appointment/client/${clinic_id}/auth_client/`;
  try {
    axiosInstance
      .get(linkApi)
      .then((res) => {
        // console.log(res.data)
        appointmentsSuccessCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log("could not get appointments");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getClinicBlogList = (blogListSuccuessCallback, clinic_id) => {
  try {
    axiosInstance
      .get(`/api/blog/list/${clinic_id}/`)
      .then((res) => {
        // console.log(res.data)
        blogListSuccuessCallback(res.data);
      })
      .catch((err) => {
        console.log("could not get blogs");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getClinicEventList = (eventListSuccuessCallback, clinic_id) => {
  // gets clinic event list
  try {
    axiosInstance
      .get(`/api/event/${clinic_id}/`)
      .then((res) => {
        // console.log(res.data)
        eventListSuccuessCallback(res.data);
      })
      .catch((err) => {
        console.log("could not get events");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

/**
 * - Basically it gets the full info of patients owned by the requesting user
 * - If ID is passed (ID of another user) it gets the full info of patients of that client
 */
export const getClientsPatients = (
  getClientsPatientsSuccessCallback,
  id,
  abortController
) => {
  const checkID = id ? id : getClinicClient();
  try {
    axiosInstance
      .get(`/api/patients/auth_customer/${checkID}/patients/`, {
        signal: abortController,
      })
      .then((res) => {
        console.log(res);
        getClientsPatientsSuccessCallback(res.data);
      })
      .catch((err) => {
        console.log(err);
        console.log(`Error: ${err.response} `);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const deleteClientsPatients = (
  getClientsPatientsSuccessCallback,
  id,
  abortController
) => {
  const checkID = id ? id : getClinicClient();
  try {
    axiosInstance
      .delete(`/api/patients/auth_customer/${checkID}/patients/`, {
        signal: abortController,
      })
      .then((res) => {
        console.log(res);
        getClientsPatientsSuccessCallback(res.data);
      })
      .catch((err) => {
        console.log(err);
        console.log(`Error: ${err.response} `);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getAppointmentDetail = async (id, appointmentsSuccessCallback) => {
  try {
    await axiosInstance
      .get(`/api/soap/appointment-detail/${id}/detail/`)
      .then((res) => {
        appointmentsSuccessCallback(res.data);
      })
      .catch((err) => {
        console.log(`Error: ${err.response} `);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getClinicAdmissionTypes = async (
  appointmentsSuccessCallback,
  clinic_id
) => {
  try {
    await axiosInstance
      .get(`/api/app_settings/${clinic_id}/admission_types/`)
      .then((res) => {
        appointmentsSuccessCallback(res.data);
      })
      .catch((err) => {
        console.log(`Error: ${err.response} `);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getHospitalDoctors = (doctorsSuccessCallback, clinic_id, date) => {
  const link = date
    ? `/users/staff/list/${clinic_id}/dated-caller/${date}/`
    : `/users/staff/list/${clinic_id}/`;
  try {
    axiosInstance
      .get(link)
      .then((res) => {
        doctorsSuccessCallback(res.data);
      })
      .catch((err) => {
        console.log(`Error: ${err.response} `);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const createAppointment = async (
  clinicId,
  values,
  appointmentCreateSuccessCallback,
  appointmentCreateErrorCallback
) => {
  const data = {
    clinic: clinicId,
    patient: values.selectedPatient,
    appointment_reason: values.description,
    appointment_type: values.admissionType,
    doctor_assigned: values.doctorAssigned,
    boarding_type: values.boardingType,
    boarding_size: values.boardingSize,
    appointment_date: values.date,
    appointment_checkout_date: values.checkoutDate,
    is_admitted: values.isAdmitted,
    boarding_status: values.boarding_status,
  };
  try {
    await axiosInstance
      .post(`/api/soap/appointment/${clinicId}/`, data)
      .then((res) => {
        appointmentCreateSuccessCallback(res.data);
      })
      .catch((err) => {
        appointmentCreateErrorCallback(err.response);
        console.log(`Error: ${err.response} `);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const updateAppointment = (id, data, successCallBack, errorCallback) => {
  try {
    axiosInstance
      .patch(`/api/soap/appointment-detail/${id}/detail/`, data)
      .then((res) => {
        successCallBack(res.data);
      })
      .catch((err) => {
        errorCallback(err.response);
        console.log(`Error: ${err.response} `);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const deleteClientSideAppointment = (
  id,
  successCallBack,
  errorCallback
) => {
  try {
    axiosInstance
      .delete(`/api/soap/appointment-detail/${id}/detail/`)
      .then((res) => {
        successCallBack(res.data);
      })
      .catch((err) => {
        errorCallback(err.response);
        console.log(`Error: ${err.response} `);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const rescheduleAppointment = async (
  values,
  appointmentRescheduleSuccessCallback,
  appointmentRescheduleErrorCallback
) => {
  const data = {
    appointment_date: `${values.date}T${values.time}`,
  };
  console.log(data);
  try {
    await axiosInstance
      .patch(`/api/soap/appointment-detail/${values.id}/detail/`, data)
      .then((res) => {
        appointmentRescheduleSuccessCallback(res.data);
      })
      .catch((err) => {
        appointmentRescheduleErrorCallback(err.response);
        console.log(`Error: ${err.response} `);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};
export const rescheduleBoardingAppointment = async (
  values,
  appointmentRescheduleSuccessCallback,
  appointmentRescheduleErrorCallback
) => {
  const data = {
    appointment_date: values.checkinDate,
    appointment_checkout_date: values.checkoutDate,
    appointment_reason: values.description,
    boarding_size: values.boardingSize,
  };
  console.log(data);
  try {
    await axiosInstance
      .patch(`/api/soap/appointment-detail/${values.id}/detail/`, data)
      .then((res) => {
        appointmentRescheduleSuccessCallback(res.data);
      })
      .catch((err) => {
        appointmentRescheduleErrorCallback(err.response);
        console.log(`Error: ${err.response} `);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};
export const createAppointmentPrescription = async (
  values,
  appointmentPrescriptionSuccessCallback,
  appointmentPrescriptionErrorCallback
) => {
  try {
    await axiosInstance
      .post(`/api/soap/prescriptions/create/`, values)
      .then((res) => {
        appointmentPrescriptionSuccessCallback(res.data);
      })
      .catch((err) => {
        appointmentPrescriptionErrorCallback(err.response);
        console.log(`Error: ${err.response} `);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const editAppointmentPrescription = async (
  id,
  values,
  appointmentPrescriptionSuccessCallback,
  appointmentPrescriptionErrorCallback
) => {
  try {
    await axiosInstance
      .patch(`/api/soap/prescriptions/detail/${id}/`, values)
      .then((res) => {
        appointmentPrescriptionSuccessCallback(res.data);
      })
      .catch((err) => {
        appointmentPrescriptionErrorCallback(err.response);
        console.log(`Error: ${err.response} `);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const deleteAppointment = async (
  patiientID,
  value,
  appointmentDeleteSuccessCallback,
  appointmentDeleteErrorCallback
) => {
  const data = {
    is_cancelled: true,
    appointment_status: "canceled",
    cancelation_reason: value,
  };
  try {
    await axiosInstance
      .patch(`/api/soap/appointment-detail/${patiientID}/detail/`, data)
      .then((res) => {
        appointmentDeleteSuccessCallback();
      })
      .catch((err) => {
        appointmentDeleteErrorCallback(err.response);
        console.log(`Error: ${err.response} `);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getUpcomingAppointmnet = (
  id,
  clinicId,
  getSuccessCallback,
  getErrorCallback
) => {
  try {
    axiosInstance
      .get(`/api/soap/patient/upcoming-appointments/${id}/${clinicId}`)
      .then((res) => {
        getSuccessCallback(res.data);
      })
      .catch((err) => {
        getErrorCallback();
        console.log(`Error: ${err.response} `);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getPatientDetail = (id, getSuccessCallback) => {
  try {
    axiosInstance
      .get(`/api/patients/patient/${id}/`)
      .then((res) => {
        getSuccessCallback(res.data);
      })
      .catch((err) => {
        console.log(`Error: ${err.response} `);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getAppointmentPrescriptions = (
  id,
  getSuccessCallback,
  getErrorCallback
) => {
  try {
    axiosInstance
      .get(`/api/soap/prescriptions/appointment/${id}/`)
      .then((res) => {
        getSuccessCallback(res.data);
      })
      .catch((err) => {
        console.log(`Error: ${err.response} `);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getAllPrescriptions = (
  id,
  getSuccessCallback,
  getErrorCallback
) => {
  try {
    axiosInstance
      .get(`/api/soap/all-soap-and-other-prescriptions/list/${id}/`)
      .then((res) => {
        getSuccessCallback(res.data);
      })
      .catch((err) => {
        console.log(`Error: ${err.response} `);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};
export const getAppointmentPlandDrugsAsPrescription = (
  id,
  getSuccessCallback,
  getErrorCallback
) => {
  try {
    axiosInstance
      .get(`/api/soap/soap-prescriptions/appointment/${id}/`)
      .then((res) => {
        getSuccessCallback(res.data);
      })
      .catch((err) => {
        console.log(`Error: ${err.response} `);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getASinglePrescription = (
  id,
  getSuccessCallback,
  getErrorCallback
) => {
  try {
    axiosInstance
      .get(`/api/soap/prescriptions/detail/${id}/`)
      .then((res) => {
        getSuccessCallback(res.data);
      })
      .catch((err) => {
        console.log(`Error: ${err.response} `);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};
export const getAppointmentPlanTests = (
  id,
  getSuccessCallback,
  getErrorCallback
) => {
  try {
    axiosInstance
      .get(`/api/soap/plan/tests/${id}/`)
      .then((res) => {
        getSuccessCallback(res.data);
      })
      .catch((err) => {
        console.log(`Error: ${err.response} `);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getAppointmentInvoiceList = (
  clientId,
  appointmentId,
  getSuccessCallback,
  getErrorCallback
) => {
  try {
    axiosInstance
      .get(`/payments/appointment-invoice-list/${clientId}/${appointmentId}/`)
      .then((res) => {
        getSuccessCallback(res.data);
      })
      .catch((err) => {
        console.log(`Error: ${err.response} `);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};
export const deleteAppointmentSoapDosage = (
  data,
  clinicId,
  getSuccessCallback,
  errorCallback,
  appointmentId
) => {
  try {
    axiosInstance
      .post(
        `/api/soap/plan/removes/remove_one_drug_from_soap/${clinicId}/${appointmentId}/`,
        data
      )
      .then((id) => {
        getSuccessCallback(id);
      })
      .catch((err) => {
        errorCallback();
        console.log(`Error: ${err.response} `);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};
export const deleteAppointmentSoapDrug = (
  data,
  clinicId,
  getSuccessCallback,
  errorCallback,
  appointmentId
) => {
  try {
    axiosInstance
      .post(
        `/api/soap/plan/removes/remove_drug_from_soap/${clinicId}/${appointmentId}/`,
        data
      )
      .then((id) => {
        getSuccessCallback(id);
      })
      .catch((err) => {
        errorCallback();
        console.log(`Error: ${err.response} `);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const deleteAppointmentSoapProduct = (
  data,
  clinicId,
  getSuccessCallback,
  errorCallback,
  appointmentId
) => {
  try {
    axiosInstance
      .post(
        `/api/soap/plan/removes/remove_product_from_soap/${clinicId}/${appointmentId}/`,
        data
      )
      .then((res) => {
        getSuccessCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(`Error: ${err.response} `);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const deleteAppointmentSoapService = (
  data,
  clinicId,
  getSuccessCallback,
  errorCallback,
  appointmentId
) => {
  try {
    axiosInstance
      .post(
        `/api/soap/plan/removes/remove_service_from_soap/${clinicId}/${appointmentId}/`,
        data
      )
      .then((res) => {
        getSuccessCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(`Error: ${err.response} `);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const deleteAppointmentSoapTest = (
  data,
  clinicId,
  getSuccessCallback,
  errorCallback,
  appointmentId
) => {
  try {
    axiosInstance
      .post(
        `/api/soap/plan/removes/remove_test_from_soap/${clinicId}/${appointmentId}/`,
        data
      )
      .then((res) => {
        getSuccessCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(`Error: ${err.response} `);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getAppointmentSoap = (id, getSuccessCallback) => {
  try {
    axiosInstance
      .get(`/api/soap/soap/${id}/`)
      .then((res) => {
        getSuccessCallback(res.data);
      })
      .catch((err) => {
        console.log(`Error: ${err.response} `);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getCustomerPayments = (
  getSuccessCallback,
  clinic_id,
  page,
  tablePage
) => {
  let pageLink = `/payments/customer_payment/${clinic_id}/`;
  if (tablePage && page) {
    pageLink = `${pageLink}?limit=${PAGE_LIMIT}&offset=${page}`;
  } else if (tablePage) {
    pageLink = `${pageLink}?limit=${PAGE_LIMIT}`;
  }
  try {
    axiosInstance
      .get(pageLink)
      .then((res) => {
        getSuccessCallback(res.data);
      })
      .catch((err) => {
        console.log(`Error: ${err.response} `);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getPaymentDetail = (id, getSuccessCallback) => {
  try {
    axiosInstance
      .get(`/payments/${id}/`)
      .then((res) => {
        getSuccessCallback(res.data);
      })
      .catch((err) => {
        console.log(`Error: ${err.response} `);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

/**
 * Gets the list of appointments of a paticular patient
 * @param {*} id ID of the Patient
 * @param {*} appointmentsSuccessCallback
 * @param {*} clinic_id The Clinic ID of the clinic you are currently logged in into
 */
export const getPatientAppointments = (
  id,
  appointmentsSuccessCallback,
  clinic_id
) => {
  try {
    axiosInstance
      .get(`/api/soap/patient/appointment/${id}/${clinic_id}/`)
      .then((res) => {
        appointmentsSuccessCallback(res.data);
      })
      .catch((err) => {
        console.log("could not get appointments");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};
export const getClinicDashboardAppointments = (
  appointmentsSuccessCallback,
  appointmentsErrorCallback,
  clinicId
) => {
  let pageLink = `/api/soap/appointment-clinic-dashboard/${clinicId}/`;

  try {
    axiosInstance
      .get(pageLink)
      .then((res) => {
        // console.log(res.data)
        appointmentsSuccessCallback(res.data);
      })
      .catch((err) => {
        appointmentsErrorCallback();
        console.log(err.response);
        console.log("could not get appointments");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getClinicPatientsAppointments = (
  appointmentsSuccessCallback,
  appointmentsErrorCallback,
  clinicId,
  page,
  tablePage
) => {
  let pageLink = `/api/soap/appointment/${clinicId}/`;
  if (tablePage && page) {
    pageLink = `${pageLink}?limit=${PAGE_LIMIT}&offset=${page}`;
  } else if (tablePage) {
    pageLink = `${pageLink}?limit=${PAGE_LIMIT}`;
  }
  try {
    axiosInstance
      .get(pageLink)
      .then((res) => {
        // console.log(res.data)
        appointmentsSuccessCallback(res.data);
      })
      .catch((err) => {
        appointmentsErrorCallback();
        console.log(err.response);
        console.log("could not get appointments");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};
export const getClinicDashboardStats = (
  id,
  statsSuccessCallback,
  statsErrorCallback
) => {
  try {
    axiosInstance
      .get(`/users/dashboard_stats/${id}/`)
      .then((res) => {
        // console.log(res.data)
        statsSuccessCallback(res.data);
      })
      .catch((err) => {
        statsErrorCallback();
        console.log(err.response);
        console.log("could not get stats");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};
/**
 * if tablePage is passed in the page will be paginated
 * the page indicates the index to offset
 */
export const getClinicStaffList = (
  successCallback,
  errorCallback,
  clinic_id,
  page,
  tablePage
) => {
  let pageLink = `/users/staff/list/${clinic_id}/`;
  if (tablePage && page) {
    pageLink = `${pageLink}?limit=${PAGE_LIMIT}&offset=${page}`;
  } else if (tablePage) {
    pageLink = `${pageLink}?limit=${PAGE_LIMIT}`;
  }
  try {
    axiosInstance
      .get(pageLink)
      .then((res) => {
        // console.log(res.data)
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get staff");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};
export const getClinicStaffDetail = (
  id,
  successCallback,
  errorCallback,
  date
) => {
  const link = date
    ? `/users/staff-date-caller/${id}/dated-caller/${date}/`
    : `/users/staff/${id}/`;
  try {
    axiosInstance
      .get(link)
      .then((res) => {
        // console.log(res.data)
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get staff");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const deleteClinicStaffDetail = (
  id,
  successCallback,
  errorCallback,
  date
) => {
  const link = date
    ? `/users/staff-date-caller/${id}/dated-caller/${date}/`
    : `/users/staff/${id}/`;
  try {
    axiosInstance
      .delete(link)
      .then((id) => {
        successCallback(id);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get staff");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};
export const getClinicAuthenticatedStaffDetail = (
  successCallback,
  errorCallback
) => {
  try {
    axiosInstance
      .get(`/users/staff/authenticated_staff/authenticate/`)
      .then((res) => {
        // console.log(res.data)
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get staff");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getStaffPaysliDetails = (
  successCallback,
  errorCallback,
  clinicId,
  staffId
) => {
  try {
    axiosInstance
      .get(`/api/payroll/payslip/staff_list/${clinicId}/${staffId}/`)
      .then((res) => {
        // console.log(res.data)
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get staff");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getClinicAppointmentsByDate = (
  date,
  dateType,
  clinic_id,
  successCallback,
  errorCallback
) => {
  const data = {
    clinic: clinic_id,
    date: date,
    date_type: dateType,
  };
  try {
    axiosInstance
      .post(`/api/soap/appointment-filter/filter_by_date/general/`, data)
      .then((res) => {
        // console.log(res.data)
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get appointments");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getClinicAdmittedAppointmentsByDate = (
  date,
  dateType,
  clinic,
  successCallback,
  errorCallback
) => {
  const data = {
    clinic: clinic,
    date: date,
    date_type: dateType,
  };
  try {
    axiosInstance
      .post(`/api/soap/appointment-filter/filter_by_date/admitted/`, data)
      .then((res) => {
        // console.log(res.data)
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get appointments");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getClinicClientsMiniList = (
  successCallback,
  errorCallback,
  clinic_id
) => {
  try {
    axiosInstance
      .get(`/users/client_full_details/${clinic_id}/`)
      .then((res) => {
        // console.log(res.data)
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get appointments");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getClinicClientsMiniMiniList = (
  successCallback,
  errorCallback,
  clinic_id,

  page,
  tablePage
) => {
  let pageLink = `/users/client_mini_list/${clinic_id}/`;
  if (tablePage && page) {
    pageLink = `${pageLink}?limit=${PAGE_LIMIT}&offset=${page}`;
  } else if (tablePage) {
    pageLink = `${pageLink}?limit=${PAGE_LIMIT}`;
  }
  try {
    axiosInstance
      .get(pageLink)
      .then((res) => {
        // console.log(res.data)
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get client");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const createSOAPSubjective = (
  values,
  successCallback,
  errorCallback
) => {
  const data = {
    appointment: values.appointment,
    initial_complaint: values.initialComplaints,
    description: values.description,
  };
  console.log(data);
  try {
    axiosInstance
      .post(`/api/soap/subjective_create/`, data)
      .then((res) => {
        // console.log(res.data)
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get appointments");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};
export const updateSOAPSubjective = (
  values,
  successCallback,
  errorCallback
) => {
  const data = {
    appointment: values.appointment,
    initial_complaint: values.initialComplaints,
    description: values.description,
  };
  try {
    axiosInstance
      .patch(`/api/soap/subjective_update/${values.id}/`, data)
      .then((res) => {
        // console.log(res.data)
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get appointments");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const createSOAPObjective = (values, successCallback, errorCallback) => {
  try {
    axiosInstance
      .post(`/api/soap/objective_create/`, values)
      .then((res) => {
        // console.log(res.data)
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get appointments");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};
export const updateSOAPObjective = (values, successCallback, errorCallback) => {
  try {
    axiosInstance
      .post(`/api/soap/objective_update/${values.id}/`, values)
      .then((res) => {
        // console.log(res.data)
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get appointments");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};
export const createSOAPAssessment = (
  values,
  successCallback,
  errorCallback
) => {
  try {
    axiosInstance
      .post(`/api/soap/assessment_create/`, values)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get appointments");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};
export const updateSOAPAssessment = (
  values,
  successCallback,
  errorCallback
) => {
  try {
    axiosInstance
      .post(`/api/soap/assessment_update/${values.id}/`, values)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get appointments");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const createSOAPPlan = (values, successCallback, errorCallback) => {
  try {
    axiosInstance
      .post(`/api/soap/plan_create/`, values)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not create soap plan");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};
export const updateSOAPPlan = (values, successCallback, errorCallback) => {
  try {
    axiosInstance
      .post(`/api/soap/plan_update/${values.plan_id}/`, values)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not update soap plan");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getClinicLabTestSample = (
  successCallback,
  errorCallback,
  clinic_id
) => {
  try {
    axiosInstance
      .get(`/api/lab/${clinic_id}/test/sample/`)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not create tests samples");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getClinicSOAPSubjectiveComplaintList = (
  successCallback,
  errorCallback,
  clinic_id
) => {
  try {
    axiosInstance
      .get(`/api/app_settings/${clinic_id}/soap_complaints/`)
      .then((res) => {
        // console.log(res.data)
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get appointments");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};
export const getClinicSOAPObjectiveVitalFindingList = (
  successCallback,
  errorCallback,
  clinic_id
) => {
  try {
    axiosInstance
      .get(`/api/app_settings/${clinic_id}/soap_vital_findings/`)
      .then((res) => {
        // console.log(res.data)
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get appointments");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const deleteVitalFindings = (successCallback, errorCallback, id) => {
  try {
    axiosInstance
      .delete(`/api/soap/vital_findings_delete/${id}`)
      .then((res) => {
        // console.log(res.data)
        successCallback(id);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get appointments");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const deleteDiagnosis = (successCallback, errorCallback, id) => {
  try {
    axiosInstance
      .delete(`/api/soap/diagnosis_delete/${id}`)
      .then((res) => {
        // console.log(res.data)
        successCallback(id);
      })
      .catch((err) => {
        console.log(err.response);
        console.log("could not get appointments");
      });
  } catch (err) {
    errorCallback();
    console.log(`Error: ${err.response} `);
  }
};

export const getClinicDrugs = (successCallback, errorCallback) => {
  try {
    axiosInstance
      .get(`api/inventory/drugs/list/`)
      .then((res) => {
        // console.log(res.data)
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get appointments");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};
export const getClinicServices = (
  successCallback,
  errorCallback,
  clinic_id
) => {
  try {
    axiosInstance
      .get(`api/inventory/services/list/${clinic_id}/`)
      .then((res) => {
        // console.log(res.data)
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get appointments");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};
export const getClinicProducts = (successCallback, errorCallback) => {
  try {
    axiosInstance
      .get(`api/inventory/products/list/`)
      .then((res) => {
        // console.log(res.data)
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get appointments");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const createStaffEducation = (
  values,
  staff,
  successCallback,
  errorCallback
) => {
  const data = {
    staff: staff,
    institution: values.institution,
    course: values.course,
    start_date: values.start_date,
    end_date: values.start_date,
    description: values.description,
  };
  try {
    axiosInstance
      .post(`/users/staff-education/education/`, data)
      .then((res) => {
        // console.log(res.data)
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not create staff education");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};
export const updateStaffEducation = (
  id,
  values,
  successCallback,
  errorCallback
) => {
  const data = {
    institution: values.institution,
    course: values.course,
    start_date: values.start_date,
    end_date: values.start_date,
    description: values.description,
  };
  console.log(values);
  try {
    axiosInstance
      .patch(`/users/staff-education/education/${id}/`, data)
      .then((res) => {
        // console.log(res.data)
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not update staff education");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};
export const deleteStaffEducation = (id, successCallback, errorCallback) => {
  try {
    axiosInstance
      .delete(`/users/staff/education/${id}/`)
      .then((res) => {
        // console.log(res.data)
        successCallback();
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not update staff education");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const handleStaffInfoUpdate = (
  id,
  values,
  handleSuccessAction,
  handleErrorAction
) => {
  try {
    axiosInstance
      .patch(`/users/staff/${id}/`, values, getImagePostHeader)
      .then((res) => {
        handleSuccessAction(res.data);
      })
      .catch((err) => {
        console.log(`Error: ${err} `);
        handleErrorAction();
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const handleStaffCreate = (
  data,
  handleSuccessAction,
  handleErrorAction,
  clinic_id,
  hostName
) => {
  let link = hostName
    ? `/users/staff/list/${clinic_id}/${hostName}/`
    : `/users/staff/list/${clinic_id}/`;
  try {
    axiosInstance
      .post(link, data)
      .then((res) => {
        handleSuccessAction(res.data);
      })
      .catch((err) => {
        console.log(err);
        handleErrorAction();
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};
export const handleStaffDepartmentUpdate = (
  values,
  handleSuccessAction,
  handleErrorAction
) => {
  const data = {
    user_title: values.title,
    description: values.description,
    role: values.role,
    department: values.department,
  };
  try {
    axiosInstance
      .patch(`/users/staff/${values.staff}/`, data)
      .then((res) => {
        handleSuccessAction(res.data);
      })
      .catch((err) => {
        console.log(`Error: ${err} `);
        handleErrorAction();
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getClinicCustomerList = (
  successCallback,
  errorCallback,
  clinic_id,
  page,
  tablePage
) => {
  let pageLink = `/users/client_full_details/${clinic_id}/`;
  if (tablePage && page) {
    pageLink = `/users/client_full_details/${clinic_id}/?limit=${PAGE_LIMIT}&offset=${page}`;
  } else if (tablePage) {
    pageLink = `/users/client_full_details/${clinic_id}/?limit=${PAGE_LIMIT}`;
  }
  try {
    axiosInstance
      .get(pageLink)
      .then((res) => {
        // console.log(res.data)
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get customers");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getClinicPatientsList = (
  successCallback,
  errorCallback,
  clinic_id,
  page,
  tablePage
) => {
  let pageLink = `/api/patients/patient/list/${clinic_id}/`;
  if (tablePage && page) {
    pageLink = `/api/patients/patient/list/${clinic_id}/?limit=${PAGE_LIMIT}&offset=${page}`;
  } else if (tablePage) {
    pageLink = `/api/patients/patient/list/${clinic_id}/?limit=${PAGE_LIMIT}`;
  }
  try {
    axiosInstance
      .get(pageLink)
      .then((res) => {
        // console.log(res.data)
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get patients");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getPayrollList = (
  successCallback,
  errorCallback,
  clinic_id,
  page,
  tablePage
) => {
  let pageLink = `/api/payroll/${clinic_id}/`;
  if (tablePage && page) {
    pageLink = `/api/payroll/${clinic_id}/?limit=${PAGE_LIMIT}&offset=${page}`;
  } else if (tablePage) {
    pageLink = `/api/payroll/${clinic_id}/?limit=${PAGE_LIMIT}`;
  }
  try {
    axiosInstance
      .get(pageLink)
      .then((res) => {
        console.log(res.data);
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get payroll");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const handleTaskCreate = (
  values,
  handleSuccessAction,
  handleErrorAction
) => {
  try {
    axiosInstance
      .post(`/api/tasks/`, values)
      .then((res) => {
        handleSuccessAction(res.data);
      })
      .catch((err) => {
        console.log(`Error: ${err} `);
        handleErrorAction();
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};
export const handleTaskUpdate = async (
  values,
  id,
  handleSuccessAction,
  handleErrorAction
) => {
  try {
    const res = await axiosInstance.patch(`/api/tasks/${id}/`, values);
    handleSuccessAction(res.data);
  } catch (err) {
    console.log(`Error: ${err.response} `);
    handleErrorAction();
  }
};

export const getTaskAssignedByMe = (
  successCallback,
  errorCallback,
  page,
  tablePage
) => {
  let pageLink = `/api/tasks/by_me/`;
  if (tablePage && page) {
    pageLink = `/api/tasks/by_me/?limit=${PAGE_LIMIT}&offset=${page}`;
  } else if (tablePage) {
    pageLink = `/api/tasks/by_me/?limit=${PAGE_LIMIT}`;
  }

  try {
    axiosInstance
      .get(pageLink)
      .then((res) => {
        console.log(res.data);
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get tasks");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getTaskAssignedToMe = (
  successCallback,
  errorCallback,
  page,
  tablePage
) => {
  let pageLink = `/api/tasks/to_me`;
  if (tablePage && page) {
    pageLink = `/api/tasks/to_me/?limit=${PAGE_LIMIT}&offset=${page}`;
  } else if (tablePage) {
    pageLink = `/api/tasks/to_me/?limit=${PAGE_LIMIT}`;
  }
  try {
    axiosInstance
      .get(pageLink)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get tasks");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getTaskDetails = (id, successCallback, errorCallback) => {
  try {
    axiosInstance
      .get(`/api/tasks/${id}/`)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get task");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const handleTaskAttachmentFile = async (
  data,
  handleSuccessAction,
  handleErrorAction,
  handleUploadProgress
) => {
  console.log(data);
  try {
    await axiosInstance
      .post(
        `/api/tasks/task/attachments/`,
        data,
        headerFileSpecial(handleUploadProgress)
      )
      .then((res) => {
        handleSuccessAction(res.data);
      })
      .catch((err) => {
        console.log(err);
        handleErrorAction(err.response);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const handleTaskAttachmentFileUpdate = async (
  id,
  data,
  handleSuccessAction,
  handleErrorAction
) => {
  try {
    await axiosInstance
      .patch(`/api/tasks/task/attachments/${id}/`, data)
      .then((res) => {
        handleSuccessAction(res.data);
      })
      .catch((err) => {
        console.log(err);
        handleErrorAction(err.response);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const deleteTaskDetails = (id, successCallback, errorCallback) => {
  try {
    axiosInstance
      .delete(`/api/tasks/${id}/`)
      .then((res) => {
        successCallback(res.id);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not delete task");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};
export const handleTaskAttachmentFileDelete = async (
  id,
  handleSuccessAction,
  handleErrorAction
) => {
  try {
    await axiosInstance
      .delete(`/api/tasks/attachments/${id}/`)
      .then((res) => {
        handleSuccessAction();
      })
      .catch((err) => {
        console.log(err);
        handleErrorAction(err.response);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const addPayroll = (
  values,
  successCallback,
  errorCallback,
  clinic_id
) => {
  const data = {
    staff: values.staff,
    working_hours: values.workingHours,
    bank_name: values.bankName,
    account_number: values.acctNumber,
    pay_day: values.payDay,
  };
  try {
    axiosInstance
      .post(`/api/payroll/${clinic_id}/`, data)
      .then((res) => {
        // console.log(res.data)
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not create payroll");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getTaskAttachment = (successCallback, errorCallback, id) => {
  let pageLink = `/api/tasks/task/attachments/${id}/`;
  try {
    axiosInstance
      .get(pageLink)
      .then((res) => {
        console.log(res.data);
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get payslip");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getPaySlipList = (
  successCallback,
  errorCallback,
  clinic_id,
  page,
  tablePage
) => {
  let pageLink = `/api/payroll/payslip/${clinic_id}/`;
  if (tablePage && page) {
    pageLink = `${pageLink}?limit=${PAGE_LIMIT}&offset=${page}`;
  } else if (tablePage) {
    pageLink = `${pageLink}?limit=${PAGE_LIMIT}`;
  }
  try {
    axiosInstance
      .get(pageLink)
      .then((res) => {
        console.log(res.data);
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get payslip");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};
