import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import { getNotifications } from "../../api/Notification/NotificationsAPI";
import ClinicAuthContext from "../../context/clinicAuthContext";
import { imageLinkReformat } from "../../features/imageLinkReformat";
import { PaperProgress } from "../../component/ProgressIndicators/PageProgress";
import capitalizeFirstLetter from "../../features/capitalizeFirstLetter";
import { timeSince } from "../../features/readableDate";
import { generatePath, useNavigate } from "react-router-dom";
import { CLINIC_LINKS } from "../../links/clinicLinks";

const NotificationItems = (NotificationLength) => {
  const navigate = useNavigate();
  const { clinic } = React.useContext(ClinicAuthContext);
  const [notifications, setNotifications] = React.useState(null);
  const successCallBack = (data) => {
    setNotifications(data);
  };
  const errorCallBack = () => {};

  const handleClick = (id) => {
    navigate(generatePath(CLINIC_LINKS.NOTIFICATION_DETAILS, { id: id }));
  };
  React.useEffect(() => {
    getNotifications(clinic.id, successCallBack, errorCallBack);
  }, [clinic.id]);
  return (
    <Box>
      {notifications ? (
        notifications.length > 0 ? (
          notifications.map((notification) => (
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  px: 2,
                  py: 2,
                  borderBottom: "1px dashed #CDD1D3",
                  cursor: "pointer",
                }}
                onClick={() => handleClick(notification.id)}
              >
                <Box
                  sx={{
                    paddingLeft: { xs: "18px", md: "58px" },
                    paddingRight: { xs: "6px", md: "16px" },
                  }}
                >
                  <Box></Box>
                  {notification.notified.image && (
                    <img
                      src={imageLinkReformat(notification.notified.image)}
                      alt={notification.notified.first_name}
                      style={{
                        width: "64px",
                        height: "64px",
                        borderRadius: "50%",
                      }}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    width: "70%",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontSize: "16px",
                      color: "#071A23",
                      lineHeight: "24px",
                    }}
                  >
                    {capitalizeFirstLetter(notification.notified.first_name)}{" "}
                    {capitalizeFirstLetter(notification.notified.last_name)}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: "14px",
                      color: "#203139",
                      lineHeight: "165%",
                    }}
                  >
                    {capitalizeFirstLetter(notification.subject)}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: "14px",
                      color: "#203139",
                      lineHeight: "165%",
                    }}
                  >
                    {capitalizeFirstLetter(notification.message)}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    // alignItems:'flex-end',
                    justifyContent: "flex-end",
                    color: "#838C91",
                    width: "104px",
                  }}
                >
                  {timeSince(notification.timestamp)}
                </Box>
              </Box>
            </Box>
          ))
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: 3,
            }}
          >
            <Typography>Hello! You have no notification here yet.</Typography>
          </Box>
        )
      ) : (
        <PaperProgress />
      )}
    </Box>
  );
};

export default NotificationItems;
