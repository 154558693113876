import React from "react";
import Notifications from "@mui/icons-material/Notifications";
import { Box, Fade, IconButton, Paper, Popper } from "@mui/material";
import PopupState, { bindToggle, bindPopper } from "material-ui-popup-state";

import Notification from "../../assets/dasboardAssets/Notification.png";

import NotificationMenu from "../../scomponents/main/NotificationMenu";

const NotificationDropdown = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <PopupState variant="popper" popupId="demo-popup-popper">
      {(popupState) => (
        <div>
          <IconButton onClick={handleClick}>
            <img
              src={Notification}
              alt="notification"
              style={{ paddingRight: "16px", cursor: "pointer" }}
            />
          </IconButton>

          <Popper {...bindPopper(popupState)} transition>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper
                  sx={{
                    mt: 2,
                    mr: 3,
                    p: 3,
                    width: { md: "500px" },
                    height: { md: "320px" },
                  }}
                >
                  <Box></Box>
                </Paper>
              </Fade>
            )}
          </Popper>
          <NotificationMenu open={open} onClose={handleClose} />
        </div>
      )}
    </PopupState>
  );
};

export default NotificationDropdown;
