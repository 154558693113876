import { Box, Modal } from '@mui/material'
import CloseIcon from "@mui/icons-material/Close";
import React from 'react'
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: "0px 24px 32px rgba(171, 171, 171, 0.16)",
    borderRadius: "16px",
  };
const ShowDocumentModal = ({open, onClose, file}) => {
  return (
    <div>

<Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            onClick={onClose}
            sx={{
              position: "absolute",
              top: "24px",
              right: "24px",
              cursor: "pointer",
            }}
          >
            <CloseIcon />
          </Box>
          {file?  <Box>
           <img src={file.attachment} alt={file.title} style={{width:"100%", height:"75vh", borderRadius: "16px",}} />
         </Box>:""}
         
        </Box>
      </Modal>
    </div>
  )
}

export default ShowDocumentModal