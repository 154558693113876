import Notifications from "@mui/icons-material/Notifications";
import { Badge, IconButton } from "@mui/material";
import PopupState, { bindToggle, bindPopper } from "material-ui-popup-state";
import Notification from "../../assets/Clinic/Dashboard/Notification.png";
import NotificationMenu from "./NotificationMenu";
import React from "react";
import { getNotifications } from "../../api/Notification/NotificationsAPI";
import ClinicAuthContext from "../../context/clinicAuthContext";

const NotificationDropdown = () => {
  const { clinic } = React.useContext(ClinicAuthContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notifications, setNotifications] = React.useState(null);
  const [viewedNotifications, setViewedNotifications] = React.useState(false);

  const open = Boolean(anchorEl);
  const id = open ? "user-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setViewedNotifications(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const successCallBack = (data) => {
    setNotifications(data);
    if (!viewedNotifications) {
      setViewedNotifications(false); // Mark notifications as viewed initially
    }
  };
  const errorCallBack = () => {};
  React.useEffect(() => {
    getNotifications(clinic.id, successCallBack, errorCallBack);
  }, [clinic.id]);
  return (
    <PopupState variant="popper" popupId="demo-popup-popper">
      {(popupState) => (
        <div>
          <IconButton
            sx={{
              backgroundColor: "backgroundTypes.buttonNew",
              mx: 1,
            }}
            onClick={handleClick}
          >
            <Badge
              badgeContent={
                viewedNotifications ? 0 : notifications && notifications.length
              }
              color="error"
            >
              <img
                style={{ height: "38px", width: "38px" }}
                src={Notification}
                alt="icon"
              />
            </Badge>
          </IconButton>

          <NotificationMenu open={open} onClose={handleClose} />
        </div>
      )}
    </PopupState>
  );
};

export default NotificationDropdown;
