import { Grid, Box, Typography, Button, useMediaQuery } from "@mui/material";
import React from "react";
import Error from "../../assets/avatars/error404.png";
import { CLINIC_LINKS } from "../../links/clinicLinks";
import { useNavigate } from "react-router-dom";
import DefaultLayout from "../../component/Clinic/DefaultLayout/ClinicDefaultLayout";
import { CLIENT_LINKS } from "../../links/clientLinks";
import { CLINIC_SUPER_LINKS } from "../../links/clinicSuperLinks";
import { APP_CENTER_LINKS } from "../../links/appCenterLinks";

const Error404Page = () => {
  const navigate = useNavigate();
  const backToHomepage = () => {
    if (window.location.pathname.includes("/vapp/")) {
      navigate(CLINIC_LINKS.DASHBOARD);
    } else if (window.location.pathname.includes("/vclients/")) {
      navigate(CLIENT_LINKS.DASHBOARD);
    } else if (window.location.pathname.includes("/vapp_super/")) {
      navigate(CLINIC_SUPER_LINKS.DASHBOARD);
    } else if (window.location.pathname.includes("/app_center/")) {
      navigate(APP_CENTER_LINKS.DASHBOARD);
    } else {
      navigate("/");
    }
  };

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Grid
      xs={12}
      sx={{
        marginX: "24px",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        flexDirection: { xs: "column", sm: "row" },
      }}
    >
      <Box sx={{ marginTop: { xs: "110px" } }}>
        <img src={Error} alt="error page" />
      </Box>

      <Box sx={{ marginTop: { xs: "57px" } }}>
        <Typography
          sx={{
            fontSize: "40px",
            fontWeight: 700,
            textAlign: "center",
            color: "#33B1F1",
          }}
        >
          Oops!
        </Typography>
        <Typography
          sx={{
            paddingTop: "24px",
            fontSize: "20px",
            fontWeight: 400,
            textAlign: "center",
            color: "#33B1F1",
          }}
        >
          Page Not Found
        </Typography>

        {!isMobile && (
          <Button
            onClick={backToHomepage}
            variant="contained"
            sx={{
              paddingX: "40px",
              paddingY: "16px",
              width: "22rem",
              borderRadius: "8px",
              marginTop: "24px",
            }}
          >
            {" "}
            Back to Homepage
          </Button>
        )}
      </Box>

      {isMobile && (
        <Box sx={{ marginTop: { xs: "68px" } }}>
          <Button
            onClick={backToHomepage}
            variant="contained"
            sx={{
              paddingX: "40px",
              paddingY: "16px",
              width: "22rem",
              borderRadius: "8px",
            }}
          >
            {" "}
            Back to Homepage
          </Button>
        </Box>
      )}
    </Grid>
  );
};

export default Error404Page;
