export const detectImageTypes = (filename) => {
  const extension = filename.split(".").pop().toLowerCase();

  // Set the file type based on the file extension
  if (extension === "pdf") {
    return "PDF";
  } else if (
    extension === "doc" ||
    extension === "docs" ||
    extension === "docx" ||
    extension === "txt"
  ) {
    return "Document";
  } else if (extension === "xlsx" || extension === "xls") {
    return "Excel";
  } else if (extension === "png") {
    return "Image";
  } else if (extension === "jpg" || extension === "jpeg") {
    return "Image";
  } else {
    return "Unknown File Type";
  }
};
