import { Box, Grid, Typography, Button } from "@mui/material";
import React from "react";

import SmartdvmLogo from "../../../assets/logo.png";
import HeroPic from "../../../assets/Clinic/Registration/heropic.png";

import Blob from "../../../assets/Clinic/Registration/Blob.png";
import { useNavigate } from "react-router-dom";
import LandingPopModal from "./LandingPopModal";

const HeroLandingPage = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const handleToggleModal = () => {
    setOpen(!open);
  };
  return (
    <Grid xs={12} sx={{ backgroundColor: "#F2FAFE", height: "100vh" }}>
      <Box>
        <img
          src={SmartdvmLogo}
          alt="smartdvm-logo||uploaded logo"
          style={{
            marginTop: "12px",
            marginLeft: "24px",
            maxHeight: "6em",
            maxWidth: "8em",
          }}
        />
      </Box>
      <Grid container xs={12}>
        <Grid xs={6} item>
          <Box
            sx={{
              backgroundImage: `url(${Blob})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "bottom center",
              backgroundSize: "17rem",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: "3rem",
            }}
          >
            <img src={HeroPic} alt="hero" style={{ height: "70vh" }} />
          </Box>
        </Grid>
        <Grid
          xs={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "space-betwen",
          }}
          item
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography sx={{ fontWeight: 800, fontSize: "48px" }}>
              Access Your<br></br>
              Account<br></br>
              <span style={{ color: "#009EED" }}>Anywhere</span>
            </Typography>
            <Typography sx={{ color: "#b1aaaa", paddingTop: "1rem" }}>
              SmartDVM keeps veterinary professionals and<br></br> pets owners
              connected, enabling seamless<br></br>coordination to prioritize
              pet health and wellness
            </Typography>
            <Box>
              <Button
                variant="contained"
                sx={{
                  mt: 5,
                  paddingY: "14px",
                  width: "18ch",
                  borderRadius: "10px",
                }}
                onClick={handleToggleModal}
              >
                Sign in
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <LandingPopModal open={open} onClose={handleToggleModal} />
    </Grid>
  );
};

export default HeroLandingPage;
