import AccountCircleOutlined from "@mui/icons-material/AccountCircleOutlined";
import FolderOpenOutlined from "@mui/icons-material/FolderOpenOutlined";
import LocalPhoneOutlined from "@mui/icons-material/LocalPhoneOutlined";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../api/axios/post";
import ClientRegMainContainer from "../../../scomponents/client/ClientRegMainContainer";
import ClinicRegSide from "../../../scomponents/clinic/ClinicRegSide";
import Title from "../../../scomponents/utils/Title";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import UserAdornment from "../../../assets/Clinic/Registration/User.png";
import EmailAdornment from "../../../assets/Clinic/Registration/Email.png";
import CardAdornment from "../../../assets/Clinic/Registration/IDCard.png";
import { CLINIC_LINKS } from "../../../links/clinicLinks";

const BioRegister = () => {
  const navigate = useNavigate();
  // const [gender, setGender] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [employee_id, setEmployeeID] = useState("");
  const [dob, setDob] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip_code, setZipCode] = useState("");
  const handleSubmit = (event) => {
    event.preventDefault();

    try {
      axiosInstance
        .post("/users/full_details/", {
          full_name: `${first_name} ${last_name}`,
          phone_number: phone_number,
          employee_id: employee_id,
          email: email,
          dob: dob,
          address: address,
          city: city,
          state: state,
          zip_code: zip_code,
        })
        .then((res) => {
          navigate(CLINIC_LINKS.CLINIC_REGISTER);
        });
    } catch (err) {
      if (err.response) {
        console.log(err.response.data);
        console.log(err.response.status);
        console.log(err.response.headers);
      } else {
        console.log(`Error: ${err.response} `);
      }
    }
  };

  return (
    <Grid container>
      <ClinicRegSide />
      <ClientRegMainContainer>
        <Box
          sx={{ display: "flex", flexWrap: "wrap" }}
          component="form"
          onSubmit={handleSubmit}
        >
          <Grid
            xs={12}
            sx={{
              paddingBottom: "56px",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontSize: "24px",
                display: "flex",
                fontWeight: "500",
                alignItems: "center",
                color: "#203139",
              }}
            >
              <ArrowBackIcon sx={{ paddingRight: "10px" }} />{" "}
              <span style={{ color: "#CDD1D3", paddingRight: "12px" }}>|</span>{" "}
              Register
            </Typography>
            <Title></Title>
          </Grid>
          <Box sx={{ mb: 1 }}>
            <Typography variant="subtitle2">Account Information</Typography>
          </Box>
          <Box sx={{ mb: 2 }}>
            <TextField
              label="First Name"
              required
              id="outlined-start-adornment"
              autoComplete="firstName"
              sx={{ m: 1, width: { xs: "40ch", sm: "30ch" } }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={UserAdornment} alt="icon" />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
            />
            <TextField
              label="Last Name"
              required
              id="outlined-start-adornment"
              sx={{ m: 1, width: { xs: "40ch", sm: "30ch" } }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={UserAdornment} alt="icon" />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
            />

            <TextField
              label="Enter Your Email"
              required
              id="outlined-start-adornment"
              sx={{ m: 1, width: { xs: "40ch", sm: "30ch" } }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={EmailAdornment} alt="icon" />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />

            <TextField
              label="Phone Number"
              autoComplete="phoneNumber"
              required
              id="outlined-start-adornment"
              sx={{ m: 1, width: { xs: "40ch", sm: "30ch" } }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocalPhoneOutlined />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
            />

            <TextField
              label="Employee ID"
              id="outlined-start-adornment"
              sx={{ m: 1, width: { xs: "40ch", sm: "30ch" } }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={CardAdornment} alt="icon" />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                setEmployeeID(e.target.value);
              }}
            />

            {/* <TextField
              labelId="demo-simple-select-label"
              select
              id="demo-simple-select"
              value={gender}
              label="Gender"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={UserAdornment} alt="icon" />
                  </InputAdornment>
                ),
              }}
              required
              onChange={(event) => {
                setGender(event.target.value);
              }}
              sx={{ m: 1, width: { xs: "40ch", sm: "30ch" } }}
            >
              <MenuItem value={10}>Male</MenuItem>
              <MenuItem value={20}>Female</MenuItem>
            </TextField> */}

            <TextField
              required
              type="date"
              id="outlined-start-adornment"
              sx={{ m: 1, width: { xs: "40ch", sm: "30ch" } }}
              onChange={(e) => {
                setDob(e.target.value);
              }}
              draggable
            />
          </Box>
          <Box sx={{ mb: 1 }}>
            <Typography variant="subtitle2">Address Information</Typography>
          </Box>
          <Box sx={{ mb: 2 }}>
            <TextField
              required
              label="State"
              id="outlined-start-adornment"
              sx={{ m: 1, width: { xs: "40ch", sm: "30ch" } }}
              onChange={(e) => {
                setState(e.target.value);
              }}
            />
            <TextField
              required
              label="City"
              id="outlined-start-adornment"
              sx={{ m: 1, width: { xs: "40ch", sm: "30ch" } }}
              onChange={(e) => {
                setCity(e.target.value);
              }}
            />
            <TextField
              required
              label="Street"
              id="outlined-start-adornment"
              sx={{ m: 1, width: { xs: "40ch", sm: "30ch" } }}
              onChange={(e) => {
                setAddress(e.target.value);
              }}
            />
            <TextField
              required
              label="Zip / Postal Code"
              id="outlined-start-adornment"
              sx={{ m: 1, width: { xs: "40ch", sm: "30ch" } }}
              onChange={(e) => {
                setZipCode(e.target.value);
              }}
            />
          </Box>
          <Box>
            <Button
              variant="contained"
              sx={{
                paddingX: "32px",
                paddingY: "13px",
                borderRadius: "8px",
                marginTop: { lg: "46px", md: "20px" },
                marginBottom: { lg: "188px", md: "20px" },
              }}
              type="submit"
            >
              Register Now
            </Button>
          </Box>
        </Box>
      </ClientRegMainContainer>
    </Grid>
  );
};

export default BioRegister;
