import { createTheme } from "@mui/material";

export default  createTheme({
  
    shadows:'none',
    components:{
      MuiButton:{
        styleOverrides:{
          root:({ theme }) => (
            {
            
            '&.MuiButton-containedPrimary:hover': {
                backgroundColor: theme.palette.primary.dark,
            },
            '&.MuiButton-containedSecondary:hover': {
                color: theme.palette.secondary.light,
            },
        }),
          
        }

      },
      MuiPaper:{
        styleOverrides:{
          root:({ theme }) => (
            {
              borderRadius:"16px",
        }),
       
        }
      }
    },

    typography: {
      fontFamily: [
        'Poppins',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      body1:{
        color:"#39484F",
      },
      body2:{
        color:"#39484F"
      },
      caption:{
        color:"#39484F"
      },
      subtitle2:{
        color:"#9CA3A7"
      },
      dashboard:{
        color: "#000000", 
        fontSize: "14px",
        fontWeight: "400"
      },
      dashboard2:{
        color: "#515F65", 
        fontSize: "14px",
        fontWeight: "400"
      },
      paragraph: {
        fontWeight: "500",
        fontSize: "20px" 
      },
      paragraph1: {
        fontWeight: "500",
        fontSize: "24px"
      },
      paragraph2: {
        fontWeight: "500",
        fontSize: "16px"
      },
      subdash:{
        color: "#39484F",
        fontSize: "14px",
        fontWeight: "400"
      },
      date: {
        color: "#838C91",
        fontSize: "14px",
        fontWeight: "400"
      },
      title: {
        color: "#071A23",
        fontWeight: "500",
        fontSize: "18px"
      }
      
    },
    
    palette: {
      primary: {
        light: '#757ce8',
        main: '#009EED',
        dark: '#4DBBF2',
        contrastText: '#fff',
        
      },
      
      secondary: {
        light: '#F8F8F8',
        main: '#F3F3F4;',
        dark: '#0079B6',
        contrastText: ' #009EED',
      },
      textGray:{
        main:"#838C91",
        main2:"#9CA3A7",
        sideNav:"#6A767B"
      },
      backgroundTypes:{
        main:"#ffffff",
        side1:"#F8F8F8",
        buttonNew:"#F3F3F4"
      },
      textColor:{
        main:"#203139",
        light:"#838C91"
      }

    },
  });