import { Alert, Box, Button } from "@mui/material";
import React, { createContext, useState } from "react";
import { extra_modules } from "../pages/smartdvm_app_center/Dashboard/Subscription/SubscriptionCreate";
import { contentTypesList } from "../component/Permissions/ContentTypesList";
import { CLINIC_LINKS } from "../links/clinicLinks";
import { useNavigate } from "react-router-dom";
import { CLINIC_SUPER_LINKS } from "../links/clinicSuperLinks";
import GeneralWarningModal from "../component/Clinic/General/Modals/WarningModal";

const ClinicAuthContext = createContext(null);
const EmptyComponent = () => {
  return <Box></Box>;
};
export const ClinicAuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [isLoggedin, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState({});
  const [userReg, setUserReg] = useState(true);
  const [clinic, setClinic] = useState({});
  const [clinicReg, setClinicReg] = useState(false);
  const [staff, setStaff] = useState({});
  const [textLabels, setTextLabels] = useState([]);
  const [money, setCurrency] = useState("&#8358;");
  const [activeBill, setActiveBill] = useState(null);
  const [warningModalOpen, setWarningModalOpen] = useState(false);

  const toggleWarningModal = () => setWarningModalOpen(!warningModalOpen);

  const goToSuperUserLogin = () => navigate(CLINIC_SUPER_LINKS.LOGIN);

  // functions
  const currency = () => {
    return <div dangerouslySetInnerHTML={{ __html: money }}></div>;
  };
  const sideNavExtraModViaAppLabelAndAppModel = (link, component) => {
    const extraModulesList = extra_modules.map((perm) => {
      let contentTypeMain = contentTypesList.find(
        (p) => p.app_label === perm.app_label && p.model === perm.model
      );
      if (contentTypeMain) {
        let perm_object = {
          ...perm,
          title: contentTypeMain.title,
          read_perm_links: contentTypeMain.read_perm_links,
          write_perm_links: contentTypeMain.write_perm_links,
        };
        if (!perm_object.title || perm_object.title === "") {
        } else {
          return perm_object;
        }
      }
    });
    const exactModule = extraModulesList.find(
      (item) =>
        item.read_perm_links.includes(link) ||
        item.write_perm_links.includes(link)
    );
    // console.log(exactModule);
    if (link === CLINIC_LINKS.DASHBOARD) {
      return component;
    } else if (!exactModule) {
      return component;
    } else if (clinic.clinic_plan && exactModule) {
      const findPerms =
        clinic.read_clinic_plan &&
        clinic.read_clinic_plan.read_extra_modules &&
        clinic.read_clinic_plan.read_extra_modules.find(
          (perm) =>
            perm &&
            perm.app_label === exactModule.app_label &&
            perm.model === exactModule.model
        );

      if (findPerms) {
        return component;
      } else {
        return EmptyComponent;
      }
    } else {
      return EmptyComponent;
    }
  };

  const clientCreateLimiter = (component) => {
    if (clinic.read_clinic_plan) {
      const number_of_clients = clinic.read_clinic_plan.number_of_client;

      if (number_of_clients > clinic.get_reg_staff_count) {
        return component;
      } else {
        return EmptyComponent;
      }
    }
  };

  const doctorCreateLimiter = (component, is_doctor) => {
    if (clinic.read_clinic_plan) {
      const number_of_doctors = clinic.read_clinic_plan.number_of_doctors;
      if (is_doctor) {
        return component;
      } else if (number_of_doctors > clinic.get_reg_staff_doctor_count) {
        // toggleWarningModal();
        return (
          <Box>
            {component}{" "}
            <Alert severity="warning" sx={{ mx: 2 }}>
              You have{" "}
              {parseInt(number_of_doctors) -
                parseInt(clinic.get_reg_staff_doctor_count)}{" "}
              doctors license left.
            </Alert>
            <GeneralWarningModal
              open={warningModalOpen}
              onClose={toggleWarningModal}
              warningText={`You have 
              ${
                parseInt(number_of_doctors) -
                parseInt(clinic.get_reg_staff_doctor_count)
              }
              doctors license left.`}
              actionText="I Understand"
            />
          </Box>
        );
      } else {
        // toggleWarningModal();
        return (
          <Box sx={{ mx: 5 }}>
            <Alert severity="error">
              You have exceeded your doctor's License.
            </Alert>
            <GeneralWarningModal
              open={warningModalOpen}
              onClose={toggleWarningModal}
              warningText={`You have exceeded your doctor's License. `}
              actionText="Upgrade"
            />
          </Box>
        );
      }
    }
  };

  /**
   * Limits the number of staff being created by the sub plan
   * @param {*} component
   * @returns compnent or an empty component
   */

  const staffCreateLimiter = (component) => {
    if (clinic.read_clinic_plan) {
      const number_of_staff = clinic.read_clinic_plan.number_of_staff;
      if (parseInt(number_of_staff) > parseInt(clinic.get_reg_staff_count)) {
        return component;
      } else {
        return (
          <Button variant="outlined" onClick={goToSuperUserLogin}>
            Upgrade Subscription
          </Button>
        );
      }
    }
  };

  const extraModViaAppLabelAndAppModel = (app_label, app_model, component) => {
    if (clinic.clinic_plan) {
      const findPerms =
        clinic.read_clinic_plan &&
        clinic.read_clinic_plan.read_extra_modules &&
        clinic.read_clinic_plan.read_extra_modules.find(
          (perm) =>
            perm && perm.app_label === app_label && perm.model === app_model
        );

      if (findPerms) {
        return component;
      } else {
        return EmptyComponent;
      }
    } else {
      return EmptyComponent;
    }
  };

  const subscriptionActive = () => {
    if (activeBill && activeBill.subscription && clinic.clinic_plan) {
      return "Active";
    } else if (clinic.clinc_plan || activeBill === null) {
      return "Inactive";
    }
  };

  const subscriptionTerminationDate = () =>
    activeBill && activeBill.termination_date;

  const authStaffFromClinic = () => {
    if (staff.clinic.id && clinic.id) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <ClinicAuthContext.Provider
      value={{
        isLoggedin,
        setIsLoggedIn,
        user,
        setUser,
        clinic,
        setClinic,
        staff,
        setStaff,
        userReg,
        setUserReg,
        clinicReg,
        setClinicReg,
        currency,
        setCurrency,
        textLabels,
        setTextLabels,
        extraModViaAppLabelAndAppModel,
        sideNavExtraModViaAppLabelAndAppModel,
        activeBill,
        setActiveBill,
        subscriptionActive,
        clientCreateLimiter,
        staffCreateLimiter,
        doctorCreateLimiter,
        subscriptionTerminationDate,
      }}
    >
      {children}
    </ClinicAuthContext.Provider>
  );
};
export default ClinicAuthContext;
