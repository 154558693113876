// program that converts the first letter of a string to uppercase
export default (string) => {
  // converting first letter  to uppercase
  if (string) {
    const capitalized =
      String(string).charAt(0).toUpperCase() + String(string).slice(1);
    return capitalized;
  } else {
    return "";
  }
};
