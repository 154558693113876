import axiosInstance from "../axios/post";

export const apiCreateAppLabel = (data, successCallback, errorCallback) => {
  try {
    axiosInstance
      .post(`/api/app_settings/app_label/`, data)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback(err);
      });
  } catch (error) {
    console.log(error);
  }
};

export const apiUpdateAppLabel = (id, data, successCallback, errorCallback) => {
  try {
    axiosInstance
      .patch(`/api/app_settings/app_label_detail/${id}/`, data)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback(err);
      });
  } catch (error) {
    console.log(error);
  }
};

export const apiCreateSubscription = (data, successCallback, errorCallback) => {
  try {
    axiosInstance
      .post(`/api/plan/list/`, data)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback(err);
      });
  } catch (error) {
    console.log(error);
  }
};

export const apiUpdateSubscription = (
  id,
  data,
  successCallback,
  errorCallback
) => {
  try {
    axiosInstance
      .patch(`/api/plan/detail/${id}/`, data)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback(err);
      });
  } catch (error) {
    console.log(error);
  }
};

export const apiClinicUpdate = (
  id,
  data,
  handleSuccessCallback,
  handleErrorCallback
) => {
  try {
    axiosInstance
      .patch(`/api/clinic/${id}/`, data)
      .then((res) => {
        handleSuccessCallback(res.data);
      })
      .catch((err) => {
        handleErrorCallback(err.response);
        console.log(`Error: ${err.response}`);
      });
  } catch (err) {
    handleErrorCallback(err.response);
  }
};

export const apiCreateAppCenterPaymentGateway = (
  data,
  successCallback,
  errorCallback
) => {
  try {
    axiosInstance
      .post(`/api/billing/payment-gateway/list/`, data)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback(err);
      });
  } catch (error) {
    console.log(error);
  }
};

export const apiUpdateAppCenterPaymentGatewayDetail = (
  id,
  data,
  successCallback,
  errorCallback
) => {
  try {
    axiosInstance
      .patch(`/api/billing/payment-gateway/detail/${id}/`, data)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback(err);
      });
  } catch (error) {
    console.log(error);
  }
};
