import { IconButton, Toolbar, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MuiAppBar from "@mui/material/AppBar";
import { styled, useTheme } from "@mui/material/styles";

// import {NotificationsIcon} from '@mui/icons-material';
import React, { useContext } from "react";
import NotificationDropdown from "../../../component/DashboardComponent/Notification";
import UserDropdown from "./UserDropdown";
import DrawerContext from "../../../context/drawerContext";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Header = () => {
  // const { userReg } = useContext(ClinicAuthContext);
  const { matches, handleDrawerOpen } = React.useContext(DrawerContext);
  return (
    <AppBar
      position="fixed"
      sx={{
        position: "fixed",
        // width:{sm:`calc(100%-${250}px)`},

        backgroundColor: "#fff",
        color: "#000",
      }}
    >
      <Toolbar
        sx={{
          borderRadius: "0px",
          marginLeft: { md: `${250}px` },
        }}
      >
        {!matches && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
        )}
        {/* {!userReg ? (
          <Typography
            variant="body2"
            sx={{
              flexGrow: 1,
            }}
          >
            To use all the services you have to complete your profile
          </Typography>
        ) : (
          <Typography
            variant="body2"
            sx={{
              flexGrow: 1,
            }}
          ></Typography>
        )} */}
        {/* <NotificationDropdown /> */}
        <Typography
          variant="body2"
          sx={{
            flexGrow: 1,
          }}
        ></Typography>
        <UserDropdown />
      </Toolbar>
    </AppBar>
  );
};

export default Header;
