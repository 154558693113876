export const APP_CENTER_LINKS = {
  // REGISTRATION
  LOGIN: "/app_center/login",

  // DASHBOARD
  DASHBOARD: "/app_center/dashboard",
  USER_PROFILE: "/app_center/profile",
  USER_PROFILE_EDIT: "/app_center/profile/edit",
  USER_PROFILE_PASSWORD: "/app_center/profile/password",

  RESET_PASSWORD: "/app_center/reset-password",

  // USER PROFILE NOTIFICATIONS
  USER_PROFILE_NOTIFICATIONS: "/app_center/profile/notifications",

  // CLINIC CREATION REQUEST
  CLINIC_CREATION_REQUEST_LIST: "/app_center/clinic-creation-request-list",
  CLINIC_CREATION_REQUEST_DETAILS:
    "/app_center/clinic-creation-request-details/:id",
  CLINIC_CREATION_REQUEST_DETAILS_LOG:
    "/app_center/clinic-creation-request-details-log/:id",
  CLINIC_CREATION_REQUEST_EDIT: "/app_center/clinic-creation-request-edit/:id",

  // APP LABEL
  APP_LABEL: "/app_center/app-label/list",
  APP_LABEL_DETAILS: "/app_center/app-label/details/:id",
  APP_LABEL_CREATE: "/app_center/app-label/create",
  APP_LABEL_EDIT: "/app_center/app-label/edit/:id",

  // SUBSRIPTION
  SUBSCRIPTION: "/app_center/subscription/list",
  SUBSCRIPTION_DETAILS: "/app_center/subscription/details/:id",
  SUBSCRIPTION_CREATE: "/app_center/subscription/create",
  SUBSCRIPTION_EDIT: "/app_center/subscription/edit/:id",

  // CLINIC
  CLINIC: "/app_center/practice/list",
  CLINIC_DETAILS: "/app_center/practice/details/:id",
  CLINIC_EDIT: "/app_center/practice/edit/:id",

  // BILLINGS
  CLINIC_BILLINGS: "/app_center/practice/billings/:id",
  CLINIC_TRANSACTIONS: "/app_center/practice/transactions/:id",
  CLINIC_BILLING_DETAIL: "/app_center/practice/billings/:clinicId/:id",
  PRINT_BILLING_DETAIL: "/app_center/practice/billings/print/:clinicId/:id",
  ADD_CLINIC_BILL: "/app_center/practice/billings/:id/add-bill",
  EDIT_CLINIC_BILL: "/app_center/practice/billings/:clinicId/:id/edit-bill",
  PAY_CLINIC_BILL: "/app_center/practice/billings/:clinicId/:id/pay-bill",
  PAY_CLINIC_BILL_SUCCESS:
    "/app_center/practice/billings/:clinicId/:id/pay-bill-success",

  // PAYMENT GATEWAY
  APP_CENTER_PAYMENT_GATEWAYS: "/app_center/payment_gateway/list",
  APP_CENTER_PAYMENT_GATEWAY_EDIT: "/app_center/payment_gateway/edit/:id",
  APP_CENTER_PAYMENT_GATEWAY_CREATE: "/app_center/payment_gateway/create",

  // SYSTEM LOGS
  APP_CENTER_STAFF_LOGS: "/app_center/staff-logs",

  //Email
  APP_EMAIL_CREATE: "/app_center/email/create",
  APP_EMAIL: "/app_center/email/list",
  APP_EMAIL_DETAILS: "/app_center/email/details/:id",
  APP_EMAIL_EDIT: "/app_center/email/edit/:id",

  //Events
  APP_EVENT_CREATE: "/app_center/event/create",
  APP_EVENT: "/app_center/event/list",
  APP_EVENT_DETAILS: "/app_center/event/details/:id",
  APP_EVENT_EDIT: "/app_center/event/edit/:id",
};
