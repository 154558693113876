import React from "react";
import { Alert, Box, Container } from "@mui/material";
import Header from "../../component/DashboardComponent/Header";
import SideNav from "../../component/DashboardComponent/SideNav";
import { DrawerProvider } from "../../context/drawerContext";
import { Detector, Offline, Online } from "react-detect-offline";
import { Outlet } from "react-router-dom";
import TaskAutomationModal from "../Task/TaskAutomationModal";

const DefaultLayout = ({ children }) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          minHeight: { xs: "150vh", md: "100vh" },
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
        }}
      >
        <DrawerProvider>
          <Header />
          <SideNav />
        </DrawerProvider>

        <Box
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "auto",
          }}
        >
          <Container
            maxWidth="xlg"
            sx={{
              mt: 10,
              ml: { sm: "-40px", md: "0px" },
            }}
          >
            <Detector
              render={({ online }) => {
                if (!online) {
                  return (
                    <Alert
                      severity="warning"
                      sx={{
                        position: "fixed",
                        borderRadius: 0,
                      }}
                    >
                      You are currently offline check you network connections
                    </Alert>
                  );
                }
              }}
            />
            <Outlet />
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default DefaultLayout;
