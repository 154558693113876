import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import Calendar from "../../../assets/dasboardAssets/Calendar.png";
import Medic from "../../../assets/dasboardAssets/medic.png";
import Bill from "../../../assets/icon/payment.svg";
import Payment from "../../../assets/dasboardAssets/payment.png";
import Dashboard from "../../../assets/dasboardAssets/Dashb.png";
import DashboardWhite from "../../../assets/dasboardAssets/Dashboard.png";
import CalendarWhite from "../../../assets/dasboardAssets/CalendarWhite.svg";
import AppLabelWhite from "../../../assets/dasboardAssets/AppLabelWhite.svg";
import Sub from "../../../assets/dasboardAssets/Sub.svg";
import SubWhite from "../../../assets/dasboardAssets/SubWhite.svg";
import BillWhite from "../../../assets/dasboardAssets/BillWhite.svg";
import NotificationWhite from "../../../assets/dasboardAssets/NotificationWhite.svg";
import MedicWhite from "../../../assets/dasboardAssets/MedicWhite.svg";
import NotificationIcon from "../../../assets/dasboardAssets/NotificationIcon.svg";

// import Dashboard from '../../assets/dasboardAssets/Dashboard.png';
// import DashboardOutlined from '@mui/material';
import { NavLink, useLocation } from "react-router-dom";
import { APP_CENTER_LINKS } from "../../../links/appCenterLinks";

const SideNavItem = ({ itemIcon, itemIconWhite, title, path }) => {
  const location = useLocation();
  const [currentPagePath, setCurrentPagePath] = useState("");
  useEffect(() => {
    setCurrentPagePath(location.pathname);
  }, [location.pathname]);

  let activeStyle = {
    textDecoration: "none",
    backgroundColor: "#009EED",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "row",
    paddingTop: "12px",
    paddingBottom: "12px",
    paddingRight: "24px",
    paddingLeft: "12px",
    color: "#ffffff",
    fontFamily: "Poppins",
    fontWeight: "400",
    fontSize: "14px",
    mb: 4,
  };
  let nonActiveStyle = {
    textDecoration: "none",
    display: "flex",
    flexDirection: "row",
    color: "#39484F",
    fontFamily: "Poppins",
    fontWeight: "400",
    fontSize: "14px",
    mb: 4,
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        mb: 4,
      }}
    >
      <NavLink
        to={path}
        style={({ isActive }) => (isActive ? activeStyle : nonActiveStyle)}
      >
        <img
          src={location.pathname === path ? itemIconWhite : itemIcon}
          style={{
            paddingRight: "12px",
          }}
          alt="dashboard Icon"
        />

        {title}
      </NavLink>
    </Box>
  );
};

const SideNavItems = () => {
  return (
    <Box
      sx={{
        mt: 5,
        color: "textGray.sideNav",
      }}
    >
      <SideNavItem
        title="Dashboard"
        itemIcon={Dashboard}
        itemIconWhite={DashboardWhite}
        path={APP_CENTER_LINKS.DASHBOARD}
      />
      <SideNavItem
        title="Practice Requests"
        itemIcon={Calendar}
        itemIconWhite={CalendarWhite}
        path={APP_CENTER_LINKS.CLINIC_CREATION_REQUEST_LIST}
      />
      <SideNavItem
        title="Practices"
        itemIcon={Medic}
        itemIconWhite={MedicWhite}
        path={APP_CENTER_LINKS.CLINIC}
      />
      <SideNavItem
        title="Subcription Plan"
        itemIcon={Sub}
        itemIconWhite={SubWhite}
        path={APP_CENTER_LINKS.SUBSCRIPTION}
      />
      <SideNavItem
        title="App Label"
        itemIcon={Payment}
        itemIconWhite={AppLabelWhite}
        path={APP_CENTER_LINKS.APP_LABEL}
      />
      <SideNavItem
        title="Payment Gateways"
        itemIcon={Bill}
        itemIconWhite={BillWhite}
        path={APP_CENTER_LINKS.APP_CENTER_PAYMENT_GATEWAYS}
      />

      <SideNavItem
        title="Email Broadcast"
        itemIcon={NotificationIcon}
        itemIconWhite={NotificationWhite}
        path={APP_CENTER_LINKS.APP_EMAIL}
      />
      <SideNavItem
        title="Smartdvm Events"
        itemIcon={Payment}
        itemIconWhite={BillWhite}
        path={APP_CENTER_LINKS.APP_EVENT}
      />
    </Box>
  );
};

export default SideNavItems;
