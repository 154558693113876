import { Typography } from "@mui/material";
import React from "react";

const Title = ({ sx, children }) => {
  return (
    <Typography
      gutterBottom
      sx={{
        flexGrow: 1,
      }}
    >
      {children}
    </Typography>
  );
};

export default Title;
