import React from "react";
import { PageProgress } from "./component/ProgressIndicators/PageProgress";

const ClinicPageLoader = () => {
  return (
    <div>
      <PageProgress />
    </div>
  );
};

export default ClinicPageLoader;
