import React, { useContext } from "react";
import { Box, Typography } from "@mui/material";
import { Link, NavLink, useNavigate } from "react-router-dom";
import UserAvatar from "../../assets/avatars/avatar_icon.png";
import Path from "../../assets/dasboardAssets/Path.png";
import ClinicAuthContext from "../../context/clinicAuthContext";
import capitalizeFirstLetter from "../../features/capitalizeFirstLetter";
import { CLIENT_LINKS } from "../../links/clientLinks";

const Userdropdown = () => {
  const { user } = useContext(ClinicAuthContext);
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(CLIENT_LINKS.DASHBOARD_PERSONAL_INFORMATION);
  };
  return (
    <Box
      sx={{ display: "flex", alignItems: "center", paddingRight: "36px" }}
      onClick={handleClick}
    >
      <img
        src={
          user && user.user && user.user.image ? user.user.image : UserAvatar
        }
        alt="Avatar"
        style={{
          marginRight: "12px",
          borderRadius: "50%",
          width: "50px",
          height: "50px",
        }}
      />
      <Typography variant="dashboard" sx={{ paddingRight: "18px" }}>
        {" "}
        {user && user.user && user.user.first_name
          ? capitalizeFirstLetter(user.user.first_name)
          : ""}{" "}
        {user && user.user && user.user.last_name
          ? capitalizeFirstLetter(user.user.last_name)
          : ""}
      </Typography>
      <img src={Path} alt="Avatar" />
    </Box>
  );
};

export default Userdropdown;
