import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import ClinicAuthContext from "./context/clinicAuthContext";
import {
  getClinicAuthenticatedStaffDetail,
  getClinicDetailSlug,
} from "./api/API";
import { getClinicSlugFromSubHost } from "./api/getClinicSlugFromSubHost";
import { getCurrency } from "./api/Settings/Settings";
import Login from "./pages/clinic_super/registration/Login";
import { CLINIC_SUPER_LINKS } from "./links/clinicSuperLinks";
import SuperAdminPageLoader from "./SuperAdminPageLoader";
import SuperAdminDefaultLayout from "./component/DefaultLayout/SuperAdminDefaultLayout";

const GetStartedRegForm = lazy(() =>
  import("./pages/clinic_super/registration/GetStartedRegForm")
);
const AdminStaffList = lazy(() =>
  import("./pages/clinic_super/AdminStaff/AdminStaffList")
);
const AdminStaffDetail = lazy(() =>
  import("./pages/clinic_super/AdminStaff/AdminStaffDetail")
);
const AdminStaffEdit = lazy(() =>
  import("./pages/clinic_super/AdminStaff/AdminStaffEdit")
);
const AdminStaffCreate = lazy(() =>
  import("./pages/clinic_super/AdminStaff/AdminStaffCreate")
);
const StaffOverview = lazy(() => import("./pages/clinic/Staff/StaffOverview"));
const Password = lazy(() => import("./pages/clinic/Staff/Password"));
const StaffOverviewEdit = lazy(() =>
  import("./pages/clinic/Staff/StaffOverviewEdit")
);
const AddNewStaff = lazy(() => import("./pages/clinic/Staff/AddNewStaff"));
const AccountInfoEditMode = lazy(() =>
  import("./pages/clinic/Profile/AccountInfoEditMode")
);
const AccountInfoViewMode = lazy(() =>
  import("./pages/clinic/Profile/AccountInfoViewMode")
);
const BillList = lazy(() =>
  import("./pages/clinic_super/BillingInfo/BillList")
);
const BillDetail = lazy(() =>
  import("./pages/clinic_super/BillingInfo/BillDetail")
);
const TransactionList = lazy(() =>
  import("./pages/clinic_super/Transaction/Transactions")
);
const ActivityLogList = lazy(() =>
  import("./pages/clinic/notification/ActivityLogList")
);
const NotificationList = lazy(() =>
  import("./pages/clinic/notification/NotificationList")
);
const NotificationDetails = lazy(() =>
  import("./pages/clinic/notification/NotificationDetails")
);
const BillPay = lazy(() => import("./pages/clinic_super/BillingInfo/BillPay"));
const BillPaySuccess = lazy(() =>
  import("./pages/clinic_super/BillingInfo/BillPaySuccess")
);
const BillPrint = lazy(() =>
  import("./pages/smartdvm_app_center/center_components/Bills/BillPrint")
);

const Dashboard = lazy(() =>
  import("./pages/clinic_super/Dashboard/Dashboard")
);
const ProfilePassword = lazy(() =>
  import("./pages/clinic/Profile/ProfilePassword")
);
const SuperBillPrint = lazy(() =>
  import("./pages/clinic_super/BillingInfo/BillPrint")
);
const UpgradeSubscription = lazy(() =>
  import("./pages/clinic_super/BillingInfo/SubscriptionList")
);

const ClinicSuperAdminApp = () => {
  const { user, staff, clinic, setUser, setClinic, setStaff, setCurrency } =
    React.useContext(ClinicAuthContext);

  const handleGetClinicCallback = (data) => {
    setClinic(data);
  };
  const handleErrorCallback = () => {
    // navigate(CLINIC_LINKS.LOGIN);
  };

  const staffSuccessCallback = (data) => {
    setStaff(data);
  };
  const getCurrencySuccess = (data) => {
    setCurrency(data.code);
  };
  React.useEffect(() => {
    getClinicAuthenticatedStaffDetail(
      staffSuccessCallback,
      handleErrorCallback
    );
    getClinicDetailSlug(
      getClinicSlugFromSubHost(),
      handleGetClinicCallback,
      handleErrorCallback
    );

    // }
    if (clinic.id) {
      getCurrency(clinic.id, getCurrencySuccess);
    }
  }, [clinic.id, staff.role]);
  return (
    <>
      <Routes>
        <Route path={CLINIC_SUPER_LINKS.LOGIN} element={<Login />} />
        <Route
          path={CLINIC_SUPER_LINKS.REQUEST_REGISTER}
          element={<GetStartedRegForm />}
        />

        <Route
          path="/vapp_super/"
          element={
            <Suspense fallback={<SuperAdminPageLoader />}>
              <SuperAdminDefaultLayout />
            </Suspense>
          }
        >
          <Route
            path={CLINIC_SUPER_LINKS.DASHBOARD}
            element={
              <Suspense fallback={<SuperAdminPageLoader />}>
                <Dashboard />
              </Suspense>
            }
          />
          {/* USER PROFILE */}
          <Route
            path={CLINIC_SUPER_LINKS.USER_PROFILE_EDIT}
            element={
              <Suspense fallback={<SuperAdminPageLoader />}>
                <AccountInfoEditMode />
              </Suspense>
            }
          />
          <Route
            path={CLINIC_SUPER_LINKS.USER_PROFILE}
            element={
              <Suspense fallback={<SuperAdminPageLoader />}>
                <AccountInfoViewMode />
              </Suspense>
            }
          />
          <Route
            path={CLINIC_SUPER_LINKS.USER_PROFILE_PASSWORD}
            element={
              <Suspense fallback={<SuperAdminPageLoader />}>
                <ProfilePassword />
              </Suspense>
            }
          />
          {/* ADMIN STAFF */}
          <Route
            path={CLINIC_SUPER_LINKS.ADMIN_STAFF}
            element={
              <Suspense fallback={<SuperAdminPageLoader />}>
                <AdminStaffList />
              </Suspense>
            }
          />
          <Route
            path={CLINIC_SUPER_LINKS.ADMIN_STAFF_DETAIL}
            element={
              <Suspense fallback={<SuperAdminPageLoader />}>
                <StaffOverview />
              </Suspense>
            }
          />
          <Route
            path={CLINIC_SUPER_LINKS.ADMIN_STAFF_EDIT}
            element={
              <Suspense fallback={<SuperAdminPageLoader />}>
                <StaffOverviewEdit />
              </Suspense>
            }
          />
          <Route
            path={CLINIC_SUPER_LINKS.ADMIN_STAFF_PASSWORD}
            element={
              <Suspense fallback={<SuperAdminPageLoader />}>
                <Password />
              </Suspense>
            }
          />

          <Route
            path={CLINIC_SUPER_LINKS.ADMIN_STAFF_CREATE}
            element={
              <Suspense fallback={<SuperAdminPageLoader />}>
                <AddNewStaff />
              </Suspense>
            }
          />

          {/* BILLING */}
          <Route
            path={CLINIC_SUPER_LINKS.BILLING}
            element={
              <Suspense fallback={<SuperAdminPageLoader />}>
                <BillList />
              </Suspense>
            }
          />
          <Route
            path={CLINIC_SUPER_LINKS.BILLING_DETAIL}
            element={
              <Suspense fallback={<SuperAdminPageLoader />}>
                <BillDetail />
              </Suspense>
            }
          />
          <Route
            path={CLINIC_SUPER_LINKS.BILLING_PLAN_UPGRADE}
            element={
              <Suspense fallback={<SuperAdminPageLoader />}>
                <UpgradeSubscription />
              </Suspense>
            }
          />

          <Route
            path={CLINIC_SUPER_LINKS.TRANSACTION}
            element={
              <Suspense fallback={<SuperAdminPageLoader />}>
                <TransactionList />
              </Suspense>
            }
          />

          <Route
            path={CLINIC_SUPER_LINKS.BILLING_PAY}
            element={
              <Suspense fallback={<SuperAdminPageLoader />}>
                <BillPay />
              </Suspense>
            }
          />
          <Route
            path={CLINIC_SUPER_LINKS.BILLING_PAY_SUCCESS}
            element={
              <Suspense fallback={<SuperAdminPageLoader />}>
                <BillPaySuccess />
              </Suspense>
            }
          />

          {/* NOTIFICATIONS */}
          <Route
            element={
              <Suspense fallback={<SuperAdminPageLoader />}>
                <NotificationList />
              </Suspense>
            }
            path={CLINIC_SUPER_LINKS.NOTIFICATIONS}
          />
          <Route
            element={
              <Suspense fallback={<SuperAdminPageLoader />}>
                <NotificationDetails />
              </Suspense>
            }
            path={CLINIC_SUPER_LINKS.NOTIFICATION_DETAIL}
          />

          <Route
            path={CLINIC_SUPER_LINKS.BILLPRINTPAGE}
            element={
              <Suspense fallback={<SuperAdminPageLoader />}>
                <SuperBillPrint />
              </Suspense>
            }
          />

          <Route
            element={
              <Suspense fallback={<SuperAdminPageLoader />}>
                <ActivityLogList />
              </Suspense>
            }
            path={CLINIC_SUPER_LINKS.LOG}
          />
        </Route>
      </Routes>
    </>
  );
};

export default ClinicSuperAdminApp;
