import {
  Box,
  Grid,
  IconButton,
  ListItem,
  ListItemIcon,
  Paper,
  Popover,
  Typography,
} from "@mui/material";
import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Settings from "../../assets/Clinic/Dashboard/Setting.png";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { CLINIC_LINKS } from "../../links/clinicLinks";

const SettingsDropdown = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedSection, setSelectedSection] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "user-popover" : undefined;

  let activeStyle = {
    textDecoration: "none",
    color: "#071A23",
  };
  let nonActiveStyle = {
    color: "#6A767B",
    textDecoration: "none",
  };

  // PREVIOUS IMPLEMENTATION WITH A DROPDOWN
  // AFTER CLICKING THE SETTINGS ICON
  // JUST UNCOMMENT THIS SECTION TO REVERT
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleClick = () => {
    navigate(CLINIC_LINKS.CONFIGURE_PRACTICE_SETTINGS);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSelect = (item) => {
    setSelectedSection(item);
  };

  const handlePreload = () => {
    const firstItem = settingsMenuList[0];
    setSelectedSection(firstItem);
  };
  React.useEffect(() => {
    handlePreload();
  }, []);
  return (
    <>
      <div>
        <IconButton
          sx={{
            backgroundColor: "backgroundTypes.buttonNew",
            mx: 1,
          }}
          onClick={handleClick}
        >
          <img
            style={{ height: "38px", width: "38px" }}
            src={Settings}
            alt="icon"
          />
        </IconButton>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          sx={{
            mt: 3,
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Paper
            sx={{
              width: { lg: "701px", md: "500px", xs: "90%" },
              height: "421px",
            }}
          >
            <Grid container>
              <Grid item xs={3}>
                {settingsMenuList.map((item) => (
                  <ListItem
                    sx={{ width: "100px", cursor: "pointer" }}
                    onClick={() => handleSelect(item)}
                  >
                    <ListItemIcon></ListItemIcon>
                    {selectedSection && item.ID === selectedSection.ID && (
                      <ArrowRightIcon />
                    )}
                    {selectedSection && item.ID === selectedSection.ID ? (
                      <Typography
                        sx={{
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "14px",
                          lineHeight: "24px",
                        }}
                      >
                        {item.title}
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          color: "#AEAEAE",
                          fontStyle: "normal",
                          fontWeight: "500",
                          fontSize: "14px",
                          lineHeight: "24px",
                        }}
                      >
                        {item.title}{" "}
                      </Typography>
                    )}
                  </ListItem>
                ))}
              </Grid>
              <Grid xs={8} item sx={{ marginTop: "15px" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    ml: 3,
                  }}
                >
                  {selectedSection &&
                    selectedSection.items &&
                    selectedSection.items.map((item) => (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "130px",
                          mx: 2,
                          mb: 3,
                        }}
                      >
                        <Typography
                          sx={{
                            paddingBottom: "8px",
                            fontStyle: "normal",
                            fontWeight: "400",
                            fontSize: "16px",
                            lineHeight: "21.6px",
                          }}
                        >
                          {item.title}
                        </Typography>
                        {item.entries.map((entry) => (
                          <NavLink
                            style={({ isActive }) =>
                              isActive ? activeStyle : nonActiveStyle
                            }
                            to={entry.link}
                          >
                            <Typography
                              sx={{
                                paddingBottom: "8px",
                                color: "#A6A6A6",
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "14px",
                                lineHeight: "17.5px",
                              }}
                            >
                              {entry.title}
                            </Typography>
                          </NavLink>
                        ))}
                      </Box>
                    ))}
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Popover>
      </div>
    </>
  );
};

export const settingsMenuList = [
  {
    title: "Clinic Settings",
    ID: 3,
    items: [
      {
        title: "Appointments",
        entries: [
          {
            title: "Admission Type",
            link: CLINIC_LINKS.ADMISSION_TYPE_SETTINGS,
          },
          {
            title: "Boarding Size",
            link: CLINIC_LINKS.CAGESIZE_SETTINGS,
          },
          {
            title: "Boarding Type",
            link: CLINIC_LINKS.CAGETYPE_SETTINGS,
          },
        ],
      },
      {
        title: "Clinical Items",
        entries: [
          {
            title: "Assessment Diagnosis",
            link: CLINIC_LINKS.ASSESSMENT_DIAGNOSIS,
          },
          {
            title: "Initial Complaint",
            link: CLINIC_LINKS.INITIAL_COMPLAINTS,
          },
          {
            title: "Vital Findngs",
            link: CLINIC_LINKS.VITAL_FINDINGS,
          },
        ],
      },
      {
        title: "Inventory",
        entries: [
          {
            title: "Medicine (Types, Categories)",
            link: CLINIC_LINKS.INVENTORY_CATEGORY_SETTINGS,
          },
          {
            title: "Products (Types, Categories)",
            link: CLINIC_LINKS.INVENTORY_CATEGORY_SETTINGS,
          },
          {
            title: "Supplier",
            link: CLINIC_LINKS.PRODUCT_SUPPLIER_SETTINGS,
          },
        ],
      },

      {
        title: "Laboratory",
        entries: [
          {
            title: "Flag",
            link: CLINIC_LINKS.LAB_METHODOLOGY_SETTINGS,
          },
          {
            title: "Methodology",
            link: CLINIC_LINKS.LAB_METHODOLOGY_SETTINGS,
          },
          {
            title: "Sample Type",
            link: CLINIC_LINKS.LAB_SAMPLE_TYPE,
          },
          {
            title: "Test Location",
            link: CLINIC_LINKS.LAB_LOCATION_SETTINGS,
          },
          {
            title: "Test Type/Analysis",
            link: CLINIC_LINKS.LAB_TEST_TYPE_SETTINGS,
          },
        ],
      },
      {
        title: "Patient",
        entries: [
          {
            title: "Breed",
            link: CLINIC_LINKS.BREED_SETTINGS,
          },
          {
            title: "Color",
            link: CLINIC_LINKS.COLOR_SETTINGS,
          },
          {
            title: "Gender",
            link: CLINIC_LINKS.SEX_SETTINGS,
          },
          {
            title: "Species",
            link: CLINIC_LINKS.SPECIES_SETTINGS,
          },
        ],
      },

      {
        title: "Staff",
        entries: [
          {
            title: "Department",
            link: CLINIC_LINKS.DEPARTMENT_SETTINGS,
          },
          {
            title: "Leave Types",
            link: CLINIC_LINKS.LEAVE_SETTINGS,
          },
          {
            title: "Roles",
            link: CLINIC_LINKS.PERMISSIONS_SETTINGS,
          },
          {
            title: "Title",
            link: CLINIC_LINKS.TITLE_SETTINGS,
          },

          // {
          //   title: "Staff Gender",
          //   link: CLINIC_LINKS.STAFF_GENDER_SETTINGS,
          // },
        ],
      },

      // {
      //   title: "Procedure and Service",
      //   entries: [
      //     {
      //       title: "Types",
      //       link: "",
      //     },
      //     {
      //       title: "Categories",
      //       link: "/vapp/settings/inventory/category",
      //     },
      //   ],
      // },
      // {
      //   title: "Boarding",
      //   entries: [
      //     {
      //       title: "Boarding Types",
      //       link: "",
      //     },
      //   ],
      // },
      // {
      //   title: "Payment Methods",
      //   entries: [
      //     {
      //       title: "Methods",
      //       link: "",
      //     },
      //   ],
      // },
    ],
  },
  {
    title: "Management Settings",
    ID: 2,
    items: [
      {
        title: "HR",
        entries: [
          {
            title: "Leave",
            link: CLINIC_LINKS.LEAVE_SETTINGS,
          },
        ],
      },
      {
        title: "Messaging",
        entries: [
          {
            title: "Google Auth Settings",
            link: CLINIC_LINKS.GOOGLE_SETTINGS,
          },
        ],
      },
      {
        title: "Payment",
        entries: [
          {
            title: "Currency",
            link: CLINIC_LINKS.CURRENCY,
          },
          {
            title: "Payment Integration",
            link: CLINIC_LINKS.PAYMENT_GATEWAY,
          },
        ],
      },
      {
        title: "Practice Information",
        entries: [
          {
            title: "Email",
            link: CLINIC_LINKS.CLINIC_UPDATE,
          },
          {
            title: "Provider Name",
            link: CLINIC_LINKS.CLINIC_UPDATE,
          },
          {
            title: "Provider ID",
            link: CLINIC_LINKS.CLINIC_UPDATE,
          },
          {
            title: "Logo Upload",
            link: CLINIC_LINKS.CLINIC_UPDATE,
          },
          {
            title: "Phone Number",
            link: CLINIC_LINKS.CLINIC_UPDATE,
          },

          // {
          //   title: "FavIcon",
          //   link: CLINIC_LINKS.CLINIC_UPDATE,
          // },
          // {
          //   title: "Time Zone",
          //   link: CLINIC_LINKS.CLINIC_UPDATE,
          // },
          // {
          //   title: "Website",
          //   link: "",
          // },
        ],
      },
    ],
  },

  // {
  //   title: "Profile Settings",
  //   ID: 4,
  //   items: [
  //     {
  //       title: "Personal Information",
  //       entries: [
  //         {
  //           title: "name",
  //           link: "",
  //         },
  //         {
  //           title: "Email",
  //           link: "",
  //         },
  //         {
  //           title: "Phone",
  //           link: "",
  //         },
  //         {
  //           title: "Department",
  //           link: "",
  //         },
  //         {
  //           title: "Role",
  //           link: "",
  //         },
  //         {
  //           title: "Signature",
  //           link: "",
  //         },
  //         {
  //           title: "Time Profile image",
  //           link: "",
  //         },
  //       ],
  //     },
  //     {
  //       title: "Login and Security",
  //       entries: [
  //         {
  //           title: "Email",
  //           link: "",
  //         },
  //         {
  //           title: "Phone",
  //           link: "",
  //         },
  //         {
  //           title: "Password",
  //           link: "",
  //         },
  //         {
  //           title: "PIN",
  //           link: "",
  //         },
  //         {
  //           title: "Security questions",
  //           link: "",
  //         },
  //         {
  //           title: "Satylogged in",
  //           link: "",
  //         },
  //         {
  //           title: "2 Step verification",
  //           link: "",
  //         },
  //       ],
  //     },
  //     {
  //       title: "Message",
  //       entries: [
  //         {
  //           title: "Tasks",
  //           link: "",
  //         },
  //         {
  //           title: "Events",
  //           link: "",
  //         },
  //       ],
  //     },
  //   ],
  // },
];
export default SettingsDropdown;
