export const CLIENT_LINKS = {
  //

  LOGIN: "/vclients/login",
  REGISTER: "/vclients/register",
  GET_CODE: "/vclients/get-code",
  BASIC_REGISTRATION_FORM: "/vclients/basic-registration-form",
  RESET_PASSWORD: "/vclients/reset-password",
  FORGOT_PASSWORD: "/vclients/forgot-password",
  REGISTRATION: "/vclients/registration",
  PATIENTS_REGISTRATION: "/vclients/registration/patient",
  MEDICAL_INFORMATION_REGISTRATION: "/vclients/registration/medical",

  // DASHBOARD
  DASHBOARD: "/vclients/dashboard",
  DASHBOARD_PERSONAL_INFORMATION: "/vclients/dashboard/personal-information",
  DASHBOARD_PATIENT_INFORMATION: "/vclients/dashboard/patient-information",
  DASHBOARD_MEDICAL_INFORMATION: "/vclients/dashboard/patient-medical-record",
  DASHBOARD_PASSWORD_UPDATE: "/vclients/dashboard/password",

  // APPOINTMENT
  APPOINTMENTS: "/vclients/appointment",
  APPOINTMENT_DETAIL: "/vclients/appointment/:id/overview",
  APPOINTMENT_RESCHEDULE: "/vclients/appointment/:id/overview/reschedule/",
  APPOINTMENT_RESCHEDULE_BOARDING:
    "/vclients/appointment/reschedule/overview/boarding",
  APPOINTMENT_CREATE: "/vclients/appointment/create-appointment",
  APPOINTMENT_PAYMENT:
    "/vclients/appointment/create-appointment/payment-method",
  APPOINTMENT_EMPTY_MEDICAL_RECORD:
    "/vclients/appointment/empty-medical-record",
  APPOINTMENT_MEDICAL_RECORD: "/vclients/appointment/:id/medical-record",
  APPOINTMENT_MEDICAL_RECORD_PRESCRIPTION:
    "/vclients/appointment/:id/medical-record/prescription",
  APPOINTMENT_MEDICAL_REORD_TEST_DETAIL:
    "/vclients/appointment/:id/medical-record/test-details",
  UPCOMING_APPOINTMENT_FULL_LIST:
    "/vclients/appointment/upcoming-appointment/:id",

  // PATIENT
  PATIENTS: "/vclients/patients",
  PATIENT_DETAIL: "/vclients/patient/:id",
  PATIENT_ADD_NEW_PATIENT: "/vclients/add-new-patient",
  PATIENT_EDIT: "/vclients/edit-patient/:id",

  // PAYMENT
  INVOICE_HISTORY: "/vclients/invoice-history",
  PAYMENT_HISTORY: "/vclients/payment-history",
  PAYMENT_DETAILS: "/vclients/payment-history/:id/payment-details",
  PAY_INVOICE: "/vclients/pay-invoice/:id",
  PAY_INVOICE_SUCCESS: "/vclients/pay-invoice-success/:id",

  // MEDICAL RECORD
  MEDICAL_RECORD: "/vclients/medical-records",
  PATIENTS_MEDICAL_RECORDS: "/vclients/patient-medical-records/:id",
};
