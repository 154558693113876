import {
  AppBar,
  Box,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

// import {NotificationsIcon} from '@mui/icons-material';
import React from "react";
import SettingsDropdown from "./SettingsDropdown";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import NotificationDropdown from "./NotificationDropdown";
import UserDropdown from "./UserDropdown";
import ClinicAuthContext from "../../context/clinicAuthContext";
import DrawerContext from "../../context/drawerContext";
import SmartdvmLogo from "../../assets/logo.png";

import CancelIcon from "../../assets/avatars/Cancelcon.svg";
import Handbuger from "../../assets/icon/Handbuger.svg";
import { Link } from "react-router-dom";
import { CLINIC_LINKS } from "../../links/clinicLinks";

const Header = () => {
  const { staff, clinic, subscriptionActive } =
    React.useContext(ClinicAuthContext);
  const { open, handleDrawerOpen, matches } = React.useContext(DrawerContext);
  const [visible, setVisible] = React.useState(false);

  const handleClick = () => {
    setVisible(!visible);
  };

  const linkStyle = {
    textDecoration: "none",
    color: "#39484F",
    transition: "color 0.3s", // Add a transition effect for hover
    borderBottom: "none",
  };

  const hoverStyle = {
    color: "#009EED",
    textDecoration: "none",
    // Change the color on hover
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        position: "fixed",
        // width:{sm:`calc(100%-${250}px)`},
        borderRadius: "0px",
        backgroundColor: "#fff",
        color: "#000",
        zIndex: 150,
      }}
    >
      <Toolbar
        sx={{
          // marginLeft: { md: `${250}px` },
          borderRadius: "0px",
        }}
      >
        {!subscriptionActive() && (
          <img
            src={SmartdvmLogo}
            alt="smartdvm-logo||uploaded logo"
            style={{
              marginTop: "12px",
              marginLeft: "24px",
              maxHeight: "6em",
              maxWidth: "8em",
            }}
          />
        )}
        {!matches && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            {/* <MenuIcon /> */}
            <img src={Handbuger} alt="handbuger icon" />
          </IconButton>
        )}

        <Box
          variant="body2"
          sx={{
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {!clinic.logo && visible && (
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                fontFamily: "Poppins",
                alignItems: "center",
                justifyContent: "space-between",
                height: "28px",
                borderRadius: "8px",
              }}
            >
              <Box
                variant="body2"
                style={{
                  padding: "5px",
                  alignContent: "center",
                }}
              >
                {" "}
                To start using all Smartdvm features, please complete your
                practice information and Settings.{" "}
                <Link
                  style={{
                    textDecoration: "none",
                    color: "#009EED",
                    transition: "color 0.3s",
                  }}
                  to={CLINIC_LINKS.GETTING_STARTED_PAGE}
                  onMouseOver={(e) => {
                    e.target.style.textDecoration = "none"; // Remove underline on hover
                    e.target.style.color = "#FF6417"; // Change color on hover
                  }}
                  onMouseOut={(e) => {
                    e.target.style.textDecoration = "none"; // Ensure no underline on mouseout
                    e.target.style.color = "#009EED";
                  }}
                >
                  Click Here.
                </Link>{" "}
              </Box>
              <Box sx={{ alignContent: "center", marginTop: "5px" }}>
                <IconButton>
                  <span
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={handleClick}
                  >
                    <img src={CancelIcon} alt="icon" />
                  </span>
                </IconButton>
              </Box>
            </Box>
          )}
        </Box>

        {matches && (
          <Box>
            <SettingsDropdown />
          </Box>
        )}
        <NotificationDropdown />
        {matches && <UserDropdown />}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
