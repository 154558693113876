import axiosInstance from "../axios/post";

export const PAGE_LIMIT = 10;

export const getNotifications = (clinic_id, successCallback, errorCallback) => {
  try {
    axiosInstance
      .get(`/api/notification/notifications/${clinic_id}/`)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get notifications");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const deleteAllNotifications = (
  clinic_id,
  successCallback,
  errorCallback,
  data
) => {
  try {
    axiosInstance
      .post(`/api/notification/notifications-delete-all/${clinic_id}/`, data)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not delete notifications");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const viewAllNotifications = (
  clinic_id,
  successCallback,
  errorCallback,
  data
) => {
  try {
    axiosInstance
      .post(`/api/notification/notifications-viewed-all/${clinic_id}/`, data)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not delete notifications");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getNotificationsDetails = (
  id,
  clinic_id,
  successCallback,
  errorCallback
) => {
  try {
    axiosInstance
      .get(`/api/notification/notifications-detail/${clinic_id}/${id}/`)
      .then((res) => {
        console.log(res.data);
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get notifications");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const deleteNotificationDetails = (
  clinic_id,
  id,
  successCallback,
  errorCallback
) => {
  try {
    axiosInstance
      .delete(`/api/notification/notifications-detail/${clinic_id}/${id}/`)
      .then((res) => {
        successCallback(res.id);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not delete notification");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getAllNotifications = (
  clinic_id,
  successCallback,
  errorCallback,
  page,
  tablePage
) => {
  let pageLink = `/api/notification/notifications/${clinic_id}/`;
  if (tablePage && page) {
    pageLink = `${pageLink}?limit=${PAGE_LIMIT}&offset=${page}`;
  } else if (tablePage) {
    pageLink = `${pageLink}?limit=${PAGE_LIMIT}`;
  }
  try {
    axiosInstance
      .get(pageLink)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get notifications");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const createBroadcast = (
  data,
  clinicId,
  successCallback,
  errorCallback
) => {
  try {
    axiosInstance
      .post(`/api/notification/broadcast/${clinicId}/`, data)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback(err);
      });
  } catch (error) {
    console.log(error);
  }
};

export const getAllBroadcast = (
  clinic_id,
  successCallback,
  errorCallback,
  page,
  tablePage
) => {
  let pageLink = `/api/notification/broadcast/${clinic_id}/`;
  if (tablePage && page) {
    pageLink = `${pageLink}?limit=${PAGE_LIMIT}&offset=${page}`;
  } else if (tablePage) {
    pageLink = `${pageLink}?limit=${PAGE_LIMIT}`;
  }
  try {
    axiosInstance
      .get(pageLink)
      .then((res) => {
        console.log(res.data);
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get broadcasts");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getActivityLogs = (
  clinic_id,
  logType,
  successCallback,
  errorCallback,
  page,
  tablePage
) => {
  let pageLink = `/api/eventlogs/${clinic_id}/${logType}/`;
  if (tablePage && page) {
    pageLink = `${pageLink}?limit=${PAGE_LIMIT}&offset=${page}`;
  } else if (tablePage) {
    pageLink = `${pageLink}?limit=${PAGE_LIMIT}`;
  }
  try {
    axiosInstance
      .get(pageLink)
      .then((res) => {
        console.log(res.data);
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get broadcasts");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const createActivityLogs = (
  clinic_id,
  data,
  successCallback,
  errorCallback
) => {
  let pageLink = `/api/eventlogs/${clinic_id}/N/`;

  try {
    axiosInstance
      .post(pageLink, data)
      .then((res) => {
        console.log(res.data);
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not create eventlogs");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const apiCreateSmartDVMNotification = (
  data,
  successCallback,
  errorCallback
) => {
  try {
    axiosInstance
      .post(`/api/notification/create-a-smartdvm-notification/`, data)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("send code error");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};
