import React from "react";
import { PageProgress } from "./component/ProgressIndicators/PageProgress";
import SuperAdminDefaultLayout from "./component/DefaultLayout/SuperAdminDefaultLayout";

const SuperAdminPageLoader = () => {
  return (
    <div>
      <PageProgress />
    </div>
  );
};

export default SuperAdminPageLoader;
