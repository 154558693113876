import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import CloseButton from "../../assets/Clinic/Appointment/closeButton.png";
import Calendar from "../../assets/Clinic/Dashboard/Calender.png";
import NotificationItems from "./NotificationItems";
import { Divider } from "@mui/material";
import GeneralDeleteModal from "../../component/Clinic/General/Modals/Delete";
import {
  deleteAllNotifications,
  viewAllNotifications,
} from "../../api/Notification/NotificationsAPI";
import { toast } from "react-toastify";
import {
  INITIAL_STATE,
  emptyReturnPostReducer,
} from "../../reducers/postReducer";
import { ACTION_TYPES } from "../../reducers/ActionTypes";
import ClinicAuthContext from "../../context/clinicAuthContext";

// import { readableDate, readableTime } from '../../../features/readableDate';

const style = {
  position: "absolute",
  top: "0",
  right: "0",
  width: { xs: "95%", sm: "60%", md: "50%" },
  minHeight: "100vh",
  bgcolor: "background.paper",
};

export default function BasicModal({ onClose, open, selectedDateInfo }) {
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const { clinic } = React.useContext(ClinicAuthContext);
  const [state, dispatch] = React.useReducer(
    emptyReturnPostReducer,
    INITIAL_STATE
  );

  const handleOpen = () => setOpenDeleteModal(!openDeleteModal);

  const deleteSuccessCallback = () => {
    dispatch({ type: ACTION_TYPES.FETCH_SUCCESS });
    toast.success("All Notification Deleted Successfully", {
      position: "bottom-right",
    });
    handleOpen();
  };

  const viewAllSuccessCallback = () => {
    dispatch({ type: ACTION_TYPES.FETCH_SUCCESS });
  };
  const errorCallBack = () => {};

  const handleDelete = () => {
    dispatch({ type: ACTION_TYPES.FETCH_START });

    const data = {
      clinic_id: clinic.id,
    };

    deleteAllNotifications(
      clinic.id,
      deleteSuccessCallback,
      errorCallBack,
      data
    );
  };

  const handleViewAll = () => {
    dispatch({ type: ACTION_TYPES.FETCH_START });

    const data = {
      clinic_id: clinic.id,
    };

    viewAllNotifications(
      clinic.id,
      viewAllSuccessCallback,
      errorCallBack,
      data
    );
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        sx={{ overflow: "scroll", border: "none" }}
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "500",
                color: "#203139",
                paddingTop: "20px",
                paddingLeft: "33px",
              }}
            >
              Notifications
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "25px",
              }}
            >
              <Button
                variant="outlined"
                color="error"
                sx={{ marginRight: "10px" }}
                onClick={handleOpen}
              >
                Delete All
              </Button>

              {/* <Button
                variant="outlined"
                sx={{ marginRight: "10px" }}
                onClick={handleViewAll}
              >
                Mark All As Viewed
              </Button> */}

              <Box
                sx={{
                  cursor: "pointer",
                  marginRight: "5px",
                }}
                onClick={onClose}
              >
                <img src={CloseButton} alt="icon" />
              </Box>
            </Box>
          </Box>
          <Divider sx={{ mt: 5, color: "#E6E8E9" }} />
          <Box sx={{ mt: 2 }}>
            <NotificationItems />
          </Box>
        </Box>
      </Modal>

      <GeneralDeleteModal
        open={openDeleteModal}
        onClose={handleOpen}
        item_type="All Notifications"
        deleteFunction={handleDelete}
      />
    </div>
  );
}
