import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import UploadEmpty from "../../../assets/registration/UploadEmpty.png";
import Eye from "../../../assets/medicalRecord/Eye.png";
import Delete from "../../../assets/medicalRecord/Delete.png";
import { LinearProgressWithLabel } from "../../../scomponents/utils/LinearProgressWithLabel";
import ShowDocumentModal from "../../../component/MedicalRecords/ShowDocumentModal";
import { detectImageTypes } from "../../../features/detectDocumentExt";

const TaskFileUploading = ({ file, progress, done, handleDelete }) => {
  const [open, setOpen] = React.useState(false);
  const handleShow = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(!open);
  };
  return (
    <Box
      sx={{
        border: { xs: "1px solid #E6E8E9" },
        borderRadius: "12px",
        minheight: "55px",
        p: 3,
        mb: 2,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            mb: 2,
          }}
        >
          <img
            src={UploadEmpty}
            alt="document upload"
            style={{
              width: "44px",
              height: "44px",
              marginRight: "5px",
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography color="textColor" variant="body1">
              {file.id ? file.title : file.name}
            </Typography>
            <Typography variant="caption">
              {/* {file.id ? file.document_size : file.size} MB */}
              File Type: {file.id ? detectImageTypes(file.title) : file.name}
            </Typography>
          </Box>
        </Box>
        {done ? (
          <Box>
            <a href={file.document} target="_blank" rel="noopener noreferrer">
              <IconButton>
                <img src={Eye} alt="" />
              </IconButton>
            </a>
            <IconButton onClick={handleDelete}>
              <img src={Delete} alt="" />
            </IconButton>
          </Box>
        ) : (
          ""
        )}
      </Box>
      <ShowDocumentModal file={file} open={open} onClose={handleClose} />
    </Box>
  );
};

export default TaskFileUploading;
