import React from "react";

import { Grid, Box, Typography } from "@mui/material";

import LoginPageImage from "../../../assets/Clinic/Registration/adminbanner.png";
import Bcircle from "../../../assets/Clinic/Registration/Blob1.png";

import SmartdvmLogo from "../../../assets/logo-white.png";

const LoginContainer = ({ children }) => {
  return (
    <Grid container xs={12} sx={{ height: "100vh" }}>
      <Grid
        items
        xs={0}
        sm={6}
        sx={{
          backgroundColor: "#009EED",
          display: { xs: "none", sm: "block" },
        }}
      >
        <Box>
          <img
            src={SmartdvmLogo}
            alt="smartdvm-logo"
            style={{
              marginLeft: "3rem",
              marginTop: "1rem",
              maxHeight: "12em",
              maxWidth: "14em",
              cursor: "pointer",
              paddingBottom: "1rem",
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: { sm: 400, md: 700 },
              marginBottom: { sm: "8px", md: "1.5rem" },
              color: "#2850AF",
              textAlign: "center",
            }}
          >
            Transform your practice <br></br> and enhance{" "}
            <span style={{ color: "#FFF" }}>patient care</span> <br></br>
            <span>with our solution</span>
          </Typography>
          <Box
            sx={{
              overflow: "hidden",
              position: "relative",
              marginTop: "1rem",
              backgroundImage: `url(${Bcircle})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "bottom center",
              backgroundSize: "21rem",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              src={LoginPageImage}
              alt="smartdvm-logo"
              style={{ height: "55vh" }}
            />
          </Box>
        </Box>
      </Grid>

      <Grid
        items
        xs={12}
        sm={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {children}
      </Grid>
    </Grid>
  );
};

export default LoginContainer;
