import { CLINIC_LINKS } from "../../links/clinicLinks";

export const contentTypesList = [
  {
    id: 7,
    app_label: "users",
    model: "clinicstaff",
    title: "Staff",
    group: "USER",
    read_perm_links: [CLINIC_LINKS.STAFF_OVERVIEW, CLINIC_LINKS.USERS_STAFF],
    write_perm_links: [
      CLINIC_LINKS.ADD_NEW_STAFF,
      CLINIC_LINKS.STAFF_OVERVIEW_EDIT,
    ],
  },

  {
    id: 9,
    app_label: "users",
    model: "clinicclient",
    title: "Client",
    group: "USER",
    read_perm_links: [CLINIC_LINKS.CLIENT_LIST, CLINIC_LINKS.PATIENT_DETAILS],
    write_perm_links: [
      CLINIC_LINKS.CLIENT_PERSONAL_INFORMATION,
      CLINIC_LINKS.EDIT_PERSONAL_INFO,
    ],
  },
  {
    id: 10,
    app_label: "account",
    model: "account",
    title: "Accounts",
    group: "ACCOUNT",
    read_perm_links: [CLINIC_LINKS.ACCOUNTS, CLINIC_LINKS.ACCOUNT_DETAIL],
    write_perm_links: [
      CLINIC_LINKS.ADD_ACCOUNT,
      CLINIC_LINKS.ADD_EXPENSES_ACCOUNT_ITEMS,
    ],
  },

  {
    id: 13,
    app_label: "app_settings",
    model: "cliniccagetype",
    title: "Appointment Settings",
    group: "SETTINGS",
    read_perm_links: [
      CLINIC_LINKS.CAGESIZE_SETTINGS,
      CLINIC_LINKS.CAGETYPE_SETTINGS,
      CLINIC_LINKS.ADMISSION_TYPE_SETTINGS,
    ],
    write_perm_links: [
      CLINIC_LINKS.CAGESIZE_SETTINGS,
      CLINIC_LINKS.CAGETYPE_SETTINGS,
      CLINIC_LINKS.ADMISSION_TYPE_SETTINGS,
    ],
  },

  {
    id: 108,
    app_label: "app_settings",
    model: "clinicsoapinitialcomplaint",
    title: "Clinical Item Settings",
    group: "SETTINGS",
    read_perm_links: [
      CLINIC_LINKS.ASSESSMENT_DIAGNOSIS,
      CLINIC_LINKS.VITAL_FINDINGS,
      CLINIC_LINKS.INITIAL_COMPLAINTS,
    ],
    write_perm_links: [
      CLINIC_LINKS.ASSESSMENT_DIAGNOSIS,
      CLINIC_LINKS.VITAL_FINDINGS,
      CLINIC_LINKS.INITIAL_COMPLAINTS,
    ],
  },
  {
    id: 108,
    app_label: "app_settings",
    model: "clinicleavetype",
    title: "HR Settings",
    group: "SETTINGS",
    read_perm_links: [CLINIC_LINKS.LEAVE_SETTINGS],
    write_perm_links: [CLINIC_LINKS.LEAVE_SETTINGS],
  },
  {
    id: 108,
    app_label: "product",
    model: "clinicproductcategory",
    title: "Inventory Settings",
    group: "SETTINGS",
    read_perm_links: [
      CLINIC_LINKS.INVENTORY_CATEGORY_SETTINGS,
      CLINIC_LINKS.PRODUCT_SUPPLIER_SETTINGS,
    ],
    write_perm_links: [
      CLINIC_LINKS.INVENTORY_CATEGORY_SETTINGS,
      CLINIC_LINKS.PRODUCT_SUPPLIER_SETTINGS,
    ],
  },
  {
    id: 108,
    app_label: "patient",
    model: "specie",
    title: "Patient Settings",
    group: "SETTINGS",
    read_perm_links: [
      CLINIC_LINKS.BREED_SETTINGS,
      CLINIC_LINKS.COLOR_SETTINGS,
      CLINIC_LINKS.SEX_SETTINGS,
      CLINIC_LINKS.SPECIES_SETTINGS,
    ],
    write_perm_links: [
      CLINIC_LINKS.BREED_SETTINGS,
      CLINIC_LINKS.COLOR_SETTINGS,
      CLINIC_LINKS.SEX_SETTINGS,
      CLINIC_LINKS.SPECIES_SETTINGS,
    ],
  },
  {
    id: 108,
    app_label: "clinic",
    model: "clinicdepartment",
    title: "Staff Settings",
    group: "SETTINGS",
    read_perm_links: [
      CLINIC_LINKS.DEPARTMENT_SETTINGS,
      CLINIC_LINKS.PERMISSIONS_SETTINGS,
      CLINIC_LINKS.TITLE_SETTINGS,
    ],
    write_perm_links: [
      CLINIC_LINKS.DEPARTMENT_SETTINGS,
      CLINIC_LINKS.PERMISSIONS_SETTINGS,
      CLINIC_LINKS.TITLE_SETTINGS,
    ],
  },
  {
    id: 108,
    app_label: "notification",
    model: "emailtemplate",
    title: "Messaging Settings",
    group: "SETTINGS",
    read_perm_links: [],
    write_perm_links: [],
  },
  {
    id: 108,
    app_label: "clinic",
    model: "clinicpaymentinfo",
    title: "Payment Integration Settings",
    group: "SETTINGS",
    read_perm_links: [CLINIC_LINKS.PAYMENT_GATEWAY],
    write_perm_links: [CLINIC_LINKS.PAYMENT_GATEWAY],
  },

  {
    id: 22,
    app_label: "availability",
    model: "staffavailability",
    title: "Availability",
    group: "USER",
    read_perm_links: [CLINIC_LINKS.STAFF_AVAILABILITY],
    write_perm_links: [CLINIC_LINKS.AVAILABILITY_SET_TIME],
  },

  {
    id: 38,
    app_label: "lab",
    model: "test",
    title: "Laboratory Test",
    group: "LABORATORY",
    read_perm_links: [
      CLINIC_LINKS.LAB_TEST_LIST,
      CLINIC_LINKS.TEST_DETAILS,
      CLINIC_LINKS.IMAGING,
      CLINIC_LINKS.IMAGING_DETAILS,
    ],
    write_perm_links: [
      CLINIC_LINKS.CREATE_TEST,
      CLINIC_LINKS.EDIT_LAB_TEST,
      CLINIC_LINKS.ADD_IMAGING,
    ],
  },
  {
    id: 41,
    app_label: "lab",
    model: "labtestresult",
    title: "Lab Test Result",
    group: "LABORATORY",
    read_perm_links: [
      CLINIC_LINKS.LAB_TEST_RESULT_LIST,
      CLINIC_LINKS.TEST_RESULT_DETAILS,
      CLINIC_LINKS.TEST_RESULT_DETAILS_PRINT,
    ],
    write_perm_links: [
      CLINIC_LINKS.ADD_TEST_RESULT,
      CLINIC_LINKS.TEST_RESULT_EDIT,
    ],
  },
  {
    id: 43,
    app_label: "leave",
    model: "leaverequest",
    title: "Leave",
    group: "HR",
    read_perm_links: [CLINIC_LINKS.STAFF_LEAVE],
    write_perm_links: [],
  },

  {
    id: 47,
    app_label: "notification",
    model: "notification",
    title: "Notification",
    group: "NOTIFICATION",
    read_perm_links: [
      CLINIC_LINKS.NOTIFICATION_LIST,
      CLINIC_LINKS.NOTIFICATION_BROADCAST_LIST,
      CLINIC_LINKS.NOTIFICATION_ACTIVITY_LOGS,
    ],
    write_perm_links: [CLINIC_LINKS.NOTIFICATION_CREATE_BROADCAST],
  },

  {
    id: 49,
    app_label: "notification",
    model: "broadcast",
    title: "Broadcast",
    group: "NOTIFICATION",
    read_perm_links: [
      CLINIC_LINKS.NOTIFICATION_LIST,
      CLINIC_LINKS.NOTIFICATION_BROADCAST_LIST,
      CLINIC_LINKS.NOTIFICATION_ACTIVITY_LOGS,
    ],
    write_perm_links: [CLINIC_LINKS.NOTIFICATION_CREATE_BROADCAST],
  },

  {
    id: 51,
    app_label: "patient",
    model: "patient",
    title: "Patient",
    group: "USER",
    read_perm_links: [CLINIC_LINKS.PATIENT_DETAILS, CLINIC_LINKS.PATIENT_VIEW],
    write_perm_links: [
      CLINIC_LINKS.ADD_CLIENT_PATIENT,
      CLINIC_LINKS.EDIT_PATIENT_INFO,
      CLINIC_LINKS.PATIENTS_MEDICAL_RECORDS,
    ],
  },
  {
    id: 58,
    app_label: "payments",
    model: "invoice",
    title: "Invoice and Payments",
    group: "ACCOUNT",
    read_perm_links: [CLINIC_LINKS.INVOICE, CLINIC_LINKS.INVOICE_DETAILS],
    write_perm_links: [
      CLINIC_LINKS.INVOICE_EDIT,
      CLINIC_LINKS.ADD_INVOICE,
      CLINIC_LINKS.INVOICE_PAYMENT_METHOD,
      CLINIC_LINKS.PAYMENT,
    ],
  },
  {
    id: 60,
    app_label: "payments",
    model: "statementofaccount",
    title: "Statement Of Account",
    group: "ACCOUNT",
    read_perm_links: [
      CLINIC_LINKS.STATEMENT_OF_ACCOUNT,
      CLINIC_LINKS.STATEMENT_OF_ACCOUNT_DETAILS,
    ],
    write_perm_links: [CLINIC_LINKS.ADD_STATEMENT],
  },
  {
    id: 64,
    app_label: "payroll",
    model: "payroll",
    title: "Payroll",
    group: "HR",
    read_perm_links: [CLINIC_LINKS.PAYROLL, CLINIC_LINKS.PAYROLL_DETAIL],
    write_perm_links: [
      CLINIC_LINKS.ADD_PAYROLL,
      CLINIC_LINKS.SUBMIT_PAYROLL,
      CLINIC_LINKS.PAYROLL_EDIT,
    ],
  },
  {
    id: 65,
    app_label: "payroll",
    model: "payslip",
    title: "Payslip",
    group: "HR",
    read_perm_links: [CLINIC_LINKS.PAYSLIP_DETAIL],
    write_perm_links: [CLINIC_LINKS.ADD_PAYSLIP],
  },
  // {
  //   id: 70,
  //   app_label: "product",
  //   model: "product",
  //   title: "Medicine",
  //   read_perm_links: [
  //     CLINIC_LINKS.MEDICINE_LIST,
  //     CLINIC_LINKS.MEDICINE_DETAILS,
  //     // CLINIC_LINKS.CONTROLLED_SUBSTANCE,
  //     // CLINIC_LINKS.CONTROLLED_SUBSTANCE_DETAILS,
  //     // CLINIC_LINKS.DIAGNOSIS_REPORT,
  //     // CLINIC_LINKS.DIAGNOSIS_REPORT_DETAIL,
  //   ],
  //   write_perm_links: [CLINIC_LINKS.ADD_MEDICINE, CLINIC_LINKS.EDIT_MEDICINE],
  // },
  {
    id: 70,
    app_label: "product",
    model: "product",
    title: "Inventory (Product & Medicine)",
    group: "STOCK",
    read_perm_links: [
      CLINIC_LINKS.INVENTORY,
      CLINIC_LINKS.INVENTORY_DETAILS,
      CLINIC_LINKS.PRODUCT_LIST,
      CLINIC_LINKS.PRODUCT_DETAILS,
      CLINIC_LINKS.MEDICINE_LIST,
      CLINIC_LINKS.MEDICINE_DETAILS,
      CLINIC_LINKS.CONTROLLED_SUBSTANCE,
      CLINIC_LINKS.CONTROLLED_SUBSTANCE_DETAILS,
      CLINIC_LINKS.DIAGNOSIS_REPORT,
      CLINIC_LINKS.DIAGNOSIS_REPORT_DETAIL,
    ],
    write_perm_links: [
      CLINIC_LINKS.ADD_INVENTORY,
      CLINIC_LINKS.EDIT_INVENTORY,
      CLINIC_LINKS.ADD_PRODUCT,
      CLINIC_LINKS.EDIT_PRODUCT,
      CLINIC_LINKS.ADD_MEDICINE,
      CLINIC_LINKS.EDIT_MEDICINE,
    ],
  },

  {
    id: 71,
    app_label: "product",
    model: "service",
    title: "Non Inventory",
    group: "STOCK",
    read_perm_links: [
      CLINIC_LINKS.NON_INVENTORY_DETAIL,
      CLINIC_LINKS.INVENTORY_LIST,
    ],
    write_perm_links: [
      CLINIC_LINKS.EDIT_NON_INVENTORY,
      CLINIC_LINKS.ADD_NEW_INVENTORY,
    ],
  },

  {
    id: 81,
    app_label: "soap",
    model: "appointment",
    title: "Appointment",
    group: "APPOINTMENT",
    read_perm_links: [
      CLINIC_LINKS.APPOINTMENT_LIST,
      CLINIC_LINKS.BOARDING_LIST,
      CLINIC_LINKS.APPOINTMENT_DETAIL_OVERVIEW,
      CLINIC_LINKS.APPOINTMENT_DETAIL_MEDICAL,
      CLINIC_LINKS.APPOINTMENT_DAILY_LIST,
      CLINIC_LINKS.SOAP_ESTIMATED_PAYMENT_PRINT_VIEW,
    ],
    write_perm_links: [
      CLINIC_LINKS.APPOINTMENT_RESCHEDULE_BOARDING,
      CLINIC_LINKS.APPOINTMENT_RESCHEDULE,
      CLINIC_LINKS.APPOINTMENT_CREATE,
    ],
  },
  {
    id: 82,
    app_label: "soap",
    model: "assessment",
    title: "SOAP Assessment",
    group: "APPOINTMENT",
    read_perm_links: [CLINIC_LINKS.SOAP_FORM_ASSESMENT],
    write_perm_links: [
      CLINIC_LINKS.SOAP_FORM_ASSESMENT_EDIT,
      CLINIC_LINKS.SOAP_FORM_ASSESMENT,
    ],
  },
  {
    id: 83,
    app_label: "soap",
    model: "objective",
    title: "SOAP Objective",
    group: "APPOINTMENT",
    read_perm_links: [CLINIC_LINKS.SOAP_FORM_OBJECTIVE],
    write_perm_links: [
      CLINIC_LINKS.SOAP_FORM_OBJECTIVE_EDIT,
      CLINIC_LINKS.SOAP_FORM_OBJECTIVE,
    ],
  },
  {
    id: 84,
    app_label: "soap",
    model: "plan",
    title: "SOAP Plan",
    group: "APPOINTMENT",
    read_perm_links: [CLINIC_LINKS.SOAP_ESTIMATED_PAYMENT],
    write_perm_links: [
      CLINIC_LINKS.SOAP_FORM_PLAN_EDIT,
      CLINIC_LINKS.SOAP_FORM_PLAN,
    ],
  },

  {
    id: 87,
    app_label: "soap",
    model: "subjective",
    title: "SOAP Subjective",
    group: "APPOINTMENT",
    read_perm_links: [CLINIC_LINKS.SOAP_FORM_SUBJECTIVE],
    write_perm_links: [
      CLINIC_LINKS.SOAP_FORM_SUBJECTIVE_EDIT,
      CLINIC_LINKS.SOAP_FORM_SUBJECTIVE,
    ],
  },

  {
    id: 92,
    app_label: "tasks",
    model: "task",
    title: "Tasks",
    group: "UTILITY",
    read_perm_links: [CLINIC_LINKS.TASK, CLINIC_LINKS.TASK_DETAILS],
    write_perm_links: [CLINIC_LINKS.ADD_NEW_TASK, CLINIC_LINKS.EDIT_NEW_TASK],
  },
  {
    id: 105,
    app_label: "availability",
    model: "staffworkinghours",
    title: "Working Hours",
    group: "USER",
    read_perm_links: [CLINIC_LINKS.STAFF_WORKING_HOURS],
    write_perm_links: [
      CLINIC_LINKS.STAFF_WORKING_HOURS_EDIT,
      CLINIC_LINKS.STAFF_WORKING_HOURS,
      CLINIC_LINKS.AVAILABILITY_SET_TIME,
    ],
  },
  {
    id: 106,
    app_label: "reviews",
    model: "review",
    title: "Report",
    group: "REPORT",
    read_perm_links: [
      CLINIC_LINKS.ACCOUNT_RECEIVABLE,
      CLINIC_LINKS.CONTROLLED_SUBSTANCE,
      CLINIC_LINKS.CONTROLLED_SUBSTANCE_DETAILS,
      CLINIC_LINKS.INVENTORY_BILLABLE_ITEMS,
      CLINIC_LINKS.SALES_OF_DAYS,
      CLINIC_LINKS.PRACTICE_TRENDS,
    ],
    write_perm_links: [],
  },
  {
    id: 106,
    app_label: "records",
    model: "medicaldocument",
    title: "Medical Record",
    group: "APPOINTMENT",
    read_perm_links: [],
    write_perm_links: [],
  },
  {
    id: 38,
    app_label: "lab",
    model: "testanalysistype",
    title: "Laboratory Settings",
    group: "SETTINGS",
    read_perm_links: [
      CLINIC_LINKS.LAB_FLAG_SETTINGS,
      CLINIC_LINKS.LAB_METHODOLOGY_SETTINGS,
      CLINIC_LINKS.LAB_SAMPLE_TYPE_SETTINGS,
      CLINIC_LINKS.LAB_LOCATION_SETTINGS,
      CLINIC_LINKS.LAB_TEST_TYPE_SETTINGS,
    ],
    write_perm_links: [
      CLINIC_LINKS.LAB_FLAG_SETTINGS,
      CLINIC_LINKS.LAB_METHODOLOGY_SETTINGS,
      CLINIC_LINKS.LAB_SAMPLE_TYPE_SETTINGS,
      CLINIC_LINKS.LAB_LOCATION_SETTINGS,
      CLINIC_LINKS.LAB_TEST_TYPE_SETTINGS,
    ],
  },
  // {
  //   id: 7,
  //   app_label: "users",
  //   model: "clinicstaff",
  //   title: "Staff Settings",
  //   group: "SETTINGS",
  //   read_perm_links: [
  //     CLINIC_LINKS.STAFF_SETTINGS,
  //     CLINIC_LINKS.ROLE_SETTINGS,
  //     CLINIC_LINKS.ROLE_SETTINGS_DETAIL_PERM,
  //     ,
  //     CLINIC_LINKS.ROLE_STAFF_LIST_SETTINGS,
  //     CLINIC_LINKS.DEPARTMENT_SETTINGS,
  //     CLINIC_LINKS.TITLE_SETTINGS,
  //   ],
  //   write_perm_links: [
  //     CLINIC_LINKS.STAFF_SETTINGS,
  //     CLINIC_LINKS.ROLE_SETTINGS,
  //     CLINIC_LINKS.ROLE_SETTINGS_DETAIL_PERM,
  //     ,
  //     CLINIC_LINKS.ROLE_STAFF_LIST_SETTINGS,
  //     CLINIC_LINKS.DEPARTMENT_SETTINGS,
  //     CLINIC_LINKS.TITLE_SETTINGS,
  //   ],
  // },
];
// context type sorting
export const contextTypeSortedList = contentTypesList.sort((a, b) => {
  const titleA = a.title.toUpperCase();
  const titleB = b.title.toUpperCase();
  if (titleA < titleB) {
    return -1;
  }
  if (titleA > titleB) {
    return 1;
  }
  return 0;
});
