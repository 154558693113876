import React, { createContext } from "react";

const TaskContext = createContext();

export const TaskProvider = ({ children }) => {
  const initialTaskValues = {
    title: null,
    description: null,
  };
  const [taskData, setTaskData] = React.useState(initialTaskValues);
  const [openTask, setOpenTask] = React.useState(false);

  const toggleTask = () => setOpenTask(!openTask);
  const clearTaskData = () => setTaskData(initialTaskValues);

  return (
    <TaskContext.Provider
      value={{
        taskData,
        setTaskData,
        openTask,
        setOpenTask,
        toggleTask,
        clearTaskData,
      }}
    >
      {children}
    </TaskContext.Provider>
  );
};
export default TaskContext;
