import React from "react";
import {
  Button,
  Typography,
  Grid,
  Box,
  TextField,
  InputAdornment,
  MenuItem,
  Alert,
  IconButton,
} from "@mui/material";
import TaskFileUpload from "../../component/Clinic/Task/TaskFileUpload";
// IMAGE ASSETS

import ArrowRight from "../../assets/appointment/arrow-right.png";
import Account from "../../assets/Clinic/Staff/Account.png";
import { useNavigate } from "react-router-dom";

import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  emptyDateReject,
  savableDate,
  savableDateTimeDateInputOnly,
} from "../../features/readableDate";
import {
  getClinicStaffList,
  handleTaskAttachmentFileUpdate,
  handleTaskCreate,
} from "../../api/API";
import { ACTION_TYPES } from "../../reducers/ActionTypes";
import { CLINIC_LINKS } from "../../links/clinicLinks";
import { INITIAL_STATE, postReducer } from "../../reducers/postReducer";
import capitalizeFirstLetter from "../../features/capitalizeFirstLetter";
import ClinicAuthContext from "../../context/clinicAuthContext";
import { ButtonSubmitProgress } from "../../component/ProgressIndicators/PageProgress";
import { Close } from "@mui/icons-material";
import TaskContext from "../../context/taskContext";
import dayjs from "dayjs";

const AddNewTask = () => {
  const today = new Date();
  const initialState = {
    clinic: null,
    assigned_by: null,
    title: null,
    assigned_to: null,
    due_date: dayjs(today),
    start_date: dayjs(today),
    description: null,
    attachments: [],
  };
  const { staff, user, clinic } = React.useContext(ClinicAuthContext);
  const { toggleTask, taskData, clearTaskData } = React.useContext(TaskContext);
  const [values, setValues] = React.useState(initialState);
  const [staffList, setStaffList] = React.useState([]);
  const [addedFiles, setAddedFiles] = React.useState([]);

  const [state, dispatch] = React.useReducer(postReducer, INITIAL_STATE);
  const navigate = useNavigate();
  const handleBack = () => navigate(CLINIC_LINKS.TASK);
  const handleChange = (event) => {
    dispatch({ type: ACTION_TYPES.RESET });
    setValues({ ...values, [event.target.name]: event.target.value });
  };
  const taskCreatedSuccessCallback = (data) => {
    dispatch({ type: ACTION_TYPES.FETCH_SUCCESS, payload: data });
    clearTaskData();
    addedFiles.map((file) => {
      const newData = {
        task: data.id,
      };
      setAddedFiles(newData);
      handleTaskAttachmentFileUpdate(file.id, newData);
    });
  };
  const taskCreateErrorCallback = () => {
    dispatch({ type: ACTION_TYPES.FETCH_ERROR });
  };
  const handleSubmit = () => {
    dispatch({ type: ACTION_TYPES.FETCH_START });
    handleTaskCreate(
      values,
      taskCreatedSuccessCallback,
      taskCreateErrorCallback
    );
  };
  const handlePreloadData = () => {
    setValues({
      ...values,
      clinic: staff.clinic.id,
      assigned_by: staff.id,
      title: taskData && taskData.title,
      description: taskData && taskData.description,
    });
  };
  const staffSuccessCallback = (data) => {
    setStaffList(data);
  };
  const staffErrorCallBack = () => {};
  React.useEffect(() => {
    if (staff) {
      handlePreloadData();
      getClinicStaffList(staffSuccessCallback, staffErrorCallBack, clinic.id);
    }
  }, [staff]);

  return (
    <Grid
      item
      xs={12}
      sx={{
        marginX: "10px",
        backgroundColor: "#ffffff",
        borderRadius: "16px",
      }}
    >
      <Box
        sx={{
          marginY: "20px",
          display: "flex",
          justifyContent: "space-between",
          boxShadow: 3,
          borderBottom: "1px dotted #aeaeae",
        }}
      >
        <Typography
          sx={{
            color: "#39484F",
            fontWeight: "500",
            fontSize: "20px",
          }}
        >
          Add Task
        </Typography>

        <IconButton onClick={toggleTask}>
          <Close />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
          }}
        >
          <TextField
            name="title"
            id="outlined-multiline-static"
            label="Title"
            value={values.title}
            onChange={handleChange}
            fullWidth
            inputProps={{
              startAdornment: (
                <InputAdornment position="start"></InputAdornment>
              ),
            }}
            sx={{
              borderRadius: "8px",

              m: 1,
            }}
            InputProps={{
              style: { borderRadius: "10px" },
            }}
          />
          <TextField
            select
            name="assigned_to"
            label="Assign to"
            id="outlined-start-adornment"
            fullWidth
            sx={{ m: 1 }}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={Account} alt="adorment" />
                </InputAdornment>
              ),
              style: { borderRadius: "10px" },
            }}
          >
            {staffList.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {capitalizeFirstLetter(option.user.first_name)}{" "}
                {capitalizeFirstLetter(option.user.last_name)}
              </MenuItem>
            ))}
          </TextField>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              label="Start Date"
              name="start_date"
              value={values.start_date}
              onChange={(value) => {
                setValues({
                  ...values,
                  start_date: savableDateTimeDateInputOnly(
                    emptyDateReject(value)
                  ),
                });
              }}
              disablePast
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  sx={{
                    m: 1,

                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: "10px",
                      },
                    },
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
              label="Due Date"
              name="due_date"
              value={values.due_date}
              onChange={(value) => {
                setValues({
                  ...values,
                  due_date: savableDateTimeDateInputOnly(
                    emptyDateReject(value)
                  ),
                });
              }}
              disablePast
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  sx={{
                    m: 1,

                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: "10px",
                      },
                    },
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <TextField
            name="description"
            value={values.description}
            multiline
            rows={3}
            id="outlined-multiline-static"
            label="Description"
            onChange={handleChange}
            fullWidth
            sx={{
              borderRadius: "8px",
            }}
            InputProps={{
              style: { borderRadius: "10px" },
              startAdornment: (
                <InputAdornment position="start"></InputAdornment>
              ),
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            marginTop: "39px",
          }}
        >
          {/* <TaskFileUpload setAddedFiles={setAddedFiles} /> */}
        </Box>
      </Box>
      {state.error ? <Alert severity="error"> Error Occured</Alert> : ""}
      {state.post ? (
        <Alert severity="success"> Task Created Successfully</Alert>
      ) : (
        ""
      )}
      <Box
        sx={{
          marginTop: "20px",
          marginBottom: "50px",
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <Button
          sx={{
            paddingX: "28px",
            paddingY: "13px",
            borderRadius: "8px",
          }}
          fullWidth
          variant="contained"
          onClick={handleSubmit}
        >
          {state.loading ? <ButtonSubmitProgress /> : ""}
          Add Task
        </Button>
      </Box>
    </Grid>
  );
};

export default AddNewTask;
