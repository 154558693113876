import React, { createContext, useState } from "react";
import { contentTypesList } from "../component/Permissions/ContentTypesList";
import PermAlertModal from "../component/PermAlert";
import { Box } from "@mui/material";
import { CLINIC_LINKS } from "../links/clinicLinks";

const StaffRolePermContext = createContext(null);

const EmptyComponent = () => {
  return <Box></Box>;
};
export const StaffRolePermProvider = ({ children }) => {
  const [isPermitted, setIsPermitted] = useState(false);
  const [staffPerms, setStaffPerms] = useState([]);
  const [staffData, setStaffData] = useState([]);

  const handlePermModalOpen = () => setIsPermitted(!isPermitted);

  const handlePermUpdateContectForUI = (data) => {
    const newData = data.role_perms.map((perm, index) => {
      let contentTypeMain = contentTypesList.find(
        (p) =>
          p.app_label === perm.content_type.app_label &&
          p.model === perm.content_type.model
      );
      if (contentTypeMain) {
        let perm_object = {
          ...perm,
          title: contentTypeMain.title,
          read_perm_links: contentTypeMain.read_perm_links,
          write_perm_links: contentTypeMain.write_perm_links,
        };
        if (!perm_object.title || perm_object.title === "") {
        } else {
          return perm_object;
        }
      }
    });
    setStaffPerms(newData);
  };

  /**
   * Checks if the staff is permitted to use a link
   * - if not permitted it will stop the page from loading
   */
  const staffPermCheck = (link, component) => {
    const findLinkInReadPerms = staffPerms.find(
      (perm) =>
        perm && perm.read_perm_links && perm.read_perm_links.includes(link)
    );
    const findLinkInWritePerms = staffPerms.find(
      (perm) =>
        perm && perm.write_perm_links && perm.write_perm_links.includes(link)
    );
    if (staffData.is_admin) {
      return component;
    } else if (link === CLINIC_LINKS.DASHBOARD) {
      return component;
    } else if (findLinkInReadPerms && findLinkInReadPerms.read_perm) {
      return component;
    } else if (findLinkInWritePerms && findLinkInWritePerms.write_perm) {
      return component;
    } else {
      // handlePermModalOpen()
      return EmptyComponent;
    }
  };

  const staffPermCheckReturnNull = (link, component) => {
    const findLinkInReadPerms = staffPerms.find(
      (perm) =>
        perm &&
        perm.read_perm_links &&
        perm.read_perm_links.includes(link) &&
        perm.read_perm
    );
    const findLinkInWritePerms = staffPerms.find(
      (perm) =>
        perm &&
        perm.write_perm_links &&
        perm.write_perm_links.includes(link) &&
        perm.write_perm
    );
    if (staffData.is_admin) {
      return component;
    } else if (link === CLINIC_LINKS.DASHBOARD) {
      return component;
    } else if (findLinkInReadPerms && findLinkInReadPerms.read_perm) {
      return component;
    } else if (findLinkInWritePerms && findLinkInWritePerms.write_perm) {
      return component;
    } else {
      // handlePermModalOpen()
      return null;
    }
  };

  /**
   * Checks if the staff is permitted to use a list of links
   * - if not permitted it will stop the page from loading
   */
  const staffPermCheckMultiLevel = (linkList, component) => {
    const findLinkInReadPerms = staffPerms.find((perm) =>
      linkList.find(
        (linkItem) =>
          perm &&
          perm.read_perm_links &&
          perm.read_perm_links.includes(linkItem.link) &&
          perm.read_perm
      )
    );
    const findLinkInWritePerms = staffPerms.find((perm) =>
      linkList.find(
        (linkItem) =>
          perm &&
          perm.write_perm_links &&
          perm.write_perm_links.includes(linkItem.link) &&
          perm.write_perm
      )
    );
    if (staffData.is_admin) {
      return component;
    } else if (linkList.includes(CLINIC_LINKS.DASHBOARD)) {
      return component;
    } else if (findLinkInReadPerms && findLinkInReadPerms.read_perm) {
      return component;
    } else if (findLinkInWritePerms && findLinkInWritePerms.write_perm) {
      return component;
    } else {
      // handlePermModalOpen()
      return EmptyComponent;
    }
  };

  const checkPermViaAppLabelAndAppModel = (app_label, app_model, component) => {
    const findLinkInReadPerms = staffPerms.find(
      (perm) =>
        perm &&
        perm.content_type.app_label === app_label &&
        perm.content_type.model === app_model &&
        perm.read_perm
    );
    const findLinkInWritePerms = staffPerms.find(
      (perm) =>
        perm &&
        perm.content_type.app_label === app_label &&
        perm.content_type.model === app_model &&
        perm.write_perm
    );
    if (staffData.is_admin) {
      return component;
    } else if (findLinkInReadPerms && findLinkInReadPerms.read_perm) {
      return component;
    } else if (findLinkInWritePerms && findLinkInWritePerms.write_perm) {
      return component;
    } else {
      return EmptyComponent;
    }
  };

  const checkPermViaAppLabel = (app_label, component) => {
    const findLinkInReadPerms = staffPerms.find(
      (perm) =>
        perm && perm.content_type.app_label === app_label && perm.read_perm
    );
    const findLinkInWritePerms = staffPerms.find(
      (perm) =>
        perm && perm.content_type.app_label === app_label && perm.write_perm
    );
    if (staffData.is_admin) {
      return component;
    } else if (findLinkInReadPerms && findLinkInReadPerms.read_perm) {
      return component;
    } else if (findLinkInWritePerms && findLinkInWritePerms.write_perm) {
      return component;
    } else {
      return EmptyComponent;
    }
  };

  return (
    <StaffRolePermContext.Provider
      value={{
        staffData,
        setStaffData,
        setStaffPerms,
        staffPermCheck,
        staffPermCheckReturnNull,
        staffPermCheckMultiLevel,
        handlePermUpdateContectForUI,
        checkPermViaAppLabelAndAppModel,
        checkPermViaAppLabel,
      }}
    >
      {children}
      <PermAlertModal open={isPermitted} onClose={handlePermModalOpen} />
    </StaffRolePermContext.Provider>
  );
};
export default StaffRolePermContext;
