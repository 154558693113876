export const reformatNumber = (number) => {
  if (number < 10) {
    return `0${number}`;
  } else {
    return number;
  }
};

export const readableDate = (date) => {
  let d = new Date(date);
  let da = new Date(
    `${d.getUTCFullYear()}-${d.getUTCMonth() + 1}-${d.getUTCDate()}`
  );
  if (date) {
    return da.toDateString();
  } else {
    return "Nill";
  }
};
export const readableLocalDate = (date) => {
  let d = new Date(date);
  const year = d.getUTCFullYear();
  const month = d.getUTCMonth() + 1;
  const day = d.getUTCDate();
  return `${year}-${reformatNumber(month)}-${reformatNumber(day)}`;
};

export const readableTime = (date) => {
  if (!date) {
    return "--:--";
  }
  let d = new Date(date);
  let hours = d.getUTCHours();
  let minutes = d.getUTCMinutes();
  let prepand = hours < 12 ? "AM" : "PM";
  return `${reformatNumber(hours > 12 ? hours - 12 : hours)}:${reformatNumber(
    minutes
  )} ${prepand}`;
};
export const getTime = (date) => {
  let d = new Date(date);
  let hours = d.getUTCHours();
  let minutes = d.getUTCMinutes();
  return `${reformatNumber(hours > 12 ? hours - 12 : hours)}:${reformatNumber(
    minutes
  )}`;
};
export const getDate = (date) => {
  let d = new Date(date);
  let day = d.getUTCDate();
  let month = d.getUTCMonth() + 1;
  let year = d.getUTCFullYear() + 1;
  return `${reformatNumber(year)}-${reformatNumber(month)}-${reformatNumber(
    day
  )}`;
};
export const getDateTime = (date) => {
  let d = new Date(date);
  let hours = d.getUTCHours();
  let minutes = d.getUTCMinutes();
  let day = d.getUTCDate();
  let month = d.getUTCMonth() + 1;
  let year = d.getUTCFullYear();
  return `${reformatNumber(year)}-${reformatNumber(month)}-${reformatNumber(
    day
  )}T${reformatNumber(hours)}:${reformatNumber(minutes)}`;
};
export const readableDateTime = (date) => {
  let d = new Date(date);
  return `${d.toDateString()} ${d.toTimeString()}`;
};
export const emptyDateReject = (date) => {
  if (date === null || date === "") {
    alert("Date/Time Field Cannot be Blank/ Null");
    return 1;
  } else {
    return date;
  }
};

export const savableDate = (date) => {
  if (date === null || date === "") {
    alert("Date/Time Field Cannot be Blank/ Null");
    return 1;
  } else {
    return `${reformatNumber(date.$y)}-${reformatNumber(
      date.$M + 1
    )}-${reformatNumber(date.$D)}`;
  }
};
export const savableMonth = (date) => {
  return `${reformatNumber(date.$M + 1)}`;
};
export const savableYear = (date) => {
  return `${reformatNumber(date.$y)}`;
};

export const savableDateTime = (date, time) => {
  return `${reformatNumber(date.$y)}-${reformatNumber(
    date.$M + 1
  )}-${reformatNumber(date.$D)}T${reformatNumber(time.$H)}:${reformatNumber(
    time.$m
  )}`;
};
export const savableDateTimeDateInputOnly = (date) => {
  return `${reformatNumber(date.$y)}-${reformatNumber(
    date.$M + 1
  )}-${reformatNumber(date.$D)}T${reformatNumber(date.$H)}:${reformatNumber(
    date.$m
  )}`;
};
export const savableTime = (time) => {
  return `${reformatNumber(time.$H)}:${reformatNumber(time.$m)}`;
};

export function dateDifference(date1, date2) {
  const rdate1 = new Date(date1);
  const rdate2 = new Date(date2);
  const diffTime = Math.abs(rdate2 - rdate1);
  const diffMin = Math.floor(diffTime / (1000 * 60));
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
  // if (day) {

  // }
  // return `${diffMin} minutes`;
}

export function timeSince(date) {
  // Math.floor(((new Date().getTime()/1000) - date))
  var d = new Date(date);
  var seconds = Math.floor((new Date() - d) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes";
  }
  return Math.floor(seconds) + " seconds";
}

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thusday",
  "Friday",
  "Saturday",
];
/**
 * This function gets us the day of a particular date
 * e.g Monday, Tuesday, Wednesday, etc
 */

export const readableDay = (date) => {
  let d = new Date(date);
  return days[d.getDay()];
};
