import Notifications from "@mui/icons-material/Notifications";
import { Box, IconButton, Paper, Popover, Typography } from "@mui/material";
import UserAvatar from "../../../assets/avatars/avatar_icon.png";
import ArrowDown from "../../../assets/Clinic/Dashboard/ArrowDown.svg";
import UserProfile from "../../../assets/Clinic/Dashboard/UserProfile.png";
import Logout from "../../../assets/Clinic/Dashboard/Logout.png";
import { useContext, useState } from "react";
import { CLINIC_LINKS } from "../../../links/clinicLinks";
import { useNavigate } from "react-router-dom";
import { imageLinkReformat } from "../../../features/imageLinkReformat";
import ClinicAuthContext from "../../../context/clinicAuthContext";
import { CLINIC_SUPER_LINKS } from "../../../links/clinicSuperLinks";

const UserDropdown = () => {
  const { staff } = useContext(ClinicAuthContext);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate(CLINIC_SUPER_LINKS.LOGIN);
  };

  const open = Boolean(anchorEl);
  const id = open ? "user-popover" : undefined;

  return (
    <div>
      <IconButton
        sx={{
          display: "flex",
          alignItems: "center",
          mx: 1,
        }}
        onClick={handleClick}
        aria-describedby={id}
      >
        <img
          style={{ height: "48px", width: "48px", borderRadius: "50%" }}
          src={
            staff.user
              ? staff.user.image
                ? imageLinkReformat(staff.user.image)
                : UserAvatar
              : UserAvatar
          }
          alt="icon"
        />
        <img style={{ paddingLeft: "15px" }} src={ArrowDown} alt="icon" />
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          mt: 1,
        }}
      >
        <Paper
          sx={{
            mr: 3,
            p: 3,
            width: { md: "183px" },
            height: { md: "78px" },
          }}
        >
          <Box>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                paddingBottom: "28px",
              }}
              onClick={() => navigate(CLINIC_SUPER_LINKS.USER_PROFILE)}
            >
              <img
                style={{ paddingRight: "9px" }}
                src={UserProfile}
                alt="icon"
              />
              View Profile
            </Typography>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                paddingBottom: "24px",
              }}
              onClick={handleLogout}
            >
              <img style={{ paddingRight: "9px" }} src={Logout} alt="icon" />
              Log Out
            </Typography>
          </Box>
        </Paper>
      </Popover>
    </div>
  );
};

export default UserDropdown;
