import axiosInstance from "../axios/post";

export const addSpecieSettings = (
  data,
  clinicId,
  successCallback,
  errorCallback
) => {
  try {
    axiosInstance
      .post(`/api/patients/specie/${clinicId}/`, data)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not create settings");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const addBreedSettings = (
  data,
  specie,
  successCallback,
  errorCallback
) => {
  try {
    axiosInstance
      .post(`/api/patients/breed/${specie}/`, data)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not create settings");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getBreedSettings = (specieId, successCallback, errorCallback) => {
  try {
    axiosInstance
      .get(`/api/patients/breed/${specieId}/`)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        console.log("could not get settings");
        errorCallback(err);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const addColorSettings = (
  data,
  clinicId,
  successCallback,
  errorCallback
) => {
  try {
    axiosInstance
      .post(`/api/patients/color/${clinicId}/`, data)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not create settings");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const addGenderSettings = (
  data,
  clinicId,
  successCallback,
  errorCallback
) => {
  try {
    axiosInstance
      .post(`/api/patients/sex/${clinicId}/`, data)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not create settings");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const addDeptSettings = (
  data,
  clinicId,
  successCallback,
  errorCallback
) => {
  try {
    axiosInstance
      .post(`/api/clinic/department/${clinicId}/`, data)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not create settings");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const addRoleSettings = (
  data,
  clinicId,
  successCallback,
  errorCallback
) => {
  try {
    axiosInstance
      .post(`/api/clinic/role/${clinicId}/`, data)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not create settings");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const vitalFindings = (
  data,
  clinicId,
  successCallback,
  errorCallback
) => {
  try {
    axiosInstance
      .post(`/api/app_settings/${clinicId}/soap_vital_findings/`, data)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not create settings");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getVitalFindings = (clinicId, successCallback, errorCallback) => {
  try {
    axiosInstance
      .get(`/api/app_settings/${clinicId}/soap_vital_findings/`)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        console.log("could not get settings");
        errorCallback(err);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};
export const deleteVitalFinding = (
  id,
  clinicId,
  successCallback,
  errorCallback
) => {
  try {
    axiosInstance
      .delete(`/api/app_settings/${clinicId}/soap_vital_findings/detail/${id}/`)
      .then((res) => {
        successCallback(id);
      })
      .catch((err) => {
        console.log("could not delete settings");
        errorCallback(err);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const addGoogleAuthCode = (
  clinicSlug,
  successCallback,
  errorCallback
) => {
  try {
    axiosInstance
      .get(`/api/notification/initiate-setup-api/${clinicSlug}/`)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        // errorCallback();
        console.log(err.response);
        console.log("could not create settings");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getGoogleOauth = (clinicSlug, successCallback, errorCallback) => {
  try {
    axiosInstance
      .get(`/api/notification/initiate-setup-api/${clinicSlug}/`)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        console.log("could not get settings");
        errorCallback(err);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const addStaffTitle = (
  data,
  clinicId,
  successCallback,
  errorCallback
) => {
  try {
    axiosInstance
      .post(`/api/app_settings/${clinicId}/staff_title/`, data)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not create settings");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getStaffTitle = (clinicId, successCallback, errorCallback) => {
  try {
    axiosInstance
      .get(`/api/app_settings/${clinicId}/staff_title/`)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        console.log("could not get settings");
        errorCallback(err);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const initialComplaint = (
  data,
  clinicId,
  successCallback,
  errorCallback
) => {
  try {
    axiosInstance
      .post(`/api/app_settings/${clinicId}/soap_complaints/`, data)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not create settings");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getInitialComplaint = (
  clinicId,
  successCallback,
  errorCallback
) => {
  try {
    axiosInstance
      .get(`/api/app_settings/${clinicId}/soap_complaints/`)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        console.log("could not get settings");
        errorCallback(err);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const deleteInitialComplaints = (
  id,
  clinicId,
  successCallback,
  errorCallback
) => {
  try {
    axiosInstance
      .delete(`/api/app_settings/${clinicId}/soap_complaints/detail/${id}/`)
      .then((res) => {
        successCallback(id);
      })
      .catch((err) => {
        errorCallback(err);
      });
  } catch (error) {
    console.log(`Error: ${error.response} `);
  }
};

export const getRoleSettings = (clinicId, successCallback, errorCallback) => {
  try {
    axiosInstance
      .get(`/api/clinic/role/${clinicId}/`)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        console.log("could not get settings");
        errorCallback(err);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getRoleSettingsDetail = (
  id,
  clinicId,
  successCallback,
  errorCallback
) => {
  try {
    axiosInstance
      .get(`/api/clinic/role/${clinicId}/detail/${id}/`)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        console.log("could not get settings");
        errorCallback(err);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};
export const getRoleSettingsDetailByName = (
  role_name,
  clinicId,
  successCallback,
  errorCallback
) => {
  try {
    axiosInstance
      .get(`/api/clinic/role_by_name/${clinicId}/detail/${role_name}/`)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        console.log("could not get settings");
        errorCallback(err);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};
export const updateRolePermSettings = (
  data,
  id,
  clinicId,
  successCallback,
  errorCallback
) => {
  try {
    axiosInstance
      .patch(`/api/clinic/role_perm/${clinicId}/detail/${id}/`, data)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        console.log("could not get settings");
        errorCallback(err);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getDeptSettings = (clinicId, successCallback, errorCallback) => {
  try {
    axiosInstance
      .get(`/api/clinic/department/${clinicId}/`)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        console.log("could not get settings");
        errorCallback(err);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getGenderSettings = (clinicId, successCallback, errorCallback) => {
  try {
    axiosInstance
      .get(`/api/patients/sex/${clinicId}/`)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        console.log("could not get settings");
        errorCallback(err);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getColorSettings = (clinicId, successCallback, errorCallback) => {
  try {
    axiosInstance
      .get(`/api/patients/color/${clinicId}/`)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        console.log("could not get settings");
        errorCallback(err);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getSpecieSettings = (clinicId, successCallback, errorCallback) => {
  try {
    axiosInstance
      .get(`/api/patients/specie/${clinicId}/`)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        console.log("could not get settings");
        errorCallback(err);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const addProductSupplier = (
  data,
  clinicId,
  successCallback,
  errorCallback
) => {
  try {
    axiosInstance
      .post(`/api/inventory/product_suppliers/${clinicId}/`, data)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not create settings");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getProductSupplier = (
  clinicId,
  successCallback,
  errorCallback
) => {
  try {
    axiosInstance
      .get(`/api/inventory/product_suppliers/${clinicId}/`)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        console.log("could not get settings");
        errorCallback(err);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getAccount = (clinicId, succuessCallback, errorCallback) => {
  try {
    axiosInstance
      .get(`/api/account/account/${clinicId}/`)
      .then((res) => {
        succuessCallback(res.data);
      })
      .catch((err) => {
        console.log("could not get account list");
        errorCallback(err);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const addAccountSetting = (
  data,
  clinicId,
  successCallback,
  errorCallback
) => {
  try {
    axiosInstance
      .post(`/api/account/account/${clinicId}/`, data)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not create settings");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const addCageTypeSetting = (
  data,
  clinicId,
  successCallback,
  errorCallback
) => {
  try {
    axiosInstance
      .post(`/api/app_settings/${clinicId}/cage_types/`, data)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not create settings");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getCageType = (clinicId, succuessCallback, errorCallback) => {
  try {
    axiosInstance
      .get(`/api/app_settings/${clinicId}/cage_types/`)
      .then((res) => {
        succuessCallback(res.data);
      })
      .catch((err) => {
        console.log("could not get account list");
        errorCallback(err);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const addCageSize = (data, clinicId, successCallback, errorCallback) => {
  try {
    axiosInstance
      .post(`/api/app_settings/${clinicId}/cage_sizes/`, data)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not create settings");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getCageSize = (clinicId, succuessCallback, errorCallback) => {
  try {
    axiosInstance
      .get(`/api/app_settings/${clinicId}/cage_sizes/`)
      .then((res) => {
        succuessCallback(res.data);
      })
      .catch((err) => {
        console.log("could not get account list");
        errorCallback(err);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getCategories = (
  clinicId,
  categoryType,
  successCallback,
  errorCallback
) => {
  try {
    axiosInstance
      .get(`/api/inventory/categories/${categoryType}/${clinicId}/`)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        console.log("could not get settings");
        errorCallback(err);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const addCategories = (
  data,
  clinicId,
  categoryType,
  successCallback,
  errorCallback
) => {
  try {
    axiosInstance
      .post(`/api/inventory/categories/${categoryType}/${clinicId}/`, data)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not create settings");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getAdmissionType = (clinicId, successCallback, errorCallback) => {
  try {
    axiosInstance
      .get(`/api/app_settings/${clinicId}/admission_types/`)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        console.log("could not get settings");
        errorCallback(err);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getDiagnosis = (clinicId, successCallback, errorCallback) => {
  try {
    axiosInstance
      .get(`/api/app_settings/${clinicId}/diagnosis/`)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        console.log("could not get settings");
        errorCallback(err);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const addAdmissionType = (
  data,
  clinicId,
  successCallback,
  errorCallback
) => {
  try {
    axiosInstance
      .post(`/api/app_settings/${clinicId}/admission_types/`, data)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not create settings");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const addDiagnosis = (
  data,
  clinicId,
  successCallback,
  errorCallback
) => {
  try {
    axiosInstance
      .post(`/api/app_settings/${clinicId}/diagnosis/`, data)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not create settings");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getContentTypes = (successCallback, errorCallback) => {
  try {
    axiosInstance
      .get(`/api/app_settings/content_types/`)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        console.log("could not get settings");
        errorCallback(err);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getCurrency = (clinicId, successCallback, errorCallback) => {
  try {
    axiosInstance
      .get(`/api/app_settings/${clinicId}/clinic_currency/`)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        console.log("could not get settings");
        errorCallback(err);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const updateCurrency = (
  data,
  clinicId,
  successCallback,
  errorCallback
) => {
  try {
    axiosInstance
      .put(`/api/app_settings/${clinicId}/clinic_currency/`, data)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not update settings");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const addPaymentGateway = (
  data,
  clinicId,
  successCallback,
  errorCallback
) => {
  try {
    axiosInstance
      .post(`/api/clinic/payment_info/${clinicId}/`, data)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not update settings");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};
export const updatePaymentGateway = (
  id,
  data,
  clinicId,
  successCallback,
  errorCallback
) => {
  try {
    axiosInstance
      .patch(`/api/clinic/payment_info/${clinicId}/detail/${id}/`, data)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not update settings");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};
export const getPaymentGatewayDetail = (
  id,
  clinicId,
  successCallback,
  errorCallback
) => {
  try {
    axiosInstance
      .get(`/api/clinic/payment_info/${clinicId}/detail/${id}/`)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get payment api settings");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getPaymentGateway = (clinicId, successCallback, errorCallback) => {
  try {
    axiosInstance
      .get(`/api/clinic/payment_info/${clinicId}/`)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get settings");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const getLeaveTypeSettings = (
  clinicId,
  successCallback,
  errorCallback
) => {
  try {
    axiosInstance
      .get(`/api/app_settings/${clinicId}/leave_types/`)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not get settings");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};
export const addLeaveType = (
  data,
  clinicId,
  successCallback,
  errorCallback
) => {
  try {
    axiosInstance
      .post(`/api/app_settings/${clinicId}/leave_types/`, data)
      .then((res) => {
        successCallback(res.data);
      })
      .catch((err) => {
        errorCallback();
        console.log(err.response);
        console.log("could not add settings leave type");
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};
