import jwt from "jwt-decode";
import axiosInstance from "./axios/post";

export const getUser = () => {
  // gets the id of the user
  const accessToken = localStorage.getItem("access_token");
  if (accessToken) {
    const decodedToken = jwt(accessToken);
    return decodedToken.user_id;
  }
  return null;
};
export const getClinicClient = () => {
  // gets the id of the user
  const clientId = localStorage.getItem("clinic_client_id");
  return clientId;
};
export const getClinicStaff = () => {
  // gets the id of the user
  const staffId = localStorage.getItem("clinic_staff_id");
  return staffId;
};

export const getClinicClientAPI = (
  handleGetUserCallback,
  handleGetUserErrorCallback,
  clinic_id
) => {
  try {
    axiosInstance
      .get(`/users/full_details/${getUser()}/${clinic_id}/`)
      .then((res) => {
        localStorage.setItem("clinic_client_id", res.data.id);
        handleGetUserCallback(res.data);
      })
      .catch((err) => {
        console.log(`Error: ${err.response} `);
        handleGetUserErrorCallback();
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

/**
 * gets the information of the login user using the id from getUser call
 * the clinic_id must be passed first
 * if id is passed Id passes is not of the clinicclient but of the clinic_client user (clinic_client.user)
 * the get user is also bringing in the id of the userlogged in (clinic_client.user)
 */
export const getFullUserInfo = (
  handleGetUserCallback,
  handleGetUserErrorCallback,
  clinic_id,
  id
) => {
  const checkID = id ? id : getUser();
  try {
    axiosInstance
      .get(`/users/full_details/${checkID}/${clinic_id}/`)
      .then((res) => {
        handleGetUserCallback(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(`Error: ${err.response} `);
        handleGetUserErrorCallback();
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const cancelClientInfo = (
  successCallBack,
  errorCallback,
  clinic_id,
  id,
  data
) => {
  try {
    axiosInstance
      .patch(`/users/full_details/${id}/${clinic_id}/`, data)
      .then((res) => {
        successCallBack(res.data);
      })
      .catch((err) => {
        console.log(`Error: ${err.response} `);
        errorCallback(err.response);
      });
  } catch (err) {
    console.log(`Error: ${err.response} `);
  }
};

export const clearUserTokens = () => {
  // clears the access and refresh tokens from the local storage
  localStorage.clear();
};

export const subscriptionCheckPass = () => {
  const subscriptionID = localStorage.getItem("subscriptionID");

  if (subscriptionID) {
  }
};
