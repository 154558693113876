import React from "react";
import { Alert, Box, Container } from "@mui/material";
import { Outlet } from "react-router-dom";

import SideNav from "../../../scomponents/main/SideNav";
import Header from "../../../scomponents/main/Header";
import Footer from "../../../scomponents/main/Footer";
import { DrawerProvider } from "../../../context/drawerContext";
import { Detector, Offline, Online } from "react-detect-offline";
import ClinicAuthContext from "../../../context/clinicAuthContext";
import TaskAutomationModal from "../../Task/TaskAutomationModal";

const DefaultLayout = ({ children, noSideNav, noOutlet }) => {
  const { clinic, activeBill, subscriptionActive } =
    React.useContext(ClinicAuthContext);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          minHeight: { xs: "150vh", md: "100vh" },
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
        }}
      >
        {!noSideNav && (
          <DrawerProvider>
            <Header />
            {subscriptionActive() && <SideNav />}
          </DrawerProvider>
        )}
        <Box
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Container
            maxWidth="xlg"
            sx={{
              mt: 10,
              ml: { xs: "auto", md: "0px" },
              mr: { xs: "auto", md: "0px" },
            }}
          >
            <Detector
              render={({ online }) => {
                if (!online) {
                  return (
                    <Alert
                      severity="warning"
                      sx={{
                        position: "fixed",
                        borderRadius: 0,
                      }}
                    >
                      You are currently offline check you network connections
                    </Alert>
                  );
                }
              }}
            />

            <Box sx={{ minHeight: "84vh" }}>
              {" "}
              {noOutlet ? children : <Outlet />}
              <TaskAutomationModal />
            </Box>
            <Box>
              {" "}
              <Footer />
            </Box>
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default DefaultLayout;
