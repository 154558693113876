import React from "react";
import { Navigate } from "react-router-dom";
import { CLINIC_LINKS } from "../../links/clinicLinks";
import { getClinicStaff, getUser } from "../../api/getUserMain";
import { useContext } from "react";
import ClinicAuthContext from "../../context/clinicAuthContext";

export const ClinicRoute = ({ children }) => {
  const authed = getUser(); // isauth() returns true or false based on localStorage
  const authedStaffId = getClinicStaff();
  const { subscriptionActive, activeBill, clinic } =
    useContext(ClinicAuthContext);

  // if (authed && authedStaffId) {
  //   if (subscriptionActive() === "Active" ) {
  //     return children;
  //   } else  {
  //     return <Navigate to={CLINIC_LINKS.SUBSCRIPTION_DASHBOARD} />; //clinic.id &&activeBill &&
  //   }
  // } else {
  //   return <Navigate to={CLINIC_LINKS.LOGIN} />;
  // }

  return authed && authedStaffId ? (
    subscriptionActive() === "Active" ? (
        children
    ) : (
      clinic.id &&
      activeBill && <Navigate to={CLINIC_LINKS.SUBSCRIPTION_DASHBOARD} />
    )
  ) : (
    <Navigate to={CLINIC_LINKS.LOGIN} />
  );
  //   return (
  //     <Routes>
  //       <Route
  //         {...rest}
  //         render={(props) => {
  //           const isLoggedIn = getUser();

  //           return isLoggedIn ? (
  //             <Component {...props} />
  //           ) : (
  //             <Navigate to={CLINIC_LINKS.LOGIN} />
  //           );
  //         }}
  //       />
  //     </Routes>
  //   );
};
