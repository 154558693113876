import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import theme from "./Theme";
import ClinicApp from "./ClinicApp";
import ClientApp from "./ClientApp";
import { ClinicAuthProvider } from "./context/clinicAuthContext";
import { StaffRolePermProvider } from "./context/StaffPermContext";
import { ClinicAppRegistration } from "./AuthClinicApp";
import { HelmetProvider } from "react-helmet-async";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ErrorBoundary from "./ErrorBoundary";
import ClinicSuperAdminApp from "./ClinicSuperAdminApp";
import SmartDVMAppCenter from "./SmartDVMAppCenter";
import { CLINIC_LINKS } from "./links/clinicLinks";
import { CLIENT_LINKS } from "./links/clientLinks";
import { CLINIC_SUPER_LINKS } from "./links/clinicSuperLinks";
import { APP_CENTER_LINKS } from "./links/appCenterLinks";
import { GENERAL_LINKS } from "./links/generalLinks";
import GeneralApp from "./GeneralApp";
import { lazy, Suspense } from "react";
import PageLoader from "./PageLoader";
import { TaskProvider } from "./context/taskContext";

// const ClinicAppRegistration = lazy(() => import("./AuthClinicApp"));
// const ClinicSuperAdminApp = lazy(() => import("./ClinicSuperAdminApp"));
// const ClinicApp = lazy(() => import("./ClinicApp"));
// const ClientApp = lazy(() => import("./ClientApp"));
// const SmartDVMAppCenter = lazy(() => import("./SmartDVMAppCenter"));

function App() {
  const helmetContext = {};

  console.log(
    Object.values(CLINIC_SUPER_LINKS).includes(window.location.pathname)
  );
  if (
    !Object.values(CLINIC_LINKS).includes(window.location.pathname) &&
    !Object.values(CLIENT_LINKS).includes(window.location.pathname) &&
    !Object.values(CLINIC_SUPER_LINKS).includes(window.location.pathname) &&
    !Object.values(APP_CENTER_LINKS).includes(window.location.pathname) &&
    !Object.values(GENERAL_LINKS).includes(window.location.pathname) &&
    window.location.pathname !== "/"
  ) {
    // window.location.pathname = GENERAL_LINKS.NOT_FOUND_404;
  }
  return (
    <>
      <ThemeProvider theme={theme}>
        <HelmetProvider context={helmetContext}>
          <BrowserRouter>
            {/* <ErrorBoundary> */}
            <ClinicAuthProvider>
              <ClinicAppRegistration />

              <StaffRolePermProvider>
                <ErrorBoundary>
                  <ClinicSuperAdminApp />
                </ErrorBoundary>
                <ErrorBoundary>
                  <TaskProvider>
                    <ClinicApp />
                  </TaskProvider>
                </ErrorBoundary>
              </StaffRolePermProvider>
              <ErrorBoundary>
                <ClientApp />
              </ErrorBoundary>
              <GeneralApp />
              <ToastContainer />
            </ClinicAuthProvider>

            {window.location.pathname.includes("app_center") && (
              <SmartDVMAppCenter />
            )}

            {/* </ErrorBoundary> */}
            {/* <Routes>
              <Route path="*" element={<Error404Page />} />
            </Routes> */}
          </BrowserRouter>
        </HelmetProvider>
      </ThemeProvider>
    </>
  );
}
export default App;
