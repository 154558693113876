export const CLINIC_SUPER_LINKS = {
  // REGISTRATION
  LOGIN: "/vapp_super/login",
  REQUEST_REGISTER: "/getting_started/register",

  // CLINIC REGISTER
  CLINIC_REGISTER: "/vapp_super/clinic-register",

  // DASHBOARD
  DASHBOARD: "/vapp_super/dashboard",

  // SUPER_USER_PROFILE
  USER_PROFILE: "/vapp_super/profile",
  USER_PROFILE_EDIT: "/vapp_super/profile/edit",
  USER_PROFILE_PASSWORD: "/vapp_super/profile/password",

  // BILLING
  BILLING: "/vapp_super/billing",
  BILLING_DETAIL: "/vapp_super/billing/detail/:id",
  BILLING_PAY_SUCCESS: "/vapp_super/billing/pay/:id/successsss",
  BILLING_PAY: "/vapp_super/billing/pay/:id",
  BILLING_PLAN_UPGRADE: "/vapp_super/billing/upgrade-plan",

  // NOTIFICATION
  NOTIFICATIONS: "/vapp_super/notification",
  NOTIFICATION_DETAIL: "/vapp_super/notification/detail/:id",

  // ADMIN STAFF
  ADMIN_STAFF: "/vapp_super/admin-staff",
  ADMIN_STAFF_CREATE: "/vapp_super/admin-staff/create",
  ADMIN_STAFF_DETAIL: "/vapp_super/admin-staff/detail/:id",
  ADMIN_STAFF_EDIT: "/vapp_super/admin-staff/edit/:id",
  ADMIN_STAFF_PASSWORD: "/vapp_super/admin-staff/password/:id",

  // TRANSACTION
  TRANSACTION: "/vapp_super/transactions",
  BILLPRINTPAGE: "/vapp_super/payment-details/:id",

  // LOGS
  LOG: "/vapp_super/log",

  // CLINIC INFORMATION
  CLINIC_DETAIL: "/vapp_super/log/detail/:id",
};
