import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { CLINIC_LINKS } from "./links/clinicLinks";
import { CLIENT_LINKS } from "./links/clientLinks";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidMount() {
    // Add event listener to detect when user navigates back
    window.addEventListener("popstate", this.handlePopstate);
  }

  componentWillUnmount() {
    // Remove event listener when component unmounts
    window.removeEventListener("popstate", this.handlePopstate);
  }

  handlePopstate = () => {
    // Force a reload to restore regular rendering flow
    // window.location.reload();
  };
  linkToSite = () => {
    if (Object.keys(CLIENT_LINKS).includes(window.location.pathname)) {
      return CLIENT_LINKS.DASHBOARD;
    }
    return CLIENT_LINKS.DASHBOARD;
  };
  render() {
    if (this.state.hasError) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h1>Oops! Something went wrong.</h1>
          <Typography>
            Please check your internet connection. We apologize for any
            inconvenience caused. If the Error persists, try again later.
          </Typography>
          <Link to={this.linkToSite} style={{ textDecoration: "none" }}>
            <Button onClick={this.linkToSite} variant="contained">
              Go To Dashboard
            </Button>
          </Link>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
