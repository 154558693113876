import React, { lazy, Suspense } from "react";
// import Login from "./pages/clinic/registration/Login";
// import ResetPassword from "./pages/clinic/registration/ResetPassword";
// import ClinicRegister from "./pages/clinic/registration/ClinicRegister";
// import ClinicUpdate from "./pages/clinic/registration/ClinicUpdate";
// import Register from "./pages/clinic/registration/Register";
// import ForgotChangePassword from "./pages/clinic/registration/ForgotChangePassword";
// import VerificationPage from "./pages/clinic/registration/VerificationPage";
import PageLoader from "./PageLoader";
import { Route, Routes } from "react-router-dom";
import { CLINIC_LINKS } from "./links/clinicLinks";
import ClinicAuthContext from "./context/clinicAuthContext";
import { getClinicDetail, getClinicDetailSlug } from "./api/API";
import { getClinicSlugFromSubHost } from "./api/getClinicSlugFromSubHost";

// const Login = lazy(() => import("./pages/clinic/registration/Login"));
const Login = lazy(() => import("./pages/clinic/registration/NewLogin"));

const ResetPassword = lazy(() =>
  import("./pages/clinic/registration/ResetPassword")
);
const ClinicRegister = lazy(() =>
  import("./pages/clinic/registration/ClinicRegister")
);

const Error404Page = lazy(() => import("./pages/Error404Page/Error404Page"));

const Register = lazy(() => import("./pages/clinic/registration/Register"));
const ForgotChangePassword = lazy(() =>
  import("./pages/clinic/registration/ForgotChangePassword")
);
const VerificationPage = lazy(() =>
  import("./pages/clinic/registration/VerificationPage")
);

export function ClinicAppRegistration() {
  const { clinic, setClinic } = React.useContext(ClinicAuthContext);
  const handleGetClinicCallback = (data) => {
    setClinic(data);
  };
  const handleErrorCallback = () => {
    // navigate(CLINIC_LINKS.LOGIN);
  };
  React.useEffect(() => {
    getClinicDetailSlug(
      getClinicSlugFromSubHost(),
      handleGetClinicCallback,
      handleErrorCallback
    );
    //getClinicDetail(2, handleGetClinicCallback, handleErrorCallback);
  }, [!clinic.id]);
  return (
    <Routes>
      <Route
        path={CLINIC_LINKS.LOGIN}
        element={
          <Suspense fallBack={<PageLoader />}>
            <Login />
          </Suspense>
        }
      />
      {/* <Route
        path="*"
        element={
          <Suspense fallback={<PageLoader />}>
            <Error404Page />
          </Suspense>
        }
      /> */}
      <Route
        path={CLINIC_LINKS.REGISTER}
        element={
          <Suspense fallBack={<PageLoader />}>
            <Register />
          </Suspense>
        }
      />
      <Route
        path={CLINIC_LINKS.CLINIC_REGISTER}
        element={
          <Suspense fallBack={<PageLoader />}>
            <ClinicRegister />
          </Suspense>
        }
      />
      {/* <Route path={CLINIC_LINKS.CLINIC_UPDATE} element={<ClinicUpdate />} /> */}
      <Route
        path={CLINIC_LINKS.CLINIC_CHANGE_PASSWORD}
        element={
          <Suspense fallBack={<PageLoader />}>
            <ForgotChangePassword />
          </Suspense>
        }
      />
      <Route
        path={CLINIC_LINKS.CLINIC_RESET_PASSWORD}
        element={
          <Suspense fallBack={<PageLoader />}>
            <ResetPassword />
          </Suspense>
        }
      />
      <Route
        path={CLINIC_LINKS.VERIFY_ACCOUNT}
        element={
          <Suspense fallBack={<PageLoader />}>
            <VerificationPage />
          </Suspense>
        }
      />
    </Routes>
  );
}
