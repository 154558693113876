export const CLINIC_LINKS = {
  DASHBOARD: "/vapp/dashboard",
  SUBSCRIPTION_DASHBOARD: "/vapp/subscription",

  //
  LOGIN: "/vapp/login/",
  REGISTER: "/vapp/register",
  FORGOT_PASSWORD: "/vapp/forgot-password",
  CLINIC_REGISTER: "/vapp/vapp_register",
  CLINIC_UPDATE: "/vapp/vapp_update",
  CLINIC_CHANGE_PASSWORD: "/vapp/change_password/:code",
  CLINIC_RESET_PASSWORD: "/vapp/reset_password",
  VERIFY_ACCOUNT: "/vapp/account_verification/:id",

  //
  BIO_REGISTER: "/vapp/bio_register",
  APPOINTMENT_LIST: "/vapp/appointments",
  BOARDING_LIST: "/vapp/boarding",
  APPOINTMENT_RESCHEDULE: "/vapp/appointments/:id/reschedule",
  UPCOMING_APPOINTMENT_FULL_LIST: "/vapp/appointments/upcoming-appointment/:id",
  LAST_PRESCRIPTION_FULL_LIST: "/vapp/appointments/last-prescription/:id",
  APPOINTMENT_RESCHEDULE_BOARDING: "/vapp/appointments/:id/reschedule/boarding",
  APPOINTMENT_DETAIL_OVERVIEW: "/vapp/appointments/:id/detail",
  APPOINTMENT_DETAIL_MEDICAL: "/vapp/appointments/:id/medical",
  APPOINTMENT_CREATE: "/vapp/appointments/create-appointment",
  APPOINTMENT_DAILY_LIST: "/vapp/appointments/daily-list",

  //SOAP

  SOAP: "/vapp/appointments/:id/soap",
  SOAP_ESTIMATED_PAYMENT: "/vapp/appointments/:id/soap/estimated-payment",
  SOAP_ESTIMATED_PAYMENT_PRINT_VIEW:
    "/vapp/appointments/:id/soap/estimated-payment/printview",
  SOAP_FORM_SUBJECTIVE: "/vapp/appointments/:id/soap/subjective",
  SOAP_FORM_OBJECTIVE: "/vapp/appointments/:id/soap-form/objective",
  SOAP_FORM_ASSESMENT: "/vapp/appointments/:id/soap-form/assesments",
  SOAP_FORM_PLAN: "/vapp/appointments/:id/soap-form/plan",
  SOAP_FORM_SUBJECTIVE_EDIT: "/vapp/appointments/:id/soap/subjective/edit",
  SOAP_FORM_OBJECTIVE_EDIT: "/vapp/appointments/:id/soap-form/objective/edit",
  SOAP_FORM_ASSESMENT_EDIT: "/vapp/appointments/:id/soap-form/assesments/edit",
  SOAP_FORM_PLAN_EDIT: "/vapp/appointments/:id/soap-form/plan/edit",
  PRINT_SOAP: "/vapp/appointments/print-soap-details/:id",

  PRINT_MEDICAL_PRESCRIPTION: "/vapp/appointments/print-prescriptions/:id",
  PRINT_SOAP_MEDICAL_PRESCRIPTION:
    "/vapp/appointments/print-soap-prescriptions/:id",
  PRINT_STAFF_PAYSLIP: "/vapp/users/profile/print-payslip/:id",
  PRINT_PAYSLIP_DETAILS:
    "/vapp/users/payroll/payslip-history/print-details/:id",

  //STAFF
  STAFF_AVAILABILITY: "/vapp/availability/staff-availability",
  AVAILABILITY_SET_TIME: "/vapp/availability/set-time/:id",
  USERS_STAFF: "/vapp/users/staff",
  STAFF_OVERVIEW: "/vapp/users/staff/:id/overview",
  STAFF_OVERVIEW_EDIT: "/vapp/users/staff/:id/overview/edit",
  STAFF_WORKING_HOURS_EDIT: "/vapp/users/staff/:id/working-hours/edit",
  STAFF_WORKING_HOURS: "/vapp/users/staff/:id/working-hours",
  ADD_NEW_STAFF: "/vapp/users/staff/add-new-staff",
  STAFF_PASSWORD: "/vapp/users/:id/edit/update-password",
  STAFF_LEAVE: "/vapp/users/staff/:id/leave",

  CLIENT_LIST: "/vapp/users/clients/client-view",
  PATIENT_VIEW: "/vapp/users/patient/patient-view",
  PATIENT_DETAILS: "/vapp/users/clients/:id/patient-details",
  EDIT_PATIENT_INFO: "/vapp/users/clients/:id/edit-patient-info",
  ADD_PATIENT_INFO: "/vapp/users/clients/:id/add-patient-info",
  EDIT_PERSONAL_INFO: "/vapp/users/clients/:id/edit-personal-info",
  DOCUMENT_LIST: "/vapp/users/clients/document-list",
  CLIENT_PERSONAL_INFORMATION: "/vapp/users/add-client/personal-information",
  ADD_CLIENT_PATIENT: "/vapp/users/clients/add-patient-info",
  PATIENTS_MEDICAL_RECORDS: "/vapp/users/clients/:id/patient-medical-records",

  //TASK
  TASK: "/vapp/users/task",
  ADD_NEW_TASK: "/vapp/users/task/add-new-task",
  EDIT_NEW_TASK: "/vapp/users/task/:id/edit-new-task",
  TASK_DETAILS: "/vapp/users/task/:id/details",

  //PAYROLL
  PAYROLL: "/vapp/users/payroll",
  ADD_PAYROLL: "/vapp/users/payroll/add-payroll",
  ADD_PAYSLIP: "/vapp/users/payroll/add-payslip",
  SUBMIT_PAYROLL: "/vapp/users/payroll/submit-payroll/:id",
  PAYSLIP_DETAIL: "/vapp/users/payroll/payslip-history/details/:id",
  PAYROLL_DETAIL: "/vapp/users/payroll/details/:id",
  PAYROLL_EDIT: "/vapp/users/payroll/edit/:id",

  //INVENTORY
  ADD_NEW_INVENTORY: "/vapp/inventory/non-inventory/add-new-inventory",
  EDIT_NON_INVENTORY: "/vapp/inventory/non-inventory/edit-non-inventory/:id",
  EDIT_INVENTORY: "/vapp/inventory/edit-inventory/:id",
  NON_INVENTORY_DETAIL: "/vapp/inventory/non-inventory/detail/:id",
  INVENTORY_LIST: "/vapp/inventory/non-inventory-list",
  ADD_PRODUCT: "/vapp/inventory/add-product",
  EDIT_PRODUCT: "/vapp/inventory/edit-product/:id",
  PRODUCT_LIST: "/vapp/inventory/product-list",
  PRODUCT_DETAILS: "/vapp/product/detail/:id",
  INVENTORY_DETAILS: "/vapp/inventory/detail/:id",
  PURCHASE_HISTORY: "/vapp/inventory/detail/:id/purchase-history",
  SALES_HISTORY: "/vapp/inventory/detail/:id/sales-history",
  MEDICINE_DETAILS: "/vapp/medicine/detail/:id",
  ADD_MEDICINE: "/vapp/inventory/add-medicine",
  EDIT_MEDICINE: "/vapp/inventory/edit-medicine/:id",
  MEDICINE_LIST: "/vapp/inventory/medicine-list",
  ADD_INVENTORY: "/vapp/inventory/add-inventory",
  INVENTORY: "/vapp/inventory/inventory",

  //ACCOUNT INFO
  ACCOUNT_INFO_EDIT_MODE: "/vapp/users/profile/account-info/edit",
  ACCOUNT_INFO_VIEW_MODE: "/vapp/users/profile/account-info/view",
  DEPARTMENT_INFO: "/vapp/users/profile/department-info/edit",
  DEPARTMENT_INFO_VIEW_MODE: "/vapp/users/profile/department-info/view",
  EDUCATION_EDIT_MODE: "/vapp/users/profile/education/edit",
  EDUCATION_VIEW_MODE: "/vapp/users/profile/education/view",
  PROFILE_PASSWORD: "/vapp/users/profile/password",
  STAFF_PAYSLIP_HISTORY: "/vapp/users/profile/payslip",
  STAFF_PAYSLIP_HISTORY_DETAILS: "/vapp/users/profile/payslip/details/:id",

  //ACCOUNTING
  PAYMENT: "/vapp/accounting/payment",
  INVOICE: "/vapp/accounting/invoice",
  INVOICE_DETAILS: "/vapp/accounting/invoice/details/:id",
  INVOICE_EDIT: "/vapp/accounting/invoice/edit/:id",
  ADD_INVOICE: "/vapp/accounting/add-invoice",
  ADD_NON_CUSTOMER_INVOICE: "/vapp/accounting/add-invoice-non-client",
  STATEMENT_OF_ACCOUNT: "/vapp/accounting/statement-of-account",
  ADD_STATEMENT: "/vapp/accounting/statement-of-account/add-statement",
  STATEMENT_OF_ACCOUNT_DETAILS:
    "/vapp/accounting/statement-of-account/details/:id",
  PAYMENT_DETAILS: "/vapp/accounting/payment-details/:id",
  INVOICE_PAYMENT_METHOD:
    "/vapp/appointment/create-appointment/payment-method/:id",
  ACCOUNTS: "/vapp/accounts/container",
  ARCHIVED_ACCOUNTS: "/vapp/accounts/archived-accounts",
  ACCOUNT_DETAIL: "/vapp/accounts/detail/:id",
  EDIT_ACCOUNT: "/vapp/accounts/edit/:id",
  ADD_EXPENSES_ACCOUNT_ITEMS: "/vapp/accounts/expenses/:id/add-items",
  ADD_ACCOUNT: "/vapp/accounts/add-account",
  PAYMENT_SUCCESSFUL: "/vapp/account/payment-success/:id",

  PROFIT_AND_LOSS_LIST: "/vapp/accounts/profit-and-loss/list",
  PROFIT_AND_LOSS_DETAIL: "/vapp/accounts/profit-and-loss/detail/:id",
  PROFIT_AND_LOSS_EDIT: "/vapp/accounts/profit-and-loss/edit/:id",
  PROFIT_AND_LOSS_CREATE: "/vapp/accounts/profit-and-loss/create",

  //LABORATORY
  LAB_TEST_LIST: "/vapp/laboratory/lab-test-list",
  LAB_TEST_RESULT_LIST: "/vapp/laboratory/lab-test-result-list",
  CREATE_TEST: "/vapp/laboratory/create-test",
  ADD_TEST_RESULT: "/vapp/laboratory/add-test-result",
  IMAGING: "/vapp/laboratory/imaging",
  ADD_IMAGING: "/vapp/laboratory/add-imaging",
  TEST_DETAILS: "/vapp/laboratory/test-details/:id",
  IMAGING_DETAILS: "/vapp/laboratory/imaging-details/:id",
  EDIT_IMAGING: "/vapp/laboratory/edit-imaging/:id",
  EDIT_LAB_TEST: "/vapp/laboratory/test-edit/:id",
  TEST_RESULT_DETAILS: "/vapp/laboratory/test-result-details/:id",
  TEST_RESULT_DETAILS_PRINT: "/vapp/laboratory/test-print-details/:id",
  TEST_RESULT_EDIT: "/vapp/laboratory/test-result-edit/:id",
  //LABORATORY SETTINGS
  LAB_FLAG_SETTINGS: "/vapp/laboratory/settings/flag",
  LAB_METHODOLOGY_SETTINGS: "/vapp/laboratory/settings/methodology",
  LAB_LOCATION_SETTINGS: "/vapp/laboratory/settings/location",
  LAB_TEST_TYPE_SETTINGS: "/vapp/laboratory/settings/test-type",
  LAB_SAMPLE_TYPE_SETTINGS: "/vapp/laboratory/settings/sample-type",

  //REPORT
  ACCOUNT_RECEIVABLE: "/vapp/report/account-receivable",
  ACCOUNT_RECEIVABLE_DETAIL: "/vapp/report/account-receivable/:id/detail",
  DOCTOR_REPORT: "/vapp/report/doctors-reports",
  DOCTOR_REPORT_DETAIL: "/vapp/report/doctors-reports/:id/detail",
  TASK_REPORT: "/vapp/report/task-reports",
  TASK_REPORT_DETAIL: "/vapp/report/task-reports/:id/detail",
  CONTROLLED_SUBSTANCE: "/vapp/report/controlled-substance",
  CONTROLLED_SUBSTANCE_DETAILS: "/vapp/report/controlled-substance-details",
  INVENTORY_BILLABLE_ITEMS: "/vapp/report/inventory-and-billable-items",
  SALES_OF_DAYS: "/vapp/report/sales-of-days",
  PRACTICE_TRENDS: "/vapp/report/practice-trends",
  CREATE_PRESCRIPTION: "/vapp/appointments/create-prescription",
  CREATE_PRESCRIPTION_ID: "/vapp/appointments/:id/create-prescription",
  EDIT_PRESCRIPTION: "/vapp/appointments/:id/edit-prescription",
  DIAGNOSIS_REPORT: "/vapp/report/diagnosis",
  DIAGNOSIS_REPORT_DETAIL: "/vapp/report/diagnosis/:diag/detail",

  //GETTING STARTED PAGE
  GETTING_STARTED_PAGE: "/vapp/getting-started",
  CONFIGURE_PRACTICE_SETTINGS: "/vapp/config/practice",

  //SETTINGS
  APPOINTMENT_SETTINGS: "/vapp/settings/appointments-settings",
  CAGESIZE_SETTINGS: "/vapp/settings/appointments/cage-size",
  BOARDING_SIZE_DETAIL: "/vapp/settings/appointments/cage-size/details/:id",
  BOARDING_SIZE_EDIT: "/vapp/settings/appointments/cage-size/edit/:id",
  CAGETYPE_SETTINGS: "/vapp/settings/appointments/cage-type",
  ADMISSION_TYPE_SETTINGS: "/vapp/settings/appointments/admission-type",
  ASSESSMENT_DIAGNOSIS: "/vapp/settings/appointments/assessment-diagnosis",
  LEAVE_SETTINGS: "/vapp/settings/leave-settings",
  PAYROLL_SETTINGS: "/vapp/settings/payroll-settings",
  PRODUCT_SUPPLIER_SETTINGS: "/vapp/settings/inventory/product-supplier",
  INVENTORY_ACCOUNT_SETTINGS: "/vapp/settings/inventory/account",
  INVENTORY_CATEGORY_SETTINGS: "/vapp/settings/inventory/category",
  STAFF_SETTINGS: "/vapp/settings/staff",
  PAYMENT_SETTINGS: "/vapp/settings/payment",
  DEFAULT_TAX_SETTINGS: "/vapp/settings/default-tax",
  INVOICE_SETTINGS: "/vapp/settings/invoices",
  DOC_NAME_SETTINGS: "/vapp/settings/doctors-name",
  DEPARTMENT_SETTINGS: "/vapp/settings/add-departments",
  PERMISSIONS_SETTINGS: "/vapp/settings/permissions",
  ROLE_STAFF_LIST_SETTINGS: "/vapp/settings/role-staff-list/:role/:id",
  SPECIES_SETTINGS: "/vapp/settings/species-settings",
  BREED_SETTINGS: "/vapp/settings/breed-settings",
  SEX_SETTINGS: "/vapp/settings/gender-settings",
  STAFF_GENDER_SETTINGS: "/vapp/settings/staff-gender-settings",
  COLOR_SETTINGS: "/vapp/settings/color-settings",
  ROLE_SETTINGS: "/vapp/settings/role-settings",
  ROLE_SETTINGS_DETAIL_PERM: "/vapp/settings/role-settings/:id/perm",
  TITLE_SETTINGS: "/vapp/settings/title-settings",
  GOOGLE_SETTINGS: "/vapp/settings/google-settings",
  INITIAL_COMPLAINTS: "/vapp/settings/initial-complaints",
  VITAL_FINDINGS: "/vapp/settings/vital_findings",
  SETTINGS: "/vapp/settings",
  CURRENCY: "/vapp/settings/currency",
  PAYMENT_GATEWAY_ADD: "/vapp/settings/payment-gateway/add-payment-way",
  PAYMENT_GATEWAY_UPDATE:
    "/vapp/settings/payment-gateway/update-payment-gateway/:id",
  PAYMENT_GATEWAY: "/vapp/settings/payment-gateway",

  // NOTIFICATIONS
  NOTIFICATION_LIST: "/vapp/notification/list",
  NOTIFICATION_CREATE_BROADCAST: "/vapp/notification/create-broadcast",
  NOTIFICATION_BROADCAST_LIST: "/vapp/notification/broadcast-list",
  NOTIFICATION_ACTIVITY_LOGS: "/vapp/notification/activity-log-list",
  NOTIFICATION_DETAILS: "/vapp/notification/details/:id",

  //LOADING
  LOADING: "/vapp/loading",

  //ERROR 404 PAGE
  ERROR404: "*",
};
export const SPLITTED_CLINIC_LINKS = {
  LAYOUT: "/vapp/",
  DASHBOARD: "dashboard",
  SUBSCRIPTION_DASHBOARD: "subscription",

  //
  LOGIN: "login/",
  REGISTER: "register",
  CLINIC_REGISTER: "vapp_register",
  CLINIC_UPDATE: "vapp_update",
  CLINIC_CHANGE_PASSWORD: "change_password/:code",
  CLINIC_RESET_PASSWORD: "reset_password",
  VERIFY_ACCOUNT: "account_verification/:id",

  //
  BIO_REGISTER: "bio_register",
  APPOINTMENT_LIST: "appointments",
  BOARDING_LIST: "boarding",
  APPOINTMENT_RESCHEDULE: "appointments/:id/reschedule",
  UPCOMING_APPOINTMENT_FULL_LIST: "appointments/upcoming-appointment/:id",
  LAST_PRESCRIPTION_FULL_LIST: "appointments/last-prescription/:id",
  APPOINTMENT_RESCHEDULE_BOARDING: "appointments/:id/reschedule/boarding",
  APPOINTMENT_DETAIL_OVERVIEW: "appointments/:id/detail",
  APPOINTMENT_DETAIL_MEDICAL: "appointments/:id/medical",
  APPOINTMENT_CREATE: "appointments/create-appointment",
  APPOINTMENT_DAILY_LIST: "appointments/daily-list",

  //SOAP

  SOAP: "appointments/:id/soap",
  SOAP_ESTIMATED_PAYMENT: "appointments/:id/soap/estimated-payment",
  SOAP_ESTIMATED_PAYMENT_PRINT_VIEW:
    "appointments/:id/soap/estimated-payment/printview",
  SOAP_FORM_SUBJECTIVE: "appointments/:id/soap/subjective",
  SOAP_FORM_OBJECTIVE: "appointments/:id/soap-form/objective",
  SOAP_FORM_ASSESMENT: "appointments/:id/soap-form/assesments",
  SOAP_FORM_PLAN: "appointments/:id/soap-form/plan",
  SOAP_FORM_SUBJECTIVE_EDIT: "appointments/:id/soap/subjective/edit",
  SOAP_FORM_OBJECTIVE_EDIT: "appointments/:id/soap-form/objective/edit",
  SOAP_FORM_ASSESMENT_EDIT: "appointments/:id/soap-form/assesments/edit",
  SOAP_FORM_PLAN_EDIT: "appointments/:id/soap-form/plan/edit",
  PRINT_SOAP: "appointments/print-soap-details/:id",

  PRINT_MEDICAL_PRESCRIPTION: "appointments/print-prescriptions/:id",
  PRINT_SOAP_MEDICAL_PRESCRIPTION: "appointments/print-soap-prescriptions/:id",
  PRINT_STAFF_PAYSLIP: "users/profile/print-payslip/:id",
  PRINT_PAYSLIP_DETAILS: "users/payroll/payslip-history/print-details/:id",

  //STAFF
  STAFF_AVAILABILITY: "availability/staff-availability",
  AVAILABILITY_SET_TIME: "availability/set-time/:id",
  USERS_STAFF: "users/staff",
  STAFF_OVERVIEW: "users/staff/:id/overview",
  STAFF_OVERVIEW_EDIT: "users/staff/:id/overview/edit",
  STAFF_WORKING_HOURS_EDIT: "users/staff/:id/working-hours/edit",
  STAFF_WORKING_HOURS: "users/staff/:id/working-hours",
  ADD_NEW_STAFF: "users/staff/add-new-staff",
  STAFF_PASSWORD: "users/:id/edit/update-password",
  STAFF_LEAVE: "users/staff/:id/leave",

  CLIENT_LIST: "users/clients/client-view",
  PATIENT_VIEW: "users/patient/patient-view",
  PATIENT_DETAILS: "users/clients/:id/patient-details",
  EDIT_PATIENT_INFO: "users/clients/:id/edit-patient-info",
  ADD_PATIENT_INFO: "users/clients/:id/add-patient-info",
  EDIT_PERSONAL_INFO: "users/clients/:id/edit-personal-info",
  DOCUMENT_LIST: "users/clients/document-list",
  CLIENT_PERSONAL_INFORMATION: "users/add-client/personal-information",
  ADD_CLIENT_PATIENT: "users/clients/add-patient-info",
  PATIENTS_MEDICAL_RECORDS: "users/clients/:id/patient-medical-records",

  //TASK
  TASK: "users/task",
  ADD_NEW_TASK: "users/task/add-new-task",
  EDIT_NEW_TASK: "users/task/:id/edit-new-task",
  TASK_DETAILS: "users/task/:id/details",

  //PAYROLL
  PAYROLL: "users/payroll",
  ADD_PAYROLL: "users/payroll/add-payroll",
  ADD_PAYSLIP: "users/payroll/add-payslip",
  SUBMIT_PAYROLL: "users/payroll/submit-payroll/:id",
  PAYSLIP_DETAIL: "users/payroll/payslip-history/details/:id",
  PAYROLL_DETAIL: "users/payroll/details/:id",
  PAYROLL_EDIT: "users/payroll/edit/:id",

  //INVENTORY
  ADD_NEW_INVENTORY: "inventory/non-inventory/add-new-inventory",
  EDIT_NON_INVENTORY: "inventory/non-inventory/edit-non-inventory/:id",
  EDIT_INVENTORY: "inventory/edit-inventory/:id",
  NON_INVENTORY_DETAIL: "inventory/non-inventory/detail/:id",
  INVENTORY_LIST: "inventory/non-inventory-list",
  ADD_PRODUCT: "inventory/add-product",
  EDIT_PRODUCT: "inventory/edit-product/:id",
  PRODUCT_LIST: "inventory/product-list",
  PRODUCT_DETAILS: "product/detail/:id",
  INVENTORY_DETAILS: "inventory/detail/:id",
  PURCHASE_HISTORY: "inventory/detail/:id/purchase-history",
  SALES_HISTORY: "inventory/detail/:id/sales-history",
  MEDICINE_DETAILS: "medicine/detail/:id",
  ADD_MEDICINE: "inventory/add-medicine",
  EDIT_MEDICINE: "inventory/edit-medicine/:id",
  MEDICINE_LIST: "inventory/medicine-list",
  ADD_INVENTORY: "inventory/add-inventory",
  INVENTORY: "inventory/inventory",

  //ACCOUNT INFO
  ACCOUNT_INFO_EDIT_MODE: "users/profile/account-info/edit",
  ACCOUNT_INFO_VIEW_MODE: "users/profile/account-info/view",
  DEPARTMENT_INFO: "users/profile/department-info/edit",
  DEPARTMENT_INFO_VIEW_MODE: "users/profile/department-info/view",
  EDUCATION_EDIT_MODE: "users/profile/education/edit",
  EDUCATION_VIEW_MODE: "users/profile/education/view",
  PROFILE_PASSWORD: "users/profile/password",
  STAFF_PAYSLIP_HISTORY: "users/profile/payslip",
  STAFF_PAYSLIP_HISTORY_DETAILS: "users/profile/payslip/details/:id",

  //ACCOUNTING
  PAYMENT: "accounting/payment",
  INVOICE: "accounting/invoice",
  INVOICE_DETAILS: "accounting/invoice/details/:id",
  INVOICE_EDIT: "accounting/invoice/edit/:id",
  ADD_INVOICE: "accounting/add-invoice",
  ADD_NON_CUSTOMER_INVOICE: "accounting/add-invoice-non-client",
  STATEMENT_OF_ACCOUNT: "accounting/statement-of-account",
  ADD_STATEMENT: "accounting/statement-of-account/add-statement",
  STATEMENT_OF_ACCOUNT_DETAILS: "accounting/statement-of-account/details/:id",
  PAYMENT_DETAILS: "accounting/payment-details/:id",
  INVOICE_PAYMENT_METHOD: "appointment/create-appointment/payment-method/:id",
  ACCOUNTS: "accounts/container",
  ACCOUNT_DETAIL: "accounts/detail/:id",
  ADD_EXPENSES_ACCOUNT_ITEMS: "accounts/expenses/:id/add-items",
  ADD_ACCOUNT: "accounts/add-account",
  PAYMENT_SUCCESSFUL: "account/payment-success/:id",

  PROFIT_AND_LOSS_LIST: "accounts/profit-and-loss/list",
  PROFIT_AND_LOSS_DETAIL: "accounts/profit-and-loss/detail/:id",
  PROFIT_AND_LOSS_EDIT: "accounts/profit-and-loss/edit/:id",
  PROFIT_AND_LOSS_CREATE: "accounts/profit-and-loss/create",

  //LABORATORY
  LAB_TEST_LIST: "laboratory/lab-test-list",
  LAB_TEST_RESULT_LIST: "laboratory/lab-test-result-list",
  CREATE_TEST: "laboratory/create-test",
  ADD_TEST_RESULT: "laboratory/add-test-result",
  IMAGING: "laboratory/imaging",
  ADD_IMAGING: "laboratory/add-imaging",
  TEST_DETAILS: "laboratory/test-details/:id",
  IMAGING_DETAILS: "laboratory/imaging-details/:id",
  EDIT_IMAGING: "laboratory/edit-imaging/:id",
  EDIT_LAB_TEST: "laboratory/test-edit/:id",
  TEST_RESULT_DETAILS: "laboratory/test-result-details/:id",
  TEST_RESULT_DETAILS_PRINT: "laboratory/test-print-details/:id",
  TEST_RESULT_EDIT: "laboratory/test-result-edit/:id",
  //LABORATORY SETTINGS
  LAB_FLAG_SETTINGS: "laboratory/settings/flag",
  LAB_METHODOLOGY_SETTINGS: "laboratory/settings/methodology",
  LAB_LOCATION_SETTINGS: "laboratory/settings/location",
  LAB_TEST_TYPE_SETTINGS: "laboratory/settings/test-type",
  LAB_SAMPLE_TYPE_SETTINGS: "laboratory/settings/sample-type",

  //REPORT
  ACCOUNT_RECEIVABLE: "report/account-receivable",
  ACCOUNT_RECEIVABLE_DETAIL: "report/account-receivable/:id/detail",
  CONTROLLED_SUBSTANCE: "report/controlled-substance",
  CONTROLLED_SUBSTANCE_DETAILS: "report/controlled-substance-details",
  INVENTORY_BILLABLE_ITEMS: "report/inventory-and-billable-items",
  SALES_OF_DAYS: "report/sales-of-days",
  PRACTICE_TRENDS: "report/practice-trends",
  CREATE_PRESCRIPTION: "appointments/create-prescription",
  CREATE_PRESCRIPTION_ID: "appointments/:id/create-prescription",
  EDIT_PRESCRIPTION: "appointments/:id/edit-prescription",
  DIAGNOSIS_REPORT: "report/diagnosis",
  DIAGNOSIS_REPORT_DETAIL: "report/diagnosis/:diag/detail",

  //GETTING STARTED PAGE
  GETTING_STARTED_PAGE: "getting-started",
  CONFIGURE_PRACTICE_SETTINGS: "config/practice",

  //SETTINGS
  APPOINTMENT_SETTINGS: "settings/appointments-settings",
  CAGESIZE_SETTINGS: "settings/appointments/cage-size",
  BOARDING_SIZE_DETAIL: "settings/appointments/cage-size/details/:id",
  BOARDING_SIZE_EDIT: "settings/appointments/cage-size/edit/:id",
  CAGETYPE_SETTINGS: "settings/appointments/cage-type",
  ADMISSION_TYPE_SETTINGS: "settings/appointments/admission-type",
  ASSESSMENT_DIAGNOSIS: "settings/appointments/assessment-diagnosis",
  LEAVE_SETTINGS: "settings/leave-settings",
  PAYROLL_SETTINGS: "settings/payroll-settings",
  PRODUCT_SUPPLIER_SETTINGS: "settings/inventory/product-supplier",
  INVENTORY_ACCOUNT_SETTINGS: "settings/inventory/account",
  INVENTORY_CATEGORY_SETTINGS: "settings/inventory/category",
  STAFF_SETTINGS: "settings/staff",
  PAYMENT_SETTINGS: "settings/payment",
  INVOICE_SETTINGS: "settings/invoices",
  DOC_NAME_SETTINGS: "settings/doctors-name",
  DEPARTMENT_SETTINGS: "settings/add-departments",
  PERMISSIONS_SETTINGS: "settings/permissions",
  ROLE_STAFF_LIST_SETTINGS: "settings/role-staff-list/:role/:id",
  SPECIES_SETTINGS: "settings/species-settings",
  BREED_SETTINGS: "settings/breed-settings",
  SEX_SETTINGS: "settings/gender-settings",
  STAFF_GENDER_SETTINGS: "settings/staff-gender-settings",
  COLOR_SETTINGS: "settings/color-settings",
  ROLE_SETTINGS: "settings/role-settings",
  ROLE_SETTINGS_DETAIL_PERM: "settings/role-settings/:id/perm",
  TITLE_SETTINGS: "settings/title-settings",
  GOOGLE_SETTINGS: "settings/google-settings",
  INITIAL_COMPLAINTS: "settings/initial-complaints",
  VITAL_FINDINGS: "settings/vital_findings",
  SETTINGS: "settings",
  CURRENCY: "settings/currency",
  PAYMENT_GATEWAY_ADD: "settings/payment-gateway/add-payment-way",
  PAYMENT_GATEWAY_UPDATE: "settings/payment-gateway/update-payment-gateway/:id",
  PAYMENT_GATEWAY: "settings/payment-gateway",

  // NOTIFICATIONS
  NOTIFICATION_LIST: "notification/list",
  NOTIFICATION_CREATE_BROADCAST: "notification/create-broadcast",
  NOTIFICATION_BROADCAST_LIST: "notification/broadcast-list",
  NOTIFICATION_ACTIVITY_LOGS: "notification/activity-log-list",
  NOTIFICATION_DETAILS: "notification/details/:id",

  //LOADING
  LOADING: "loading",

  //ERROR 404 PAGE
  ERROR404: "*",
};
