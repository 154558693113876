import AccountCircleOutlined from "@mui/icons-material/AccountCircleOutlined";
import Visibility from "@mui/icons-material/Visibility";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import LoginContainer from "../../../scomponents/utils/LoginContainer";
import LockIcon from "@mui/icons-material/Lock";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  getClinicAuthenticatedStaffDetail,
  handleLogin,
} from "../../../api/API";
import ClinicAuthContext from "../../../context/clinicAuthContext";
import { INITIAL_STATE, postReducer } from "../../../reducers/postReducer";
import { ACTION_TYPES } from "../../../reducers/ActionTypes";
import { ButtonSubmitProgress } from "../../../component/ProgressIndicators/PageProgress";
import { CLINIC_SUPER_LINKS } from "../../../links/clinicSuperLinks";
import { CLINIC_LINKS } from "../../../links/clinicLinks";
import { CLIENT_LINKS } from "../../../links/clientLinks";
import { useSearchQuery } from "../../../hooks/searchQuery";
import capitalizeFirstLetter from "../../../features/capitalizeFirstLetter";

import SmartdvmLogo from "../../../assets/logo.png";

const Login = () => {
  const navigate = useNavigate();
  const { clinic, setUser } = useContext(ClinicAuthContext);
  const searchQuery = useSearchQuery();

  const [values, setValues] = React.useState({
    email: "",
    password: "",
    showPassword: false,
  });
  const [state, dispatch] = React.useReducer(postReducer, INITIAL_STATE);

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
    dispatch({ type: ACTION_TYPES.RESET });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const getStaffInfoSucess = (data) => {
    if (data.is_supper_staff) {
      setUser(data);
      const next = searchQuery.get("next");
      console.log(next);
      navigate(next ? next : CLINIC_SUPER_LINKS.DASHBOARD);
    } else {
      handleErrorAction();
    }
  };
  const handleSuccessAction = () => {
    getClinicAuthenticatedStaffDetail(
      getStaffInfoSucess,
      handleErrorAction,
      clinic.id
    );
  };
  const handleErrorAction = () => {
    dispatch({ type: ACTION_TYPES.FETCH_ERROR });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch({ type: ACTION_TYPES.FETCH_START });
    handleLogin(values, handleSuccessAction, handleErrorAction);
  };

  return (
    <LoginContainer>
      <>
        <Box component="form" onSubmit={handleSubmit}>
          {/* This section will only show when it's mobile screen */}
          <Box sx={{ display: { xs: "block", sm: "none" } }}>
            <Box>
              <img
                src={SmartdvmLogo}
                alt="smartdvm-logo"
                style={{
                  marginLeft: "3rem",
                  marginTop: "0.5rem",
                  maxHeight: "12em",
                  maxWidth: "14em",
                  cursor: "pointer",
                }}
              />
            </Box>
          </Box>
          {/* It ends here */}
          <Typography
            variant="h4"
            sx={{
              fontWeight: 400,
              fontSize: "18px",
              marginBottom: "0.5rem",
              alignItems: "left",
            }}
          >
            {`Hello! Welcome To ${
              clinic.name ? capitalizeFirstLetter(clinic.name) : "SmartDVM"
            }.`}
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 400,
              marginBottom: "2rem",
              alignItems: "left",
            }}
          >
            Log In
          </Typography>
          <Box>
            <TextField
              margin="normal"
              required
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              sx={{ width: "40ch" }}
              autoFocus
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircleOutlined />
                  </InputAdornment>
                ),
                style: { borderRadius: "10px" },
              }}
              onChange={handleChange}
            />
          </Box>
          <Box>
            <TextField
              margin="normal"
              required
              id="password"
              label="Password"
              name="password"
              type={values.showPassword ? "text" : "password"}
              sx={{ width: "40ch" }}
              autoFocus
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
                style: { borderRadius: "10px" },

                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={handleChange}
            />
          </Box>
          <Typography
            variant="caption"
            sx={{
              color: "#009EED",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingRight: "12px",
            }}
          >
            <Link
              style={{ textDecoration: "none" }}
              to={CLINIC_LINKS.FORGOT_PASSWORD}
            >
              Forgot Password
            </Link>
          </Typography>
          <Button
            variant="contained"
            type="submit"
            sx={{
              mt: 5,
              paddingY: "14px",
              borderRadius: "10px",
              width: "40ch",
            }}
            onClick={handleSubmit}
          >
            Login
            {state.loading && <ButtonSubmitProgress />}
          </Button>
        </Box>
        <Box sx={{ my: 2 }}>
          {state.error && clinic.id ? (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              Email Or Password Incorrect. Check and Login again. Notice: only
              super admin of {clinic.name} can login
            </Alert>
          ) : (
            ""
          )}
          {state.error && !clinic.id ? (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              Fetch Error Kindly reload the page
              {console.log(clinic.id)}
            </Alert>
          ) : (
            ""
          )}
        </Box>
      </>
    </LoginContainer>
  );
};

export default Login;
