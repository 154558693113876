import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import Calendar from "../../../assets/dasboardAssets/Calendar.png";
import Medic from "../../../assets/dasboardAssets/medic.png";
import Patient from "../../../assets/dasboardAssets/Patient.png";
import Payment from "../../../assets/dasboardAssets/payment.png";
import NotificaationIcon from "../../../assets/dasboardAssets/NotificationIcon.svg";
import Users from "../../../assets/Clinic/Dashboard/Users.png";
import Transaction from "../../../assets/Clinic/Dashboard/Group1.png";
import Dashboard from "../../../assets/dasboardAssets/Dashb.png";
import DashboardWhite from "../../../assets/dasboardAssets/Dashboard.png";
import CalendarWhite from "../../../assets/dasboardAssets/CalendarWhite.svg";
import TransactionWhite from "../../../assets/dasboardAssets/TransactionWhite.svg";
import UserWhite from "../../../assets/dasboardAssets/UserWhite.svg";
import NotificationWhite from "../../../assets/dasboardAssets/NotificationWhite.svg";
import AppLabelWhite from "../../../assets/dasboardAssets/AppLabelWhite.svg";

import { NavLink, useLocation } from "react-router-dom";
import { CLINIC_SUPER_LINKS } from "../../../links/clinicSuperLinks";

const SideNavItem = ({ itemIcon, itemIconWhite, title, path }) => {
  const location = useLocation();
  const [currentPagePath, setCurrentPagePath] = useState("");
  useEffect(() => {
    setCurrentPagePath(location.pathname);
  }, [location.pathname]);

  let activeStyle = {
    textDecoration: "none",
    backgroundColor: "#009EED",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "row",
    paddingTop: "12px",
    paddingBottom: "12px",
    paddingRight: "24px",
    paddingLeft: "12px",
    color: "#ffffff",
    fontFamily: "Poppins",
    fontWeight: "400",
    fontSize: "14px",
    mb: 4,
  };
  let nonActiveStyle = {
    textDecoration: "none",
    display: "flex",
    flexDirection: "row",
    color: "#39484F",
    fontFamily: "Poppins",
    fontWeight: "400",
    fontSize: "14px",
    mb: 4,
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        mb: 4,
      }}
    >
      <NavLink
        to={path}
        style={({ isActive }) => (isActive ? activeStyle : nonActiveStyle)}
      >
        <img
          src={location.pathname === path ? itemIconWhite : itemIcon}
          style={{
            paddingRight: "12px",
          }}
          alt="dashboard Icon"
        />

        {title}
      </NavLink>
    </Box>
  );
};

const SuperSideNavItems = () => {
  return (
    <Box
      sx={{
        mt: 2,
        color: "textGray.sideNav",
      }}
    >
      <SideNavItem
        title="Dashboard"
        itemIcon={Dashboard}
        itemIconWhite={DashboardWhite}
        path={CLINIC_SUPER_LINKS.DASHBOARD}
      />
      <SideNavItem
        title="Billing"
        itemIcon={Calendar}
        itemIconWhite={CalendarWhite}
        path={CLINIC_SUPER_LINKS.BILLING}
      />
      <SideNavItem
        title="Transactions"
        itemIcon={Transaction}
        itemIconWhite={TransactionWhite}
        path={CLINIC_SUPER_LINKS.TRANSACTION}
      />
      <SideNavItem
        title="Admin Staff"
        itemIcon={Users}
        itemIconWhite={UserWhite}
        path={CLINIC_SUPER_LINKS.ADMIN_STAFF}
      />
      <SideNavItem
        title="Notification"
        itemIcon={NotificaationIcon}
        itemIconWhite={NotificationWhite}
        path={CLINIC_SUPER_LINKS.NOTIFICATIONS}
      />
      <SideNavItem
        title="Logs"
        itemIcon={Payment}
        itemIconWhite={AppLabelWhite}
        path={CLINIC_SUPER_LINKS.LOG}
      />
    </Box>
  );
};

export default SuperSideNavItems;
