import React from "react";
import { PageProgress } from "./component/ProgressIndicators/PageProgress";
import DefaultLayout from "./component/DefaultLayout/DefaultLayout";

const ClientPageLoader = () => {
  return (
    <div>
      <DefaultLayout>
        <PageProgress />
      </DefaultLayout>
    </div>
  );
};

export default ClientPageLoader;
