import AccountCircleOutlined from '@mui/icons-material/AccountCircleOutlined'
import { Box, Grid, Typography } from '@mui/material'
import { green } from '@mui/material/colors'
import React from 'react'

import Logo from '../../assets/Clinic/Registration/Logo.png'
import LoginAvatar from '../../assets/Clinic/Registration/LoginAvatar.png'


const ClinicRegSide = () => {
  return (
    
        <Grid item xs={12} sm={5} md={3} sx={{
            backgroundColor:"#F0FAFE",
            minheight:{xs: '50px',sm:'100vh'},
            display:"flex",
            alignItems:"center",
            flexDirection:"column"
            
        }}>
            
            <Box sx={{
                mt:3
            }}>
            <img src={Logo} width='200px'  alt='smartdvm-logo' />
            </Box>
            <Box sx={{
                mt:8
            }}>
                <img src={LoginAvatar} width='250px'  alt='smartdvm-logo' />
                
                    
            </Box>
            
        </Grid>
        
        
   
  )
}

export default ClinicRegSide