import {
  Box,
  Button,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { styled, useTheme } from "@mui/material/styles";
import React, { useEffect, useContext } from "react";
import SideNavItems from "./SideNavItems";

import SmartdvmLogo from "../../assets/logo.png";
import Settings from "../../assets/Clinic/Dashboard/Setting.png";
import UserAvatar from "../../assets/avatars/avatar_icon.png";
import LogoutIcon from "../../assets/icon/LogoutIcon.svg";

import { getClinicDetail } from "../../api/API";
import ClinicAuthContext from "../../context/clinicAuthContext";
import DrawerContext from "../../context/drawerContext";
import { useNavigate, Link } from "react-router-dom";
import { CLINIC_LINKS } from "../../links/clinicLinks";
import { imageLinkReformat } from "../../features/imageLinkReformat";
import { WarningAmber } from "@mui/icons-material";
import { dateDifference } from "../../features/readableDate";
import { CLINIC_SUPER_LINKS } from "../../links/clinicSuperLinks";

const drawerWidth = 260;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const SideNav = () => {
  const today = new Date();
  const { open, handleDrawerOpen, matches, drawerRef } =
    useContext(DrawerContext);
  const { clinic, staff, subscriptionTerminationDate } =
    useContext(ClinicAuthContext);
  const [clinicDetails, setClinicDetails] = React.useState([]);

  const terminationTill = dateDifference(today, subscriptionTerminationDate());
  const theme = useTheme();
  const navigate = useNavigate();

  const handleUserIconClick = () => {
    navigate(CLINIC_LINKS.ACCOUNT_INFO_VIEW_MODE);
  };

  const handleSettingsIconClick = () => {
    navigate(CLINIC_LINKS.CONFIGURE_PRACTICE_SETTINGS);
  };
  const goToSuperAdminPortal = () =>
    navigate(`${CLINIC_SUPER_LINKS.LOGIN}?next=${CLINIC_SUPER_LINKS.BILLING}`);

  const successCallBack = (data) => {
    setClinicDetails(data);
  };

  const errorCallBack = () => {};

  const handleLogout = () => {
    localStorage.clear();
    navigate(CLINIC_LINKS.LOGIN);
  };

  useEffect(() => {
    getClinicDetail(clinic.id, successCallBack, errorCallBack);
  }, [clinic.id]);

  const id = open ? "user-popover" : undefined;

  return (
    <Drawer
      ref={drawerRef}
      variant={matches ? "permanent" : "temporary"}
      anchor="left"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
        display: "flex",
        flexDirection: "row",
      }}
      open={open}
      onClose={handleDrawerOpen}
    >
      <Toolbar
        sx={{
          display: { xs: "flex" },
          alignItems: "center",
          flexDirection: "column",
          width: { sm: 190 },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyItems: "center",
            flexGrow: 1,
            mb: 3,
            zIndex: 200,
          }}
        >
          {matches ? (
            <Link to={CLINIC_LINKS.DASHBOARD}>
              <img
                src={SmartdvmLogo}
                alt="smartdvm-logo"
                style={{
                  marginTop: "-10px",
                  marginLeft: "-2rem",
                  marginBottom: "-2rem",
                  maxHeight: "8em",
                  maxWidth: "10em",
                  cursor: "pointer",
                  paddingBottom: "1.5rem",
                }}
              />
            </Link>
          ) : (
            <Link to={CLINIC_LINKS.DASHBOARD}>
              <img
                src={SmartdvmLogo}
                alt="smartdvm-logo"
                style={{
                  marginTop: "12px",
                  marginLeft: "24px",
                  maxHeight: "6em",
                  maxWidth: "8em",
                  cursor: "pointer",
                }}
              />
            </Link>
          )}

          {!matches && (
            <DrawerHeader>
              <IconButton onClick={handleDrawerOpen}>
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
            </DrawerHeader>
          )}
        </Box>

        {!matches && (
          <Box
            sx={{
              width: "204px",
              height: "0.853px",
              background: "#F3F3F4",
              marginBottom: "24px",
            }}
          ></Box>
        )}

        {!matches && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {/* USER AVATAR */}
              <IconButton
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={handleUserIconClick}
                aria-describedby={id}
              >
                <img
                  style={{
                    height: "40px",
                    width: "40px",
                    borderRadius: "50%",
                  }}
                  src={
                    staff.user
                      ? staff.user.image
                        ? imageLinkReformat(staff.user.image)
                        : UserAvatar
                      : UserAvatar
                  }
                  alt="icon"
                />
              </IconButton>
              <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>
                View profile
              </Typography>
            </Box>
            <Box sx={{ marginLeft: "24px" }}>
              {/* SETTINGS ICON */}
              {staff && staff.is_admin && (
                <IconButton
                  sx={{
                    backgroundColor: "backgroundTypes.buttonNew",
                    height: "20px",
                    width: "20px",
                  }}
                  onClick={handleSettingsIconClick}
                >
                  <img
                    style={{ height: "38px", width: "38px" }}
                    src={Settings}
                    alt="icon"
                  />
                </IconButton>
              )}
            </Box>
          </Box>
        )}
        {matches && (
          <hr
            style={{
              width: "17.8rem",
              margin: 0,
              marginTop: "-2rem",
              border: "none",
              borderBottom: "1px solid #ccc",
            }}
          />
        )}

        <SideNavItems />
        {terminationTill <= 7 && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "90%",
              height: "200px",
              borderRadius: "10px",
              backgroundColor: "#FFC85B",
              color: "#000",
              flexDirection: "column",
              p: 2,
            }}
          >
            <WarningAmber sx={{ fontSize: "42px" }} />
            <Typography
              align="center"
              sx={{
                my: 1,
                mx: "20px",
                fontSize: "12px",
              }}
            >
              Subscription{" "}
              {terminationTill == 0
                ? `Expires Today`
                : terminationTill < 0
                ? ` Expired on ${subscriptionTerminationDate()} Days`
                : ` Expires in ${terminationTill} Days`}{" "}
            </Typography>

            <Button
              variant="contained"
              size="small"
              color="info"
              sx={{ backgroundColor: "#000", mt: 2, borderRadius: "10px" }}
              onClick={goToSuperAdminPortal}
            >
              Renew Subscription
            </Button>
          </Box>
        )}

        {!matches && (
          <Box
            sx={{
              marginTop: "40px",
              cursor: "pointer",
              paddingBottom: "20px",
              marginLeft: "-24px",
            }}
          >
            <Box
              sx={{ display: "flex", alignItems: "center" }}
              onClick={handleLogout}
            >
              <img
                src={LogoutIcon}
                alt="logout icon"
                style={{ width: "24px", height: "24px" }}
              />
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  paddingLeft: "7px",
                }}
              >
                Log Out
              </Typography>
            </Box>
          </Box>
        )}
      </Toolbar>
    </Drawer>
  );
};

export default SideNav;
